import React from "react";
import { Box, ButtonStyled, FormEleWrapper, LabelStyled } from "../../../Styled/Elements";
import { InputFloating, SelectList } from "../../../Styled/FormElements";
import { UserDropDown } from "../../../Components/Dashboard/Pages/Projects/UserDropDown";

export const SaveProjectLayout = () => {
  return (

    <>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Layout Name</LabelStyled>
          <Box className="w-100">
            {/* <SelectList options={[]} placeholder="Select Task Manager" /> */}
            <UserDropDown userLabel="Select Task Manager" />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">
            Layout Description
          </LabelStyled>
          <Box className="w-100">
            <Box className=" pb-2 textarea-field-lg">
              <InputFloating
                row={8}
                col={5}
                textarea
                type="textarea"
                name="Description"
                placeholder="Description"
              />
            </Box>
          </Box>
        </Box>
      </FormEleWrapper>
      <Box className="bTNS mt-3">
        <ButtonStyled className="py-2 mx-2" bg="var(--themeColor)" width="120px">
          Save
        </ButtonStyled>
        <ButtonStyled className="py-2 mx-2" bg="var(--red)" width="120px">
          Cancel
        </ButtonStyled>
      </Box>
    </>
  );
};
