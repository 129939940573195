import styled from "styled-components";
import { MessagesList } from "./MessagesList";
import { Dropdown } from "react-bootstrap";
import { NotificationsList } from "./NotificationsList";
import { InputStyledWrap } from "../../Styled/styled";
import { ChangeButton } from "../../Languages/ChangeButton";
import { InputWithIcon } from "../../Styled/FormElements";
import { HeadingStyled, LazyImage, Box } from "../../Styled/Elements";
import { Message, Notification, SearchIcon } from "../../Styled/AllImages";
import {
  MessagesListArray,
  NotificationsListArray,
} from "./MessageNotifyListsArray";

export const Topbar = () => {
  return (
    <TopBarStyled className="w-100 d-flex align-items-center justify-content-between topBar">
      <HeadingStyled
        className="mb-0"
        size="18px"
        weight="normal"
        color="var(--darkBlue)"
        family="var(--semiBold)"
      >
        Hi Abdullah!
      </HeadingStyled>

      <Box className="btnlist">
        {/* <InputStyledWrap>
          <InputWithIcon type="text" placeholder="search" icon={SearchIcon} />
        </InputStyledWrap> */}
        <ChangeButton />
        <ul className="list-unstyled mb-0 d-flex align-items-center">
          <li>
            <Dropdown>
              <Dropdown.Toggle
                bsPrefix
                className="bg-transparent position-relative border-0 p-0"
              >
                <Box className="red-dot">
                  <Message height="24px" width="24px" />
                </Box>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropMNListContainer">
                <HeadingStyled
                  size="18px"
                  family="var(--semiBold)"
                  className="MNHeading"
                >
                  Messages
                </HeadingStyled>
                {MessagesListArray.map((item, key) => (
                  <MessagesList
                    {...item}
                    key={item.key}
                    className="BankListing"
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="pt-1">
            <Dropdown>
              <Dropdown.Toggle
                bsPrefix
                className="bg-transparent position-relative border-0 p-0"
              >
                <Box className="red-dot">
                  <Notification height="24px" width="24px" />
                </Box>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropMNListContainer">
                <HeadingStyled
                  size="18px"
                  family="var(--semiBold)"
                  className="MNHeading"
                >
                  Messages
                </HeadingStyled>
                {NotificationsListArray.map((item, key) => (
                  <NotificationsList
                    {...item}
                    key={item.key}
                    className="BankListing"
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            <Dropdown>
              <Dropdown.Toggle
                bsPrefix
                className="bg-transparent active-dot position-relative border-0 p-0 userDD"
              >
                <LazyImage
                  width="35px"
                  height="35px"
                  src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                  className="rounded-circle"
                />
                <span className="userNameT">Kevin Miller</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  href="#!"
                  className="d-flex align-items-center ddLinkU"
                >
                  {/* <UserIcon className='flip-item me-2' width='20px' height='20px' /> */}
                  Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="#!"
                  className="d-flex align-items-center ddLinkU"
                >
                  {/* <LogoutIcon className='flip-item me-2' width='20px' height='20px' /> */}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </Box>
    </TopBarStyled>
  );
};

const TopBarStyled = styled.header`
  //box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  // border-radius: 12px;
  background-color: var(--white);
  padding: 20px 20px 20px 268px;
  border-bottom: 1px solid var(--outlineC);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 102;
  transition: 0.3s;
  .btnlist {
    display: flex;
    align-items: center;
    gap: 20px;
    & > div > button {
      padding: 6px 10px;
    }
  }
  ul {
    & > li {
      position: relative;
      svg {
        top: 10px;
      }
      & > a {
        text-decoration: none;
        font-size: 14px;
        position: relative;
        color: var(--drColor);

        svg {
          [stroke] {
            //stroke: var(--drColor);
          }
        }
      }
      .red-dot:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--red);
        position: absolute;
        top: -4px;
        right: -1px;
        border: 2px solid var(--white);
      }
      margin-right: 24px;
      &:last-child {
        margin-right: 0px;
      }
      .active-dot::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--green2);
        border: 2px solid var(--white);
        position: absolute;
        top: 0px;
        left: 26px;
        z-index: 2;
      }
    }
    .userDD {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .ddLinkU {
      font-size: 14px;
      color: var(--grey);
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  .dropMNListContainer {
    min-width: 320px;
    max-width: 320px;
  }
  .MNHeading {
    line-height: 20px;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid var(--outlineC);
  }
  .changeButton {
    position: relative;
    top: 0;
    right: 0;
  }
`;
