import styled from "styled-components";

export const BankDetailWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--greyBg);
  border-radius: 10px;
  margin-top: 30px;
  padding: 24px;

  .row {
    width: 100%;
    position: relative;
    border-bottom: 1px solid var(--outlineC);
    margin: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: none;
    }
  }
  .colBkList {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

export const SeeInvoice = styled.button`
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0;
    margin: 10px 0 5px 0;
    background:transparent;
    font-family:var(--semiBold);
    font-size: 15px;
    border: none;
    color: var( --themeColor);
    text-transform: uppercase;
    transition: all 0.4s ease-in
    &:hover{
        background:var(--themeColor);
        color:var(--white);
    }
    
`;
export const FileUploader = styled.label`
  background-color: var(--greyBg);
  border: 1px dashed var(--outlineGreyBG);
  border-radius: 6.9px;
  padding: 25px;
  cursor: pointer;
  &:hover {
    border-color: var(--grey);
  }
  svg {
    margin-right: 10px;
  }
`;
