import styled from "styled-components";

export const FormStyled = styled.form`
        width: 80%;
    .counter-wrapper {
        margin-bottom: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
    li.counter-box {
            width: 36px;
            height: 36px;
            background-color: var(--themeColor);
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
        }
    li:not(.counter-box) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .otp-field {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        > div {
            width:110px;
            input {
                background-color:var(--lightBlue);
                border: 1.5px solid var(--lightBlue);
                border-radius: 8px;
                height: 85px;
                outline: none;
                font-size: 50px;
                &,&::placeholder {
                    font-weight: 200;
                    color: var(--grey);
                }

            
        }
    }
`;
