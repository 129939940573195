import React, { useState } from "react";
import DataWrapper from "./DataWrapper";
import CloneWrapper from "./CloneWrapper";
import {
	avatarImg,
	profile,
	profile2,
	profile3,
	profile4,
} from "../../../../../Styled/AllImages";
import styled from "styled-components";

const AddMember = () => {
	const [selectedMainParents, setSelectedMainParents] = useState([]);

	const mainParents = [
		{
			id: 1,
			profile: profile,
			userName: "Savannah Nguyen",
			userRole: "User Role 1",
		},
		{
			id: 2,
			profile: profile2,
			userName: "Kristin Watson",
			userRole: "User Role 2",
		},
		{
			id: 3,
			profile: profile3,
			userName: "Brooklyn Simmons",
			userRole: "User Role 3",
		},
		{
			id: 4,
			profile: profile3,
			userName: "Brooklyn Simmons",
			userRole: "User Role 3",
		},
	];

	const handleMainParentClick = (mainParent) => {
		console.log(mainParent , 'Start Hello World');
		
		if (selectedMainParents.some((selected) => selected.id === mainParent.id)) {
			console.log(mainParent , 'Hello World');
			// Remove the mainParent from the selected list
			setSelectedMainParents(
				selectedMainParents.filter((selected) => selected.id !== mainParent.id)
			);
			
		} else {
			console.log(mainParent , 'Else Hello World');

			// Add the mainParent to the selected list
			setSelectedMainParents([...selectedMainParents, mainParent]);
		}
	};

	return (
		<MultiUserRow>
			<CloneWrapper selectedMainParents={selectedMainParents} />
			<DataWrapper
				mainParents={mainParents}
				onMainParentClick={handleMainParentClick}
				selectedMainParents={selectedMainParents}
			/>


		</MultiUserRow>
	);
};

export default AddMember;


const MultiUserRow = styled.div`
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
`;
