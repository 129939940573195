import { Col, Row } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import { calendar } from "../../Styled/AllImages";
import { InputStyledWrap } from "../../Styled/styled";
import { InputWithIcon, SelectList, SelectListWithIcon } from "../../Styled/FormElements";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { ProgressBarTile } from "../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import ColumnStackedChart from "../../Components/Dashboard/Pages/Charts/ColumnStackedChart";
import IndPerformanceGraph from "../../Components/Dashboard/Pages/Charts/IndPerformanceGraph";
import IndependentTasksChart from "../../Components/Dashboard/Pages/Charts/IndependentTasksChart";
import ReportsIndependentTasksChart from "../../Components/Dashboard/Pages/Charts/ReportsIndependentTasksChart";
import ReportsColumnStackedChart from "../../Components/Dashboard/Pages/Charts/ReportsColumnStackedChart";

import PerformanceIndications from "../../Components/Dashboard/Pages/Charts/PerformanceIndications";
import ProjectPerformanceGraph from "../../Components/Dashboard/Pages/Charts/ProjectPerformanceGraph";
// import { ReportsProgressBarTile } from "../../Components/Dashboard/Pages/Charts/ReportsProgressBarTile";


import {
	Box,
	ButtonStyledMedium,
	ChartBoxStyled,
	HeadingStyledDB,
	PieBoxStyled,
	SecondaryBarStyled,
	SpanStyled,
} from "../../Styled/Elements";
import {
	IndependentTasksArray,
	IndependentTasksProgBarTileArray,
	IntExtProgBarTileArray,
	InternalExternalProjectsArray,
	ProjectCostArray,
	ProjectCostProgBarTileArray,
	ProjectTaskProgBarTileArray,
	ProjectTasksArray,
	ProjectsProgBarTileArray,
	ProjectsTileArray,
	TeamsArray,
	TeamsProgBarTileArray,
	ReportsProjectsTileArray,
	ReportsProjectsProgBarTileArray,
	ReportsProjectTasksArray,
	ReportsProjectTaskProgBarTileArray,
	ReportsIndependentTasksArray,
	ReportsIndependentTasksProgBarTileArray,
	ReportsProjectCostArray,
	ReportsProjectCostProgBarTileArray,
	ReportsInternalExternalProjectsArray,
	ReportsIntExtProgBarTileArray,

} from "../../Components/Dashboard/Pages/TilesArray";
import {
	IndPerformanceArray,
	ProjectsPerformanceArray,
} from "../../Components/Dashboard/Pages/Charts/PerformanceIndicationsArray";
import { DateRangePickersBar } from "../../Components/Dashboard/Pages/DateRangePickerBar";
import { SecondaryBar } from "../../Components/Dashboard/Pages/Reports/SecondaryBar";

export const Overview = () => {
	return (
		<Box>
			<SecondaryBar />

			<Box className="main-content">
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Projects
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{ReportsProjectsTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}

							{ReportsProjectsProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Project Tasks
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{ReportsProjectTasksArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsProjectTaskProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Independent Tasks
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{ReportsIndependentTasksArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsIndependentTasksProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Project Cost
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-3">
							{ReportsProjectCostArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-3"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsProjectCostProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-3"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Internal/External Projects
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-2">
							{ReportsInternalExternalProjectsArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-2"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsIntExtProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-3"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Teams
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{TeamsArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{TeamsProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">

					<Col className="" xs={12} lg={6}>
						<ChartBoxStyled>
							<Box className="graph-sel-row">
								<Box className="graph-sel-h-col">
									<HeadingStyledDB className="mb-0">
										Independent Tasks
									</HeadingStyledDB>
									<Box className="mo-duration">
										<SpanStyled size="14" color="var(--greyText)">
											February Total
										</SpanStyled>
										<SpanStyled
											size="14"
											color="var(--darkBlue)"
											family="var(--semiBold)"
										>
											248
										</SpanStyled>
									</Box>
								</Box>
								<SelectList
									options={["Week", "Today"]}
									className="custom-select-design med-sel"
								/>
							</Box>
							<ReportsIndependentTasksChart />
						</ChartBoxStyled>
					</Col>
					<Col className="" xs={12} lg={6}>
						<ChartBoxStyled>
							<Box className="graph-sel-row">
								<Box className="graph-sel-h-col">
									<HeadingStyledDB className="mb-0">
										Project Tasks
									</HeadingStyledDB>
									<Box className="mo-duration">
										<SpanStyled size="14" color="var(--greyText)">
											February Total
										</SpanStyled>
										<SpanStyled
											size="14"
											color="var(--darkBlue)"
											family="var(--semiBold)"
										>
											102
										</SpanStyled>
									</Box>
								</Box>
								<SelectListWithIcon
									options={["Week", "Today"]}
									className="custom-select-design med-sel"
								/>
							</Box>
							<ReportsColumnStackedChart />
						</ChartBoxStyled>
					</Col>
				</Row>
			</Box>
		</Box>
	);
};
