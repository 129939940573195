import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { ReasonModal } from "../../Components/Modals/ReasonModal";
import { avatarImg, calendar, Attach, Voice } from "../../Styled/AllImages";
import Calendar from "react-calendar";
import styled from "styled-components";
import {
	Box,
	ButtonStyled,
	Container,
	FormEleWrapper,
	HeadingStyled,
	LabelStyled,
	SecondaryBarStyled,
	DataContainerStyled,
	SpanStyled,
	MainContainer,
	TextWithIcon,
	ButtonStyledDiv,
	CalendarContainer,
} from "../../Styled/Elements";
import {
	BackButton,
	CustomCheckbox,
	Pill,
	SelectList,
	FileUploader,
	InputWithIcon,
	InputFloating,
} from "../../Styled/FormElements";
import { InputStyledWrap } from "../../Styled/styled";

import { X } from "react-bootstrap-icons";
import { TaskActivities } from "./TaskActivities";
import { ListTaskViewWrap } from './styledViewTask';
import AddTasks from "../../Components/Dashboard/Pages/Projects/addTasks/MainData";
import AddMemberRole from "../../Components/Dashboard/Pages/Projects/addMemberRole/MainData";

// import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
// import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';

export const InventoryColumns = [
	{
		name: "taskName",
		label: "Task Name",
	},
	{
		name: "type",
		label: "Type",
		render: (text) => (
			<CustomCheckbox
				name={text}
				// label={item?.name}
				value={text}
			/>
		),
	},
	{
		name: "status",
		label: "Status",
	},
	{
		name: "ProjectManager",
		label: "Project Manager",
		render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
	},
	{
		name: "Start",
		label: "Start",
	},
	{
		name: "Duration",
		label: "Duration",
	},
	{
		name: "End",
		label: "End",
		render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
	},
];

export const ViewTask = () => {
	// const [selectedOption, setSelectedOption] = useState(null);
	// const handleSelectChange = (selectedValue) => {
	// 	setSelectedOption(selectedValue);
	// };
	const [showDateModal, setShowDateModal] = useState(false);
	const [date, onDataChange] = useState(new Date());

	const [selectedOption, setSelectedOption] = useState(null);

	const handleSelectChange = (selectedValue) => {
		setSelectedOption(selectedValue);
	};
	const [showModal, setModal] = useState(false);

	const navigate = useNavigate();
	const rows = [
		{
			taskName: "Android Development",
			type: "type",
			status: "Started",
			executor: "Executor",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
		},
	];
	const onDelete = () => {
		setModal(true);
	};

	// const firstChildRef = useRef(null);
	// const secondChildRef = useRef(null);

	// useEffect(() => {
	// 	const firstChildHeight = firstChildRef.current.clientHeight;
	// 	secondChildRef.current.style.height = `${firstChildHeight}px`;
	// }, []);
	const firstChildRef = useRef(null);
	const secondChildRef = useRef(null);
	const [firstChildHeight, setFirstChildHeight] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setFirstChildHeight(firstChildRef.current.scrollHeight);
		};

		// Initial height calculation
		handleResize();

		// Listen for changes that may affect the height of the first child
		window.addEventListener('resize', handleResize);

		return () => {
			// Cleanup the event listener
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		secondChildRef.current.style.height = `${firstChildHeight}px`;
	}, [firstChildHeight]);



	return (
		<>
			<Box>
				<Box className="main-content">
					<DataContainerStyled className="allDataContainer mb-4 p-0">
						<Row>
							<Col xs={12} className="listTaskViewContainer">
								<ListTaskViewWrap>
									<Box className="topBarInner">
										<HeadingStyled
											className="topBarInnerHeading"

										>
											<BackButton
												// onClick={() => navigation("/framing-agreement-requests")}
												label=" Android Development"
											/>
										</HeadingStyled>
									</Box>
									<Box className="listTaskActWrapper">
										<Container className="listTaskView" borderRight="1px solid var(--outlineC)" ref={firstChildRef}>

											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">Task Name</LabelStyled>
													<LabelStyled className="labelForm pt-1">
														Android Development
													</LabelStyled>
												</Box>
											</FormEleWrapper>
											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">Type</LabelStyled>

													<Box className="listRowWrapper">
														<Box className="d-flex radioListWrapper">
															<SelectList options={["Relational", "Standalone"]} placeholder="Select by Task" />
														</Box>

														<AddTasks />
													</Box>
												</Box>
											</FormEleWrapper>
											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">Duration</LabelStyled>
													<Pill text="3 Days, May  9 - 12" icon={calendar} />
												</Box>
											</FormEleWrapper>
											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">
														Implementer
													</LabelStyled>
													<Box className="listRowWrapper">
														<Box className="d-flex radioListWrapper">
															<SelectList options={["Team", "Alone"]} />
														</Box>

														<AddMemberRole />
													</Box>
												</Box>
											</FormEleWrapper>

											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">
														Description
													</LabelStyled>
													<Box className="w-100">
														<SpanStyled color="var(--grey)" size="15px" lheight="18px">
															Introducing our
															{/* Introducing our groundbreaking Android mobile app, your one-stop solution for unrivaled productivity and endless entertainment. Experience the pinnacle of convenience with our feature-rich app, boasting a user-friendly interface and an array of powerful tools. Stay connected to what matters most, effortlessly manage tasks, and harness the power of organization with our intuitive design. Whether you're seeking seamless communication, efficient planning, or immersive entertainment, our cutting-edge app has you covered. From instant messaging and file sharing to customizable widgets and personalized recommendations, embrace a world of limitless possibilities and elevate your mobile experience. Embrace the future with our extraordinary Android app, tailored to meet your every need. */}
														</SpanStyled>
													</Box>
												</Box>
											</FormEleWrapper>
											<FormEleWrapper>
												<Box className="formEleContainer align-items-start">
													<LabelStyled className="labelForm pt-1">Attachment</LabelStyled>
													<Box className="w-100">
														<FileUploader />
													</Box>
												</Box>
											</FormEleWrapper>
											<Box className="formBtns w-100">
												<ButtonStyled type="button" bg="var(--themeColor)">
													Submit Task
												</ButtonStyled>
												<ButtonStyled onClick={() => setShowDateModal(true)}
													border="1px solid var(--themeColor)"
													color="var(--themeColor)"
													bg="var(--white)"
												>
													Extend Request
												</ButtonStyled>
											</Box>
										</Container>
										<Container className="listTaskActivities" ref={secondChildRef}>
											<TaskActivities />

										</Container>
									</Box>
								</ListTaskViewWrap>
							</Col>
						</Row>
					</DataContainerStyled>
				</Box>
			</Box>
			<ReasonModal
				showModal={showDateModal}
				onHide={() => setShowDateModal(false)}
				//heading="Task Timeline"
				closeButton={true}
				buttons={[
					{ text: "Ok", variant: "primary" },
					{ text: "Cancel", variant: "danger" },
				]}
				size="sm"
			>
				<CalendarContainer>
					<SpanStyled size="15px" family="var(--medium)" color="var(--darkBlue)" >Reason</SpanStyled>
					<Box className="w-100">
						<Box className=" pb-2 textarea-field-lg">
							<InputFloating
								row={5}
								textarea
								type="textarea"
								name={"reason"}
								placeholder="Enter Reason"

							/>
						</Box>
					</Box>
					<Calendar onChange={onDataChange} value={date} />
					{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer
							components={[
								'DateRangePicker',
								'MobileDateRangePicker',
								'DesktopDateRangePicker',
								'StaticDateRangePicker',
							]}
						>
							<DemoItem label="Static variant" component="StaticDateRangePicker">
								<StaticDateRangePicker
									defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
									sx={{
										[`.${pickersLayoutClasses.contentWrapper}`]: {
											alignItems: 'center',
										},
									}}
								/>
							</DemoItem>
						</DemoContainer>
					</LocalizationProvider> */}
				</CalendarContainer>
			</ReasonModal>
		</>
	);
};

