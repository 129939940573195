import { useState } from "react";
import styled from "styled-components";
import { TasksArray } from "./TasksArray";
import { Plus, Filter } from "../../Styled/AllImages";
import {
  Box,
  ButtonStyledMedium,
  SecondaryBarStyled,
  SpanStyled,
  TabStyled,
} from "../../Styled/Elements";
import { AssignTaskToTeam } from "./addTask/AssignTaskToTeam";
import { Board } from "./Board";
import MembersModal from "./Modals/MembersModal";

export const Tasks = () => {
  const [activeTab, setActiveTab] = useState(TasksArray[0]);
  const [showAssignTaskToTeamModal, setShowAssignTaskToTeamModal] = useState(false)
  
  const openAddTaskModal = () => {
    setShowAssignTaskToTeamModal(true)
  }

  return (
    <>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <TabsContainer>
            <Box className="tabsList">
              <TabStyled className="mb-0 list-unstyled d-flex justify-content-between">
                {TasksArray.map((item, key) => (
                  <li
                    className={` ${item.title === activeTab.title ? "active" : ""
                      }`}
                    onClick={() => setActiveTab(TasksArray[key])}
                    key={key}
                  >
                    {item.icon}
                    <SpanStyled className="px-2">{item.title}</SpanStyled>
                  </li>
                ))}
              </TabStyled>
            </Box>
          </TabsContainer>
          <Box className="boxRBt">
            <Box className="filterWrap">
              <Filter />
              <SpanStyled>Filter</SpanStyled>
            </Box>
            <ButtonStyledMedium onClick={openAddTaskModal}>
              <Plus /> Add Task
            </ButtonStyledMedium>
          </Box>
        </Box>
      </SecondaryBarStyled>
      {
        activeTab.component === Board ?
        <activeTab.component openAddTaskModal={openAddTaskModal} /> : <activeTab.component />
      }
      
      <AssignTaskToTeam
        showAssignTaskToTeamModal={showAssignTaskToTeamModal}
        setShowAssignTaskToTeamModal={() => setShowAssignTaskToTeamModal(false)}
      />
      <MembersModal />
    </>
  );
};

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .tabsList {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
  }
`;
