import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box } from "../../../Styled/Elements";
import { ButtonWithIcon, CustomCheckbox } from "../../../Styled/FormElements";
import { ArrowLeft, ArrowRight } from "../../../Styled/AllImages";
export const arrayList = [
  "Task",
  "Projects",
  "Reports",
  "Meeting",
  "Teams",
  "Users",
  "Contact",
  "Achive",
  "Settings",
];
export const UsersPermissions = () => {
  return (
    <Box className="px-16 pb-4 border-bottom-custom">
      <Row className="my-4">
        <Col xs={12} className="mt-4">
          <PermissionsTableStyled className="w-100">
            <thead>
              <tr>
                <th>Feature</th>
                <th>Create</th>
                <th>Edit</th>
                <th>View</th>
                <th>Member</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {arrayList.map((item, key) => (
                <tr key={key}>
                  <td>{item}</td>
                  <td>
                    <CustomCheckbox />
                  </td>
                  <td>
                    <CustomCheckbox />
                  </td>
                  <td>
                    <CustomCheckbox />
                  </td>
                  <td>
                    <CustomCheckbox />
                  </td>
                  <td>
                    <CustomCheckbox />
                  </td>
                </tr>
              ))}
            </tbody>
          </PermissionsTableStyled>
        </Col>
      </Row>
      <Box className="d-flex align-items-center justify-content-between">
        <ButtonWithIcon icon={ArrowLeft} label="Previous" />
        <ButtonWithIcon icon={ArrowRight} label="Next" />
      </Box>
    </Box>
  );
};

export const PermissionsTableStyled = styled.table`
  thead {
    tr {
      background: #fcfcfc;
      border-radius: 7px 7px 0px 0px;
      outline: 1px solid var(--grey-light);
      th {
        padding: 10px 15px;
        font-weight: 600;
        font-size: 12px;
        color: var(--drColor);
        text-transform: uppercase;
      }
    }
  }
  tbody {
    tr {
      box-shadow: -1px 0 0 var(--grey-light), 1px 0 0 var(--grey-light),
        0 1px 0 var(--grey-light);
      td {
        padding: 15px 15px;
        font-size: 13px;
        font-weight: 400;
      }
      &:last-child {
        border-radius: 0px 0px 7px 7px;
      }
    }
  }
`;
