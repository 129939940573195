import React from "react";
import { calendar } from "../../../Styled/AllImages";
import styled from "styled-components";
import {
	SelectListWithIcon,
} from "../../../Styled/FormElements";

export const DayPick = () => {
	return (
		<DayDropdown>
			<SelectListWithIcon
                  options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
				   icon={calendar}
                />
			
		</DayDropdown>
	);
};

const DayDropdown = styled.div`
svg {
	[stroke] {
	  stroke: var(--grey);
	}
	[fill] {
	  fill: var(--grey);
	}
  }
`
