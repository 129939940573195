import {
	PrTotal,
	PrInProgress,
	PrOverdue,
	PrCompleted,
	PtTotal,
	PtInProgress,
	PtOverdue,
	PtCompleted,
	ItTotal,
	ItInProgress,
	ItOverdue,
	ItCompleted,
	ProjectCost,
	TotalPaid,
	TotalPaid2,
	TotalRest,
	InternalProject,
	ExternalProject,
	UserIcon,
	UserIcon2,
	UserHouseIcon,
	MeetingUser,
	TotalMeetingUser,
	RemoteMeeting,
	MeetingHours,
	Clander,
	tasks,
	Standalone,
	Relational,
	TimeIcon,
	Pms,
	Lts,
	Tms,
	NotStarted,
	TodoTask,
	Extended,
	OverdueTask,
	TkInProgress,
	PendingTasks,
} from "../../../Styled/AllImages";
import ProjectProgressBar from "./Charts/ProjectProgressBar";
import ProjectTasksProgressBar from "./Charts/ProjectTasksProgressBar";
import IndependentTasksProgressBar from "./Charts/IndependentTasksProgressBar";
import ProjectCostProgressBar from "./Charts/ProjectCostProgressBar";
import IntExtProgressBar from "./Charts/IntExtProgressBar";
import PaymentProgressBar from "./Charts/PaymentProgressBar";
import TeamsProgressBar from "./Charts/TeamsProgressBar";
import ReportsProjectProgressBar from "./Charts/ReportsProjectProgressBar";
import ReportsProjectTasksProgressBar from "./Charts/ReportsProjectTasksProgressBar";
import ReportsIndependentTasksProgressBar from "./Charts/ReportsIndependentTasksProgressBar";
import ReportsProjectCostProgressBar from "./Charts/ReportsProjectCostProgressBar";
import ReportsIntExtProgressBar from "./Charts/ReportsIntExtProgressBar";
import ReportsPrProjectProgressBar from "./Charts/ReportsPrProjectProgressBar";
import ReportsPrProjectCostProgressBar from "./Charts/ReportsPrProjectCostProgressBar";
import ReportsPrIntExtProgressBar from "./Charts/ReportsPrIntExtProgressBar";
import ReportsPrDeProjectCostProgressBar from "./Charts/ReportsPrDeProjectCostProgressBar";
import ReportsTasksOverallProgressBar from "./Charts/ReportsTasksOverallProgressBar";
import { ProgressGraphTeam } from "./Charts/ProgressGraphTeam";
import ReportsProjectsTeamProgressBar from "./Charts/ReportsProjectsTeamProgressBar";
import ReportsTasksTeamProgressBar from "./Charts/ReportsTasksTeamProgressBar";



export const ProjectsTileArray = [
	{
		icon: PrTotal,
		count: "200",
		bg: "var(--lightBlue)",
		title: "Total Projects",
	},
	{
		icon: PrInProgress,
		count: "50",
		bg: "var(--lightBlue1)",
		title: "Projects in Progress",
	},
	{
		icon: PrOverdue,
		count: "2",
		bg: "var(--lightRed)",
		title: "Overdue Projects",
	},
	{
		icon: PrCompleted,
		count: "150",
		bg: "var(--lightGreen1)",
		title: "Completed Projects",
	},
];

export const ProjectViewTiles = [
	{
		icon: tasks,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Tasks",
	},
	{
		icon: Standalone,
		count: "4",
		bg: "var(--lightGreen1)",
		title: "Standalone Tasks",
	},
	{
		icon: Relational,
		count: "5",
		bg: "var(--lightPurple)",
		title: "Relational Tasks",
	},
	{
		icon: TimeIcon,
		count: "75 Days",
		bg: "var(--lightYellow)",
		title: "Total Duration",
	},
];
export const ProjectsProgBarTileArray = [
	{
		progBar: ProjectProgressBar,
		count: "75%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ProjectTasksArray = [
	{
		icon: PtTotal,
		count: "420",
		bg: "var(--lightBlue)",
		title: "Total Project Tasks",
	},
	{
		icon: PtInProgress,
		count: "248",
		bg: "var(--lightBlue1)",
		title: " Project Tasks in Progress",
	},
	{
		icon: PtOverdue,
		count: "5",
		bg: "var(--lightRed)",
		title: "Overdue Project Tasks",
	},
	{
		icon: PtCompleted,
		count: "375",
		bg: "var(--lightGreen1)",
		title: "Completed Project Tasks",
	},
];
export const ProjectTaskProgBarTileArray = [
	{
		progBar: ProjectTasksProgressBar,
		count: "60%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const IndependentTasksArray = [
	{
		icon: ItTotal,
		count: "400",
		bg: "var(--lightBlue)",
		title: "Total Tasks",
	},
	{
		icon: ItInProgress,
		count: "200",
		bg: "var(--lightBlue1)",
		title: "Tasks in Progress",
	},
	{
		icon: ItOverdue,
		count: "3",
		bg: "var(--lightRed)",
		title: "Overdue Tasks",
	},
	{
		icon: ItCompleted,
		count: "200",
		bg: "var(--lightGreen1)",
		title: "Completed Tasks",
	},
];
export const IndependentTasksProgBarTileArray = [
	{
		progBar: IndependentTasksProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ProjectCostArray = [
	{
		icon: ProjectCost,
		count: "SAR 400",
		bg: "var(--lightBlue)",
		title: "Total Project Cost",
	},
	{
		icon: TotalPaid,
		count: "SAR 200",
		bg: "var(--lightBlue1)",
		title: "Total Project Paid",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Total Project Rest",
	},
];

export const PerformanceArray = [
	{
		icon: ProjectCost,
		count: "400",
		bg: "var(--lightBlue)",
		title: "Cost Center",
		progBar: IndependentTasksProgressBar,
		percent: "60%",
		status: "Completed",
		subtitle: "Cost Center Performance",
	},
	{
		icon: TotalPaid,
		count: "200",
		bg: "var(--lightBlue1)",
		title: "Total Members",
		progBar: IndependentTasksProgressBar,
		percent: "60%",
		status: "Completed",
		subtitle: "Total Members Performance",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Total PMs",
		progBar: IndependentTasksProgressBar,
		percent: "20%",
		status: "Completed",
		subtitle: "PMs Performance",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Team Leaders",
		progBar: IndependentTasksProgressBar,
		percent: "90%",
		status: "Completed",
		subtitle: "Team Leaders Performance",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Tasks Manager",
		progBar: IndependentTasksProgressBar,
		percent: "90%",
		status: "Completed",
		subtitle: "Team Leaders Performance",
	},
];

export const UserArray = [
	{
		icon: UserIcon,
		count: "SAR 400",
		bg: "var(--lightBlue)",
		title: "Total Users",
	},
	{
		icon: UserHouseIcon,
		count: "SAR 200",
		bg: "var(--lightBlue1)",
		title: "Total Coast Center",
	},
];
export const MeetingArray = [
	{
		icon: MeetingUser,
		count: "4",
		bg: "var(--lightBlue)",
		title: "Total Meetings",
	},
	{
		icon: TotalMeetingUser,
		count: "8",
		bg: "var(--lightBlue1)",
		title: "In Person Meetings",
	},
	{
		icon: RemoteMeeting,
		count: "2",
		bg: "var(--lightBlue1)",
		title: "Remote Meetings",
	},
	{
		icon: MeetingHours,
		count: "6",
		bg: "var(--lightBlue1)",
		title: "Total Meeting Hours",
	},
];
export const ProjectCostProgBarTileArray = [
	{
		progBar: ProjectCostProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const InternalExternalProjectsArray = [
	{
		icon: InternalProject,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Internal Projects",
	},
	{
		icon: ExternalProject,
		count: "12",
		bg: "var(--lightBlue1)",
		title: "Total External Projects",
	},
];
export const IntExtProgBarTileArray = [
	{
		progBar: IntExtProgressBar,
		count: "22",
		status: "Total",
		title: "Overall Progress",
	},
];
export const PaymentCostArray = [
	{
		icon: ProjectCost,
		count: "SAR 10K",
		bg: "var(--lightBlue)",
		title: "Total Amount",
	},
	{
		icon: TotalPaid,
		count: "SAR 4K",
		bg: "var(--lightGreen)",
		title: "Total Paid Amount",
	},
	{
		icon: TotalPaid2,
		count: "Sar 4K",
		bg: "var(--lightRed)",
		title: "Total Rest Amount",
	},
];
export const PaymentProgBarTileArray = [
	{
		progBar: PaymentProgressBar,
		count: "8K",
		status: "SAR",
		title: "Overall Progress",
	},
];

export const TeamsArray = [
	{
		icon: TotalMeetingUser,
		count: "120",
		bg: "var(--lightBlue)",
		title: "Total Mambers",
	},
	{
		icon: Pms,
		count: "10",
		bg: "var(--lightPurple)",
		title: "Number of PMs",
	},
	{
		icon: Lts,
		count: "10",
		bg: "var(--lightGreen)",
		title: "Number of TLs",
	},
	{
		icon: Tms,
		count: "10",
		bg: "var(--lightYellow)",
		title: "Number of TMs",
	},
];
export const TeamsProgBarTileArray = [
	{
		progBar: TeamsProgressBar,
		count: "120",
		status: "Total",
		//title: "Overall Progress",
	},
];

/*****************Reports****************/

/*******Overview**********/

export const ReportsProjectsTileArray = [
	{
		icon: PrTotal,
		count: "200",
		bg: "var(--lightBlue)",
		title: "Total Projects",
	},
	{
		icon: PrInProgress,
		count: "50",
		bg: "var(--lightBlue1)",
		title: "Projects in Progress",
	},
	{
		icon: PrOverdue,
		count: "2",
		bg: "var(--lightRed)",
		title: "Overdue Projects",
	},
	{
		icon: PrCompleted,
		count: "150",
		bg: "var(--lightGreen1)",
		title: "Completed Projects",
	},
];
export const ReportsProjectsProgBarTileArray = [
	{
		progBar: ReportsProjectProgressBar,
		count: "75%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsProjectTasksArray = [
	{
		icon: PtTotal,
		count: "420",
		bg: "var(--lightBlue)",
		title: "Total Project Tasks",
	},
	{
		icon: PtInProgress,
		count: "248",
		bg: "var(--lightBlue1)",
		title: " Project Tasks in Progress",
	},
	{
		icon: PtOverdue,
		count: "5",
		bg: "var(--lightRed)",
		title: "Overdue Project Tasks",
	},
	{
		icon: PtCompleted,
		count: "375",
		bg: "var(--lightGreen1)",
		title: "Completed Project Tasks",
	},
];
export const ReportsProjectTaskProgBarTileArray = [
	{
		progBar: ReportsProjectTasksProgressBar,
		count: "60%",
		status: "Completed",
		title: "Overall Progress",
	},
];
export const ReportsIndependentTasksArray = [
	{
		icon: ItTotal,
		count: "400",
		bg: "var(--lightBlue)",
		title: "Total Tasks",
	},
	{
		icon: ItInProgress,
		count: "200",
		bg: "var(--lightBlue1)",
		title: "Tasks in Progress",
	},
	{
		icon: ItOverdue,
		count: "3",
		bg: "var(--lightRed)",
		title: "Overdue Tasks",
	},
	{
		icon: ItCompleted,
		count: "200",
		bg: "var(--lightGreen1)",
		title: "Completed Tasks",
	},
];
export const ReportsIndependentTasksProgBarTileArray = [
	{
		progBar: ReportsIndependentTasksProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsProjectCostArray = [
	{
		icon: ProjectCost,
		count: "SAR 400",
		bg: "var(--lightBlue)",
		title: "Total Project Cost",
	},
	{
		icon: TotalPaid,
		count: "SAR 200",
		bg: "var(--lightBlue1)",
		title: "Total Project Paid",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Total Project Rest",
	},
];
export const ReportsProjectCostProgBarTileArray = [
	{
		progBar: ReportsProjectCostProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsInternalExternalProjectsArray = [
	{
		icon: InternalProject,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Internal Projects",
	},
	{
		icon: ExternalProject,
		count: "12",
		bg: "var(--lightBlue1)",
		title: "Total External Projects",
	},
];
export const ReportsIntExtProgBarTileArray = [
	{
		progBar: ReportsIntExtProgressBar,
		count: "22",
		status: "Total",
		title: "Overall Progress",
	},
];

/*******Projects**********/

export const ReportsPrProjectsTileArray = [
	{
		icon: PrTotal,
		count: "200",
		bg: "var(--lightBlue)",
		title: "Total Projects",
	},
	{
		icon: PrInProgress,
		count: "150",
		bg: "var(--lightBlue1)",
		title: "Projects in Progress",
	},
	{
		icon: PrOverdue,
		count: "2",
		bg: "var(--lightRed)",
		title: "Overdue Projects",
	},
	{
		icon: NotStarted,
		count: "50",
		bg: "var(--lightPurple)",
		title: "Not Started Yet",
	},
];
export const ReportsPrProjectsProgBarTileArray = [
	{
		progBar: ReportsPrProjectProgressBar,
		count: "75%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsPrProjectCostArray = [
	{
		icon: ProjectCost,
		count: "SAR 400",
		bg: "var(--lightBlue)",
		title: "Total Project Cost",
	},
	{
		icon: TotalPaid,
		count: "SAR 200",
		bg: "var(--lightBlue1)",
		title: "Total Project Paid",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Total Project Rest",
	},
];
export const ReportsPrProjectCostProgBarTileArray = [
	{
		progBar: ReportsPrProjectCostProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsPrInternalExternalProjectsArray = [
	{
		icon: InternalProject,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Internal Projects",
	},
	{
		icon: ExternalProject,
		count: "12",
		bg: "var(--lightBlue1)",
		title: "Total External Projects",
	},
];
export const ReportsPrIntExtProgBarTileArray = [
	{
		progBar: ReportsIntExtProgressBar,
		count: "22",
		status: "Total",
		title: "Overall Progress",
	},
];

export const ReportsPrDeProjectCostArray = [
	{
		icon: ProjectCost,
		count: "SAR 400",
		bg: "var(--lightBlue)",
		title: "Total Project Cost",
	},
	{
		icon: TotalPaid,
		count: "SAR 200",
		bg: "var(--lightBlue1)",
		title: "Total Project Paid",
	},
	{
		icon: TotalRest,
		count: "3",
		bg: "var(--lightRed)",
		title: "Total Project Rest",
	},
];
export const ReportsPrDeProjectCostProgBarTileArray = [
	{
		progBar: ReportsPrDeProjectCostProgressBar,
		count: "50%",
		status: "Completed",
		title: "Overall Progress",
	},
];

export const ReportsTasksDeProjectCostArray = [
	{
		icon: PtTotal,
		count: "11",
		bg: "var(--lightBlue)",
		title: "Total Tasks",
	},
	{
		icon: TodoTask,
		count: "3",
		bg: "var(--outlineGreyBG)",
		title: "To do Tasks",
	},
	{
		icon: PtInProgress,
		count: "6",
		bg: "var(--lightBlue1)",
		title: "In Progress	Tasks",
	},
	{
		icon: Extended,
		count: "2",
		bg: "var(--lightPurple)",
		title: "Extended Tasks",
	},
	{
		icon: OverdueTask,
		count: "2",
		bg: "var(--lightRed)",
		title: "Overdue	Tasks",
	},
	{
		icon: PtCompleted,
		count: "2",
		bg: "var(--lightGreen1)",
		title: "Completed Tasks",
	},
];


export const ReportsTasksOverallProgBarTileArray = [
	{
		progBar: ReportsTasksOverallProgressBar,
		count: "50%",
		status: "Completed",
		//title: "Overall Progress",
	},
];

export const ReportsTasksTskArray = [
	{
		icon: PtTotal,
		count: "11",
		bg: "var(--lightBlue)",
		title: "Total Tasks",
	},
	{
		icon: TkInProgress,
		count: "3",
		bg: "var(--lightGreen)",
		title: "Tasks in Progress",
	},
	{
		icon: ItOverdue,
		count: "6",
		bg: "var(--lightRed)",
		title: "Overdue Tasks",
	},
	{
		icon: PendingTasks,
		count: "2",
		bg: "var(--lightPurple)",
		title: "Pending Tasks",
	},
	{
		icon: ItCompleted,
		count: "2",
		bg: "var(--lightGreen1)",
		title: "Completed Tasks",
	},
];


export const EmployeeTileArray = [
	{
		icon: MeetingUser,
		count: "200",
		bg: "var(--lightBlue)",
		title: "Total Employees",
		stroke: "var(--darkBlue)",

	},
	{
		icon: MeetingUser,
		count: "5",
		bg: "var(--lightGreen)",
		title: "Project Managers",
		stroke: "var(--green)",
		teamCount: "5",
		percentCount: "7%",
		graph: <ProgressGraphTeam
			series={[7, 93]}
			colors={["var(--green)", "var(--outlineC)"]}
			width={65}
			height={65} />,
	},
	{
		icon: MeetingUser,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Team Leaders",
		stroke: "var(--themeColor)",
		teamCount: "10",
		percentCount: "13%",
		graph: <ProgressGraphTeam
			series={[13, 87]}
			colors={["var(--themeColor)", "var(--outlineC)"]}
			width={65}
			height={65} />,
	},
	{
		icon: MeetingUser,
		count: "20",
		bg: "var(--lightPurple)",
		title: "Task Managers",
		stroke: "var(--purple)",
		teamCount: "20",
		percentCount: "26%",
		graph: <ProgressGraphTeam
			series={[26, 74]}
			colors={["var(--purple)", "var(--outlineC)"]}
			width={65}
			height={65} />,
	},
	{
		icon: MeetingUser,
		count: "40",
		bg: "var(--lightGreen1)",
		title: "Total Members",
		stroke: "var(--green2)",
		teamCount: "40",
		percentCount: "53%",
		graph: <ProgressGraphTeam
			series={[53, 47]}
			colors={["var(--green2)", "var(--outlineC)"]}
			width={65}
			height={65} />,
	},
];

export const ReportsProjectsTeamTileArray = [
	{
		icon: MeetingUser,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Project Teams",
		stroke: "var(--darkBlue)",

	},
	{
		icon: MeetingUser,
		count: "55",
		bg: "var(--lightYellow)",
		title: "Total Employees of Project Teams",
		stroke: "var(--orange)",
	},

];
export const ReportsProjectsTeamProgBarTileArray = [
	{
		progBar: ReportsProjectsTeamProgressBar,
		count: "15",
		status: "Total",
		title: "Employees Working in Multiple Projects Teams",
	},
];

export const ReportsTasksTeamTileArray = [
	{
		icon: MeetingUser,
		count: "10",
		bg: "var(--lightBlue)",
		title: "Total Taska Teams",
		stroke: "var(--darkBlue)",

	},
	{
		icon: MeetingUser,
		count: "20",
		bg: "var(--lightPurple)",
		title: "Total Employees of Tasks Teams",
		stroke: "var(--purple)",
	},

];
export const ReportsTasksTeamProgBarTileArray = [
	{
		progBar: ReportsTasksTeamProgressBar,
		count: "4",
		status: "Total",
		title: "Employees Working in Multiple Tasks Teams",
	},
];