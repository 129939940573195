import Calendar from "react-calendar";
import React, { useState } from "react";
import { calendar } from "../../../../Styled/AllImages";
import { InputStyledWrap } from "../../../../Styled/styled";
import { InputWithIcon } from "../../../../Styled/FormElements";
import { format } from 'date-fns';
import {
	Box,
	FormEleWrapper,
	LabelStyled,
	CalendarContainer,
} from "../../../../Styled/Elements";

const ProjectStartDate = () => {
	const [date, onDataChange] = useState(new Date());

	const handleDateChange = (newDate) => {
		onDataChange(newDate);
	};
	// Format the date as 'DD / MM / YYYY'
	const formattedDate = format(date, 'dd/MM/yyyy');


	return (
		<>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Meeting Day</LabelStyled>
					<Box className="w-100">
						<InputStyledWrap>
							<InputWithIcon type="text" placeholder="10/05/2023" value={formattedDate} readonly icon={calendar} />
						</InputStyledWrap>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<CalendarContainer className="w-100 calendarStartDate">
					<Calendar onChange={handleDateChange} value={date}  />
				</CalendarContainer>
			</FormEleWrapper>
		</>
	);
};

export default ProjectStartDate;
