import { SingleProfile, profile2 } from "../../Styled/AllImages";
import styled from "styled-components";
import {
	Box,
	Container,
	ImageIcon,
	SpanStyled,
} from "../../Styled/Elements";


export const TaskActivitiesCard = () => {
	return (
		<MainContainerChat>
			<Box className="chatAvatar">
				<img src={profile2} alt="img" />
			</Box>
			<Box className="chatContent">
				<Box className="chatTitleDate">
					<Box>
						<SpanStyled className="chatTitle">Some Name</SpanStyled>
					</Box>
					<Box>
						<SpanStyled className="chatDate">
							Feb 12 at 03:23
						</SpanStyled>
					</Box>
				</Box>
				<SpanStyled className="chatDesc">
					Some conversation Some conversation Some conversation Some
					conversation Some conversation Some conversation Some conversation
					Some conversation
				</SpanStyled>
			</Box>
		</MainContainerChat>
	);
};

const MainContainerChat = styled.div`
	display: flex;
	padding: 15px;
	gap: 12px;
	.chatAvatar{
		min-width: 40px;
		max-width: 40px;
	}
	.chatContent{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		.chatTitleDate{
			display: flex;
			width: 100%;
			justify-content: space-between;
			gap: 5px;
			.chatTitle{
				color: var(--darkBlue);
				font-size: 14px;
				font-family: var(--medium);
				line-height: 18px;
				display: flex;
				word-wrap: anywhere;
			}
			.chatDate{
				color: var(--greyText);
				font-size: 12px;
				white-space: nowrap;
				line-height: 18px;
				display: flex;
			}

		}
		.chatDesc{
			font-size: 14px;
			color: var(--grey);
			display: flex;
			width: 100%;
			line-height: 18px;
			margin-top: 5px;
			word-wrap: anywhere;
		}
	}
  

`;
