import { UserDropDown } from "./UserDropDown";
import { MembersDropDown } from "./MemberDropDown";
import { ImageUploader } from "../../../../Styled/FormElements";
import { profile, profile2 } from "../../../../Styled/AllImages";
import { Box, FormEleWrapper, LabelStyled } from "../../../../Styled/Elements";
import AddMemberRole from "./addMemberRole/MainData";

const ProjectBoard = () => {
	return (
		<>
			<FormEleWrapper>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">Project Owner</LabelStyled>
					<ImageUploader label="Savannah Nguyen" userImg={profile} />
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">Project Manager</LabelStyled>
					<ImageUploader label="Ronald Richards" userImg={profile2} />
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">Members</LabelStyled>
					{/* <MembersDropDown userLabel="Add Another Member" /> */}
					<AddMemberRole />
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">Board Secretary</LabelStyled>
					<UserDropDown userLabel="Board Secretary" />
				</Box>
			</FormEleWrapper>
		</>
	);
};

export default ProjectBoard;
