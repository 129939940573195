import { FormBox } from "./style";
import { Col, Row } from "react-bootstrap";
import { SpanStyled } from "../../../Styled/Elements";
import { InputStyledWrap } from "../../../Styled/styled";
import { InputWithIcon } from "../../../Styled/FormElements";
import { name, card, calendar, cvv } from "../../../Styled/AllImages";

export const SignupForm = () => {
  return (
    <FormBox>
      <InputStyledWrap>
        <SpanStyled className="formLabel">Card Holder</SpanStyled>
        <InputWithIcon type="text" placeholder="Card Holder Name" icon={name} />
      </InputStyledWrap>
      <InputStyledWrap>
        <SpanStyled className="formLabel">Card Number</SpanStyled>
        <InputWithIcon type="email" placeholder="Card Number" icon={card} />
      </InputStyledWrap>
      <Row className="rowFormND">
        <Col sm={12} md={6} lg={8}>
          <InputStyledWrap>
            <SpanStyled className="formLabel">Expiry Date</SpanStyled>
            <InputWithIcon
              type="text"
              placeholder="Expiry Date"
              icon={calendar}
            />
          </InputStyledWrap>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <InputStyledWrap>
            <SpanStyled className="formLabel">CVV</SpanStyled>
            <InputWithIcon type="text" placeholder="CVV" icon={cvv} />
          </InputStyledWrap>
        </Col>
      </Row>
    </FormBox>
  );
};
