import styled from "styled-components";

export const DateRangeWrapper = styled.div`
gap: 15px;
    .DateRangeIconTitle{
        display: flex;
        gap: 10px;
        align-items: center;
        
    }
    .DateRangeInput {
        border: 0;
        padding: 0;
        height: 18px;
    }
    .DateRangeTitle{
        margin: 0;
        line-height: 18px;
    }
    
`