import { SignupForm } from "./SignupForm";
import {
  Box,
  HeadingStyled,
  SpanStyled,
  ContainerWapper,
  LeftBox,
} from "../../../Styled/Elements";
import { SiteLogo, BackArrow } from "../../../Styled/AllImages";
import { LandingWrapper } from "../../../Styled/FormElements";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../Components/Footer/Footer";
import { Col } from "react-bootstrap";

export const Signup = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo mb-4">
            <BackArrow className="back_arrow" onClick={() => navigate("/")} />
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="36px" weight="bold" className="mb-3">
              Signup With
              <SpanStyled color="var(--themeColor)" size="36px" weight="bold">
                PTM
              </SpanStyled>
            </HeadingStyled>
            <SignupForm />
          </LeftBox>
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Experience a new level of project organization. Join our Task Management platform today."
          />
        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
