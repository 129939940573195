import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BankDetailList } from "./BankDetailList";
import { Footer } from "../../../Components/Footer/Footer";
import { LandingWrapper } from "../../../Styled/FormElements";
import { BankDetailWrap, SeeInvoice, FileUploader } from "./style";
import {
  Box,
  ButtonStyled,
  ContainerWapper,
  HeadingStyled,
  LeftBox,
  SpanStyled,
} from "../../../Styled/Elements";
import {
  SiteLogo,
  totalAmount,
  iban,
  bankIcon,
  Invoice,
  beneficiary,
  Upload,
  BackArrow,
} from "../../../Styled/AllImages";

export const BankPlan = () => {
  const navigate = useNavigate();
  const BankDetailArray = [
    {
      icon: totalAmount,
      title: "Total Amount",
      text: "600 SAR",
    },
    {
      icon: iban,
      title: "Bank Name",
      text: "Al Rajhi Bank",
    },
    {
      icon: bankIcon,
      title: "IBAN",
      text: "1010000096",
    },
    {
      icon: beneficiary,
      title: "Beneficiary Name",
      text: "Muhammad Abdullah",
    },
  ];
  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo mb-4">
            <BackArrow
              className="back_arrow"
              onClick={() => navigate("/Subscription-Plan")}
            />
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="36px" weight="bold" className="mb-3">
              Signup With
              <SpanStyled color="var(--themeColor)" size="36px" weight="bold">
                PTM
              </SpanStyled>
            </HeadingStyled>
            <Box>
              <HeadingStyled size="24px" weight="bold" className="mt-3">
                Bank Account Information
              </HeadingStyled>
            </Box>
            <Box>
              <SpanStyled color="var(--grey)" className="my-3">
                Please see the account info below and send total amount
              </SpanStyled>
            </Box>
            <Box>
              <BankDetailWrap>
                {BankDetailArray.map((item, key) => (
                  <BankDetailList
                    {...item}
                    key={item.title + key}
                    className="BankListing"
                  />
                ))}
                <SeeInvoice>
                  <SpanStyled>
                    <Invoice width="24px" />
                  </SpanStyled>
                  See Invoice
                </SeeInvoice>
              </BankDetailWrap>
            </Box>
            <HeadingStyled size="24px" className="mb-0 mt-4">
              Upload Receipt
            </HeadingStyled>
            <FileUploader className="d-block w-100 mt-2">
              <Form
                as="input"
                type="file"
                accept="image/png, image/jpeg"
                className="d-none"
              />
              <Box className="d-flex align-items-center justify-content-center">
                <Upload size={20} />
                <SpanStyled color="var(--greyText)" family="var(--medium)">
                  Upload Document
                </SpanStyled>
              </Box>
            </FileUploader>
            <ButtonStyled
              className="button_text my-4"
              onClick={() => navigate("/")}
            >
              <SpanStyled>Signup</SpanStyled>
            </ButtonStyled>
          </LeftBox>
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Efficiently powerful tasks, collaborate seamlessly, and boost productivity with our intuitive and powerful task management tool. Simplify your workflow today!"
          />
        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
