import { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "../../Styled/AllImages";
import { showMembersModal } from "../../Store/slices/HelperSlice";
import { RadioButton } from "../../Styled/FormElements";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
	AvatarGroup,
	Box,
	BoxCheck,
	FormEleWrapper,
	LabelStyled,
	SpanStyled,
	TextStyled,
} from "../../Styled/Elements";
import { EditTaskToTeam } from "./addTask/EditTaskToTeam";
import { useDispatch } from "react-redux";
import { ViewTaskModal } from "../../Components/Modals/ViewTask/ViewTask";
import { ArrowRightCircle } from "react-bootstrap-icons";

export const ListView = () => {
	const [showModal, setModal] = useState(false);
	const [showAssignTskToTeamModal, setShowAssignTskToTeamModal] = useState(false);
	const [showEditTaskToTeamModal, setShowEditTaskToTeamModal] = useState(false);

	const [tableVisibility, setTableVisibility] = useState({
		toDo: true,
		inProgress: true,
		overdue: true,
		complete: true,
	});
	const dispatch = useDispatch()
	const InventoryColumns = [
		{
			name: "id",
			label: "ID",
		},
		{
			name: "taskName",
			label: "Task Name",
			render: (text, item) => (
				<SpanStyled className="d-flex align-items-center">
					<BoxCheck BoxCheckClass={item.color.class} />
					<TextStyled className="mb-0 mx-1" color="var(--grey)">{text}</TextStyled>
				</SpanStyled>
			)
		},
		{
			name: "type",
			label: "Type",
			render: (text, item) => (
				<RadioButton
					color={item.color.stateVar}
					name="intExt"
					label={''}
				/>
			),
		},
		{
			name: "status",
			label: "Status",
		},
		{
			name: "executor",
			label: "Executor",
			render: (text) => <AvatarGroup data={text} dialog={() => dispatch(showMembersModal(true))} width='26px' height='24px' />,
		},
		{
			name: "Start",
			label: "Start",
		},
		{
			name: "Duration",
			label: "Duration",
		},
		{
			name: "End",
			label: "End",
			render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
		},
	];
	const rows = [
		{
			id: '00123',
			taskName: "Android Development",
			type: "toDo",
			status: "Started",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			color: {
				class: "boxLightGrey",
				stateVar: "var(--purple)"
			},
			state: 'toDo'
		},
		{
			id: '00123',
			taskName: "Android Development",
			type: "inProgress",
			status: "Started",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			color: {
				class: "boxThemeColor",
				stateVar: "var(--green2)"
			},
			state: 'inProgress'
		},
		{
			id: '00123',
			taskName: "Android Development",
			type: "overdue",
			status: "Started",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			color: {
				class: "boxRed",
				stateVar: "var(--purple)"
			},
			state: 'overdue'
		},
		{
			id: '00123',
			taskName: "Android Development",
			type: "complete",
			status: "Started",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			color: {
				class: "boxGreen",
				stateVar: "var(--green2)"
			},
			state: 'complete'
		},
	];

	const onDelete = () => {
		setModal(true);
	};
	const openAddTskModal = () => {
		setShowAssignTskToTeamModal(true)
	}
	const openEdiTaskModal = () => {
		setShowEditTaskToTeamModal(true)
	}
	const toggleTableVisibility = (tableName) => {
		setTableVisibility((prevVisibility) => ({
			...prevVisibility,
			[tableName]: !prevVisibility[tableName],
		}));
	};
	const renderDropdownIcon = (tableName) => {
		const isTableVisible = tableVisibility[tableName];

		if (isTableVisible) {
			return (
				<Box className="pb-2" onClick={() => toggleTableVisibility(tableName)}>
					<Dropdownicon>
						<Dropdown width="18px" height="18px" />
					</Dropdownicon>
				</Box>
			);
		} else {
			return (
				<Box className="pb-2" onClick={() => toggleTableVisibility(tableName)}>
					<RotatedDropdownIcon>
						<Dropdown width="18px" height="18px" />
					</RotatedDropdownIcon>
				</Box>
			);
		}
	};

	return (
		<ListViewStyled>
			<Box className="main-content">
				<Box className="d-flex">
					<RadioButton
						color="var(--green2)"
						width="16px"
						height="16px"
						name="intExt"
						label="Standalone tasks"
					// value={item.value}
					// checked={selectedInternalExternal === item.value}
					// onChange={handleInternalExternalRadioButtonChange}
					/>

					<RadioButton
						color="var(--purple)"
						width="16px"
						height="16px"
						name="intExt"
						label="Sequence tasks"
					// value={item.value}
					// checked={selectedInternalExternal === item.value}
					// onChange={handleInternalExternalRadioButtonChange}
					/>
				</Box>
				<FormEleWrapper>
					<Box className="formEleContainer align-items-baseline">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('toDo')}
							<Pill>
								<SpanStyled weight={500} size="14px">
									To Do
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">3 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['toDo'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view", "edit", "delete"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				< FormEleWrapper >
					<Box className="formEleContainer align-items-baseline">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('inProgress')}
							<Pill color="#018de4" textColor="white">
								<SpanStyled weight={500} size="14px">
									In Progress
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['inProgress'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view", "edit", "delete"]}
								idIndex={1}
								//value="inventory-Edit"
								rows={rows.filter(row => row.state === 'inProgress')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				<FormEleWrapper>
					<Box className="formEleContainer align-items-baseline">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('overdue')}
							<Pill color="var(--red)" textColor="white">
								<SpanStyled weight={500} size="14px">
									Overdue
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['overdue'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view", "edit", "delete"]}
								idIndex={1}
								//value="inventory-Edit"
								rows={rows.filter(row => row.state === 'overdue')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}
				<FormEleWrapper>
					<Box className="formEleContainer align-items-baseline">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('complete')}
							<Pill color="#20c012" textColor="white">
								<SpanStyled weight={500} size="14px">
									Complete
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['complete'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view", "edit", "delete"]}
								idIndex={1}
								//value="inventory-Edit"
								rows={rows.filter(row => row.state === 'complete')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				<ViewTaskModal
					showAssignTskToTeamModal={showAssignTskToTeamModal}
					setShowAssignTskToTeamModal={() => setShowAssignTskToTeamModal(false)}
					showSubmitTask={true}  // Submit button Hide-Show
					showPrintTask={false} // Print button Hide-Show
				/>
				<EditTaskToTeam
					showEditTaskToTeamModal={showEditTaskToTeamModal}
					setShowEditTaskToTeamModal={() => setShowEditTaskToTeamModal(false)}
				/>
			</Box >
		</ListViewStyled >
	);
};

const ListViewStyled = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }
  .iaiYpx {
    padding: 9px 0;
}
`
const Dropdownicon = styled.div`
  cursor: pointer;
`
const RotatedDropdownIcon = styled(Box)`
  cursor: pointer;
  transform: rotate(-90deg);
`;
const RadioPositionStyled = styled.div`
  [type="radio"]:not(:checked)+label:before, [type="radio"]:checked+label:before {
    left: 2px;
    top: -16px;
  }
  [type="radio"]:checked+label:after, [type="radio"]:not(:checked)+label:after {
    top: -8px;
    left: 8px;
  }
`
const Pill = styled.div`
  border: none;
  background-color: ${(props) => (props.color ? props.color : "#e4e5e7")};
  // height: 43px;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 10px;
  text-transform: uppercase;
  
  span {
    color: ${(props) => (props.textColor ? props.textColor : "#92929d")};
  }
`;
