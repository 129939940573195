import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Clander } from "../../Styled/AllImages";
import { useDispatch, useSelector } from "react-redux";
import { ClockHistory } from "react-bootstrap-icons";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { MeetingsColumns } from "../../Components/Dashboard/Pages/Tables/tableConstants";
import {
  Box,
  HeadingStyled,
  SpanStyled,
  TextWithIcon,
} from "../../Styled/Elements";
import {
  showMeetingsReschduleModal,
  showMembersModal,
} from "../../Store/slices/HelperSlice";

export const MyMeeting = () => {
  const navigate = useNavigate();
  const helpers = useSelector((state) => state.helpers);
  const dispatch = useDispatch();
  const rows = [
    {
      name: "IR Team",
      description: "96325871",
      type: "Remove",
      date: "01-02-2023",
      start_time: "start meeting",
      Duration: "25 Minutes",
      end_time: "2:00 PM",
      members: [
        {
          name: "Arham",
        },
        {
          name: "naeem",
        },
        {
          name: "Ali cheema",
        },
      ],
      re_shedule: [
        {
          name: "arham",
        },
        {
          name: "Naeem",
        },
      ],
    },
  ];

  return (
    <>
      <ListTable
        type="users"
        onButton={() => navigate("/start-meeting")}
        idIndex={1}
        options={["view", "button"]}
        rows={rows}
        columns={MeetingsColumns}
      />

      <Modal
        show={helpers?.membersModal}
        className="members-popup"
        onHide={() => dispatch(showMembersModal(false))}
        centered
      >
        <Modal.Header closeButton>
          <HeadingStyled size="18px" weight="600" color="var(--white)">
            Members
          </HeadingStyled>
        </Modal.Header>
        <Modal.Body>
          <Box className="d-flex align-items-center mb-3">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
          <Box className="d-flex align-items-center mb-3">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
          <Box className="d-flex align-items-center">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
        </Modal.Body>
      </Modal>
      <Box>
        <Modal
          size="lg"
          show={helpers?.meetingSchedule}
          className="re-scedule-meeting-modal"
          onHide={() => dispatch(showMeetingsReschduleModal(false))}
          centered
        >
          <Modal.Header closeButton>
            <HeadingStyled size="18px" weight="600">
              Re-schedule Request
            </HeadingStyled>
          </Modal.Header>
          <Modal.Body className="p-2">
            <Box className="table-responsive custom-scroll overflow-x-auto">
              <table className="w-100 re-scedule-meeting p-2">
                <thead>
                  <tr>
                    <th className="text-uppercase px-1">User</th>
                    <th className="text-uppercase px-1">Date</th>
                    <th className="text-uppercase px-1">Time</th>
                    <th className="text-uppercase px-1">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2">
                      <Box className="d-flex align-items-center">
                        <Box
                          className="meeting-dropdown mx-2"
                          style={{ width: "30px", height: "30px" }}
                        >
                          <img
                            alt="img"
                            className="img-fluid"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                          />
                        </Box>
                        <HeadingStyled
                          size="14px"
                          className=" mx-1 "
                          color="var(--greyText)"
                          weight={500}
                        >
                          Dell Alienware
                        </HeadingStyled>
                      </Box>
                    </td>
                    <td className="py-2 ">
                      <TextWithIcon
                        icon={Clander}
                        title="01-02-2023"
                        color="var(--greyText)"
                      />
                    </td>
                    <td className="py-2 ">
                      <TextWithIcon
                        icon={ClockHistory}
                        title="8:00 PM"
                        color="var(--greyText)"
                      />
                    </td>
                    <td className="py-2">
                      <SpanStyled size="14px" color="var(--greyText)">
                        Other Meeting
                      </SpanStyled>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <Box className="d-flex align-items-center">
                        <Box
                          className="meeting-dropdown mx-2"
                          style={{ width: "30px", height: "30px" }}
                        >
                          <img
                            alt="img"
                            className="img-fluid"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                          />
                        </Box>
                        <HeadingStyled
                          size="14px"
                          className=" mx-1 "
                          color="var(--greyText)"
                          weight={500}
                        >
                          Dell Alienware
                        </HeadingStyled>
                      </Box>
                    </td>
                    <td className="py-2 ">
                      <TextWithIcon
                        icon={Clander}
                        title="01-02-2023"
                        color="var(--greyText)"
                      />
                    </td>
                    <td className="py-2 ">
                      <TextWithIcon
                        icon={ClockHistory}
                        title="8:00 PM"
                        color="var(--greyText)"
                      />
                    </td>
                    <td className="py-2">
                      <SpanStyled size="14px" color="var(--greyText)">
                        Other Meeting
                      </SpanStyled>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Modal.Body>
        </Modal>
      </Box>
    </>
  );
};
