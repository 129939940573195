import { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { InputFloating, RadioButton } from "../../../Styled/FormElements";
import {
  Box,
  FormEleWrapper,
  LabelStyled,
  ButtonStyled,
} from "../../../Styled/Elements";

export const Step2 = (props) => {
  const [chkTaskType, setchkTaskType] = useState(null);
  const TaskType = [
    {
      label: "Standalone",
      value: "standalone",
    },
    {
      label: "Relational",
      value: "relational",
    },
  ];
  const MultiOptions = [
    {
      cat: "Group 1",
      key: "Android Development",
    },
    {
      cat: "Group 1",
      key: "iOS Development",
    },
    {
      cat: "Group 1",
      key: "Web Development",
    },
    {
      cat: "Group 1",
      key: "Media Channel",
    },
  ];
  const TeamManager = [
    {
      label: "Task Manager",
      value: "taskManager",
    },
    {
      label: "Team",
      value: "team",
    },
  ];

  // const MultiOptions = options.map((option, index) => ({
  // 	...option,
  // 	value: index,
  //   }));
  //   const customOptionRenderer = (option) => (
  // 	<div dangerouslySetInnerHTML={{ __html: option.key }} />
  //   );
  const handleTaskTypeBtnCage = (e) => {
    setchkTaskType(e.target.value);
  };
  if (props.currentStep !== 2) {
    return null;
  }

  return (
    <>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Task Name</LabelStyled>
          <InputFloating type="text" placeholder="Android App UX/UI Design" />
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Type</LabelStyled>
          <Box className="w-100">
            <Box className="d-flex w-100 radioListWrapper mb-2">
              {TaskType.map((item, i) => (
                <Box key={i} className="radioList">
                  <RadioButton
                    color="var(--grey)"
                    name="taskType"
                    label={item.label}
                    value={item.value}
                    checked={chkTaskType === item.value}
                    onChange={handleTaskTypeBtnCage}
                  />
                </Box>
              ))}
            </Box>
            {chkTaskType === "relational" && (
              <Multiselect
                avoidHighlightFirstOption="false"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                displayValue="key"
                placeholder="Select Task"
                options={MultiOptions}
                showCheckbox
              />
            )}
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Duration</LabelStyled>
          <InputFloating type="text" placeholder="Duration days" />
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Implementer</LabelStyled>
          <Box className="w-100">
            <Box className="d-flex w-100 radioListWrapper mb-2">
              {TeamManager.map((item, i) => (
                <Box key={i} className="radioList">
                  <RadioButton
                    color="var(--grey)"
                    name="tmmgr"
                    label={item.label}
                    value={item.value}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">Description</LabelStyled>
          <InputFloating textarea placeholder="Add Description" />
        </Box>
        <Box className="formBtns formBtnsInner">
          <ButtonStyled
            border="1px solid var(--themeColor)"
            color="var(--themeColor)"
            bg="var(--white)"
          >
            Save & Add Another Task
          </ButtonStyled>
        </Box>
      </FormEleWrapper>

      {/* 		
		<FormEleWrapper>
			<Box className="formEleContainer align-items-start">
				<LabelStyled className="labelForm pt-1">Implementor type</LabelStyled>
				<Box className="w-100">
					<Box className="d-flex w-100 radioListWrapper">
					{InternalExternal.map((item, i) => (
						<Box key={i} className="radioList">
						<RadioButton
							color="var(--grey)"
							name="intExt"
							label={item.label}
							value={item.value}
							checked={selectedInternalExternal === item.value}
							onChange={handleInternalExternalRadioButtonChange}
						/>
						</Box>
					))}
					</Box>

					{showContractOrderRadioButtons && (
						<Box className="d-flex w-100 radioListWrapper mt-4 mb-3">
							{ContractOrder.map((item, i) => (
							<Box key={i} className="radioList">
								<RadioButton
								name="contractOrder"
								label={item.label}
								value={item.value}
								onChange={handleContractOrderRadioButtonChange}
								/>
							</Box>
							))}
						</Box>
					)}

					{selectedInternalExternal === "external" &&
					selectedContractOrder === "other" && (
						<InputFloating type="text" placeholder="Other" />
					)}

					{selectedInternalExternal === "external" &&
					(selectedContractOrder === "contract" ||
						selectedContractOrder === "purchase" ||
						selectedContractOrder === "other") && (
						<InnerOpContainer className="second">
						<Box className="formEleContainer inp-white">
							<LabelStyled className="labelForm">
							Implementor Name
							</LabelStyled>
							<InputFloating type="text" placeholder="Person / Company" />
						</Box>
						<Box className="formEleContainer inp-white">
							<LabelStyled className="labelForm">
							Contact Person
							</LabelStyled>
							<InputFloating
							type="text"
							placeholder="Contact Person Name"
							/>
						</Box>
						<Box className="formEleContainer inp-white">
							<LabelStyled className="labelForm">Email</LabelStyled>
							<InputFloating type="text" placeholder="Email" />
						</Box>
						<Box className="formEleContainer inp-white">
							<LabelStyled className="labelForm">Cell Phone</LabelStyled>
							<InputFloating type="text" placeholder="Cell Phone" />
						</Box>
						</InnerOpContainer>
					)}
				</Box>
			</Box>
		</FormEleWrapper>
		<FormEleWrapper>
			<Box className="formEleContainer">
			<LabelStyled className="labelForm">Owner</LabelStyled>
			<ImageUploader label="Select Owner" />
			</Box>
		</FormEleWrapper>
		<FormEleWrapper>
			<Box className="formEleContainer">
			<LabelStyled className="labelForm">Users</LabelStyled>
			<ImageUploader label="Add User" />
			</Box>
		</FormEleWrapper>
		<FormEleWrapper>
			<Box className="formEleContainer">
			<LabelStyled className="labelForm">Project Manager</LabelStyled>
			<ImageUploader label="Add Project Manager" />
			</Box>
		</FormEleWrapper>

		<FormEleWrapper>
			<Box className="formEleContainer align-items-start">
				<LabelStyled className="labelForm pt-2">Project Approval Letter</LabelStyled>
				<Box className="w-100">
					<FileUploader />
				</Box>
			</Box>
		</FormEleWrapper>
		<FormEleWrapper>
			<Box className="formEleContainer align-items-start">
				<LabelStyled className="labelForm pt-2">Contract Document</LabelStyled>
				<Box className="w-100">
					<FileUploader />
				</Box>
			</Box>
		</FormEleWrapper>
		<FormEleWrapper>
			<Box className="formEleContainer align-items-start">
				<LabelStyled className="labelForm pt-2">Attachment</LabelStyled>
				<Box className="w-100">
					<FileUploader />
				</Box>
			</Box>
		</FormEleWrapper>

		<FormEleWrapper>
			<Box className="formEleContainer align-items-start">
				<LabelStyled className="labelForm pt-1">Payment Method</LabelStyled>
				<Box className="w-100">
					<Box className="d-flex w-100 radioListWrapper mb-2">
						{PaymentMethod.map((item, i) => (
							<Box key={i} className="radioList">
							<RadioButton
								color="var(--grey)"
								name="intExt"
								label={item.label}
								value={item.value}
								checked={chkPaymentMethod === item.value}
								onChange={handlePaymentMethodBtnCage}
							/>
							</Box>
						))}
					</Box>

					{chkPaymentMethod === "percentage" &&(
						<InputFloating type="text" placeholder="Percentage Based" />
					)}
					{chkPaymentMethod === "monthly" &&(
						<InputFloating type="text" placeholder="Monthly Based" />
					)}
					{chkPaymentMethod === "others" &&(
						<InputFloating type="text" placeholder="Other Payment Method" />
					)}
					
				</Box>
			</Box>
			<Box className="formEleContainer">
				<LabelStyled className="labelForm">Description</LabelStyled>
				<InputFloating textarea placeholder="Add Description" />
			</Box>
		</FormEleWrapper>
		 */}
    </>
  );
};
