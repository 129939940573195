import React, { useState } from "react";
import Calendar from "react-calendar";
import { CustomModal } from "../../../Components/Modals/CustomModal";
import { calendar } from "../../../Styled/AllImages";
import { InputStyledWrap } from "../../../Styled/styled";
import { MembersDropDown } from "../../../Components/Dashboard/Pages/Projects/MemberDropDown";
import AddTasks from "../../../Components/Dashboard/Pages/Projects/addTasks/MainData";



import {
	Box,
	CalendarContainer,
	FormEleWrapper,
	LabelStyled,
	SpanStyled,

} from "../../../Styled/Elements";
import {
	FileUploader,
	InputFloating,
	InputWithIcon,
	SelectList,
	RadioButton,
	Pill,
} from "../../../Styled/FormElements";
import { ReasonModal } from "../../../Components/Modals/ReasonModal";
import { StaticDatePickerContainer } from "../../../Components/Dashboard/Pages/StaticRangePicker";
import AddMemberRole from "../../../Components/Dashboard/Pages/Projects/addMemberRole/MainData";

export const EditTaskToTeam = ({
	showEditTaskToTeamModal,
	setShowEditTaskToTeamModal,
}) => {


	const [showDateModal, setShowDateModal] = useState(false);
	// const [date, onDataChange] = useState(new Date());
	const [chkTaskType, setchkTaskType] = useState(null);
	const [chkImplementor, setchkImplementor] = useState(null);
	const TaskType = [
		{
			label: "Standalone",
			value: "standalone",
		},
		{
			label: "Relational",
			value: "relational",
		},
	];
	const Implementor = [
		{
			label: "Task Manager",
			value: "tTaskmanager",
		},
		{
			label: "Team",
			value: "team",
		},
	];


	const handleTaskTypeBtnCage = (e) => {
		setchkTaskType(e.target.value);
	};
	const handleImplementorBtnCage = (e) => {
		setchkImplementor(e.target.value);
	};




	return (
		<CustomModal
			showModal={showEditTaskToTeamModal}
			onHide={() => setShowEditTaskToTeamModal(false)}
			heading="Edit Task"
			closeButton={true}
			buttons={[
				{ text: "Update", variant: "primary" },
				{ text: "Cancel", variant: "danger" },
			]}
		>
			<FormEleWrapper>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">
						Task Name
					</LabelStyled>
					<Box className="w-100">
						<InputStyledWrap>
							<InputWithIcon type="text" placeholder="Enter Task Name" />
						</InputStyledWrap>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Type</LabelStyled>
					<Box className="w-100">
						<Box className="d-flex w-100 radioListWrapper mb-2">
							{TaskType.map((item, i) => (
								<Box key={i} className="radioList">
									<RadioButton
										color="var(--grey)"
										name="taskType"
										label={item.label}
										value={item.value}
										checked={chkTaskType === item.value}
										onChange={handleTaskTypeBtnCage}
									/>
								</Box>
							))}
						</Box>
						{chkTaskType === "relational" && (

							<AddTasks />


						)}
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Duration</LabelStyled>
					<Box className="cursor-pointer" onClick={() => setShowDateModal(true)} >
						{/* <LabelStyled className="labelForm pt-1">10 Days</LabelStyled> */}
						<Pill text="3 Days, May  9 - 12" icon={calendar} />
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Implementer</LabelStyled>
					<Box className="w-100">
						<Box className="d-flex w-100 radioListWrapper mb-2">
							{Implementor.map((item, i) => (
								<Box key={i} className="radioList">
									<RadioButton
										color="var(--grey)"
										name="implementor"
										label={item.label}
										value={item.value}
										checked={chkImplementor === item.value}
										onChange={handleImplementorBtnCage}
									/>
								</Box>
							))}
						</Box>
						{chkImplementor === "team" && (
							<AddMemberRole />
						)}
					</Box>
				</Box>
			</FormEleWrapper>

			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Description</LabelStyled>
					<Box className="w-100">
						<Box className=" pb-2 textarea-field-lg">
							<InputFloating
								row={5}
								textarea
								type="textarea"
								name={"technical_aspect"}
								placeholder="Add Description"
							/>
						</Box>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-2">Attachment</LabelStyled>
					<Box className="w-100">
						<FileUploader />
					</Box>
				</Box>
			</FormEleWrapper>

			<ReasonModal
				showModal={showDateModal}
				onHide={() => setShowDateModal(false)}
				//heading="Task Timeline"
				closeButton={true}
				buttons={[
					{ text: "Ok", variant: "primary" },
					{ text: "Cancel", variant: "danger" },
				]}
				size="sm"
			>
				<CalendarContainer>
					<SpanStyled size="14px" family="var(--medium)" color="var(--darkBlue)" >Number of Days</SpanStyled>
					<Box className="w-100">
						<InputStyledWrap>
							<InputWithIcon type="text" placeholder="Add Number of Days" />
						</InputStyledWrap>
					</Box>
					<Box className="w-100 text-center pt-2 pb-2">
						<SpanStyled size="18px" family="var(--semiBold)" color="var(--darkBlue)" >Task Timeline</SpanStyled>
					</Box>
					<StaticDatePickerContainer />
					{/* <Calendar onChange={onDataChange} value={date} /> */}
					{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer
							components={[
								'DateRangePicker',
								'MobileDateRangePicker',
								'DesktopDateRangePicker',
								'StaticDateRangePicker',
							]}
						>
							<DemoItem label="Static variant" component="StaticDateRangePicker">
								<StaticDateRangePicker
									defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
									sx={{
										[`.${pickersLayoutClasses.contentWrapper}`]: {
											alignItems: 'center',
										},
									}}
								/>
							</DemoItem>
						</DemoContainer>
					</LocalizationProvider> */}
				</CalendarContainer>
			</ReasonModal>

			{/* <CustomModal
				showModal={showDateModal}
				onHide={() => setShowDateModal(false)}
				heading="Task Timeline"
				closeButton={true}
				buttons={[
					{ text: "Ok", variant: "primary" },
					{ text: "Cancel", variant: "danger" },
				]}
				size="sm"
				>
				<CalendarContainer>
					<Calendar onChange={onDataChange} value={date} />
				</CalendarContainer>
			</CustomModal> */}
		</CustomModal>
	);
};
