import styled from "styled-components";

export const SidebarStyled = styled.aside`
  height: 100%;
  padding-left: 20px;
  width: 248px;
  min-width: 248px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 105;
  transition: 0.3s;
  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.03);
  background-color: var(--white);

  a {
    padding: 10px 15px;
    transition: all ease-in-out 0.4s;
    border-radius: 8px;
    font-size: 15px;
    &:not(.active) {
      color: var(--grey);
      svg {
        [stroke] {
          stroke: var(--grey);
        }
      }
    }
    .list-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 20px;
      height: auto;
    }
    &:hover,
    &.active {
      color: #ffffff;
      background-color: var(--themeColor);
      svg {
        [stroke] {
          stroke: var(--white);
        }
        [fill] {
          fill: var(--white);
        }
      }
      span {
        // color: var(--white-color);
        // font-weight: 600;
      }
    }
    &.active {
      font-family: var(--semiBold);
    }
  }
  .logo-container {
    padding: 20px 10px 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-list {
    padding: 0px 20px 0 0px;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
  .subMenu {
    text-align: justify;
    & > li > a:hover,
    &.subActive {
      color: var(--greyText);
      background-color: var(--outlineC);
    }
  }
  .subActive {
    color: var(--greyText);
    background-color: var(--outlineC);
  }
`;
