import React, { useState } from "react";
import DataWrapper from "./DataWrapper";
import CloneWrapper from "./CloneWrapper";
import {
  avatarImg,
  profile,
  profile2,
  profile3,
  profile4,
} from "../../../../../Styled/AllImages";
import styled from "styled-components";

const AddMemberRole = () => {
  const [selectedMainParents, setSelectedMainParents] = useState([]);
  const [mainParents, setMainParents] = useState([
    {
      id: 1,
      profile: profile,
      userName: "Savannah Nguyen",
      userRole: "Select Role",
    },
    {
      id: 2,
      profile: profile2,
      userName: "Kristin Watson",
      userRole: "Select Role",
    },
    {
      id: 3,
      profile: profile3,
      userName: "Brooklyn Simmons",
      userRole: "Select Role",
    },
    {
      id: 4,
      profile: profile3,
      userName: "Brooklyn Simmons",
      userRole: "Select Role",
    },
  ]);

  const handleMainParentClick = (mainParent) => {
    if (selectedMainParents.some((selected) => selected.id === mainParent.id)) {
      setSelectedMainParents(
        selectedMainParents.filter((selected) => selected.id !== mainParent.id)
      );
    } else {
      setSelectedMainParents([...selectedMainParents, mainParent]);
    }
  };

  const handleMemberRoles = (role, id) => {
    setMainParents(
      mainParents.map((selected) => {
        if (selected.id === id) {
          return {
            ...selected,
            userRole: role,
          };
        } else return selected;
      })
    );

    if (selectedMainParents.some((selected) => selected.id === id)) {
      setSelectedMainParents(
        selectedMainParents.map((selected) => {
          if (selected.id === id) {
            return {
              ...selected,
              userRole: role,
            };
          } else return selected;
        })
      );
    }
  };

  const handleRemoveClick = (parentId) => {
		// Remove the selected data based on its ID
		setSelectedMainParents(selectedMainParents.filter((selected) => selected.id !== parentId));
	};

  return (
    <MultiUserRow>
      <CloneWrapper selectedMainParents={selectedMainParents} onRemoveClick={handleRemoveClick} />
      <DataWrapper
        mainParents={mainParents}
        onMainParentClick={handleMainParentClick}
        selectedMainParents={selectedMainParents}
        handleMemberRoles={handleMemberRoles}
      />
    </MultiUserRow>
  );
};

export default AddMemberRole;

const MultiUserRow = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;
