import styled from "styled-components";

export const PaymentDateRangeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    .DateRangeInput {
        border: 0;
        padding: 0;
    }
    
`