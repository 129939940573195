import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputFloating, InputWithIcon } from "../../Styled/FormElements";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { ReceivedMeetingsColumns } from "../../Components/Dashboard/Pages/Tables/tableConstants";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  TextStyled,
} from "../../Styled/Elements";
import {
  closeReScheduleModal,
  closeViewDownloadModall,
} from "../../Store/slices/HelperSlice";

export const ReceviedMeetingList = () => {
  const helpers = useSelector((state) => state.helpers);
  const dispatch = useDispatch();
  const rows = [
    {
      name: "IR Team",
      description: "96325871",
      type: "Remove",
      date: "01-02-2023",
      start_time: "start meeting",
      Duration: "25 Minutes",
      agenda: "View or Download",
      end_time: "2:00 PM",
      action_status: "pending",
    },
    {
      name: "IR Team",
      description: "96325871",
      type: "Remove",
      date: "01-02-2023",
      start_time: "start meeting",
      Duration: "25 Minutes",
      agenda: "View or Download",
      end_time: "2:00 PM",
      action_status: "accepted",
    },
    {
      name: "IR Team",
      description: "96325871",
      type: "Remove",
      date: "01-02-2023",
      start_time: "start meeting",
      Duration: "25 Minutes",
      end_time: "2:00 PM",
      agenda: "View or Download",
      action_status: "rejected",
    },
    {
      name: "IR Team",
      description: "96325871",
      type: "Remove",
      date: "01-02-2023",
      start_time: "start meeting",
      Duration: "25 Minutes",
      end_time: "2:00 PM",
      agenda: "View or Download",
      action_status: "re-schdule",
    },
  ];
  return (
    <Box className="meeting-table">
      <ListTable rows={rows} columns={ReceivedMeetingsColumns} />
      <Modal
        centered
        show={helpers?.reScheduleModal}
        onHide={() => dispatch(closeReScheduleModal())}
      >
        <Modal.Header closeButton>
          <HeadingStyled size="18px" weight="600">
            Reschedule Meeting
          </HeadingStyled>
        </Modal.Header>
        <Modal.Body>
          <TextStyled lh={1.6} size="14px">
            Suggest a new meeting date & time and submit the form to request a
            meeting reschedule.
          </TextStyled>
          <Box className="mb-3">
            <InputWithIcon type="date" />
          </Box>
          <Box className="mb-3">
            <InputWithIcon type="time" />
          </Box>
          <Box className="mb-3">
            <InputFloating textarea placeholder="Reason" />
          </Box>
          <Box className="bTNS d-flex">
            <ButtonStyled
              className="py-2 mx-2"
              bg="var(--themeColor)"
              width="240px"
            >
              Request Re-Schedule
            </ButtonStyled>
            <ButtonStyled className="py-2 mx-2" bg="var(--red)" width="120px">
              Cancel
            </ButtonStyled>
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={helpers?.viewDownloadModal}
        onHide={() => dispatch(closeViewDownloadModall())}
      >
        <Modal.Header closeButton>
          <HeadingStyled size="18px" weight="600">
            Agenda
          </HeadingStyled>
        </Modal.Header>
        <Modal.Body>
          <TextStyled lh={1.6} size="14px">
            Suggest a new meeting date & time and submit the form to request a
            meeting reschedule.
          </TextStyled>
          <Box className="mb-3">
            <HeadingStyled size="18px" weight="600">
              Attachments
            </HeadingStyled>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};
