import { Col, Row } from "react-bootstrap";
import { PrTotal, avatarImg } from "../../Styled/AllImages";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
  SpanStyled,
  TextWithIcon,
  FormEleWrapper,
  LabelStyled,
} from "../../Styled/Elements";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { InputStyledWrap } from "../../Styled/styled";
import {
  FileUploader,
  InputWithIcon,
  RadioButton,
  SelectList,
} from "../../Styled/FormElements";
import { Plus, Search } from "react-bootstrap-icons";
import { useState } from "react";
import { CustomModal } from "../../Components/Modals/CustomModal";

const rows = [
  {
    sequenceNumber: "00125",
    documentName: "Android Development",
    category: "Project Related",
    documentDate: "May 23",
    uploaderName: "Albert Flores",
  },
  {
    sequenceNumber: "00126",
    documentName: "iOS Develpment",
    category: "Others",
    documentDate: "May 23",
    uploaderName: "Jeny Wilson",
  },
];

const InventoryColumns = [
  {
    name: "sequenceNumber",
    label: "Sequence Number",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "documentName",
    label: "Document Name",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "category",
    label: "Category",
  },
  {
    name: "documentDate",
    label: "Document Date",
  },
  {
    name: "uploaderName",
    label: "Uploader Name",
  },
];

const ProjectsArray = [
  {
    icon: PrTotal,
    count: "200",
    bg: "var(--lightBlue)",
    title: "Archived Projects",
  },
];

export const LibraryOthers = () => {
  const [viewModal, setViewModal] = useState(false);
  const [showAddDocModal, setShowAddDocModal] = useState(false);
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Others Library
            </HeadingStyled>
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search by Document name"
                icon={Search}
              />
            </InputStyledWrap>
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search By uploader"
                icon={Search}
              />
            </InputStyledWrap>
            <Box className="boxRBt">
              <ButtonStyledMedium
              //   onClick={() => navigate("/project/add-project")}
              >
                Search
              </ButtonStyledMedium>
            </Box>
            <Box className="boxRBt">
              <ButtonStyledMedium onClick={() => setShowAddDocModal(true)}>
                Add Document
                <Plus />
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <Row className=" main-content px-row-10 mb-4">
          <Row className="px-row-10">
            <Col xs={12}>
              <Row className="px-row-10 row-tile row-tile-4">
                {ProjectsArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-tile col-tile-4"
                    sm={3}
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <ListTable
            onView={(item) => setViewModal(item)}
            options={["view"]}
            idIndex={1}
            value="inventory-Edit"
            rows={rows}
            columns={InventoryColumns}
          />

          <CustomModal
            showModal={viewModal}
            onHide={() => setViewModal(``)}
            heading="Document Details"
            closeButton={true}
            buttons={[{ text: "Download Document", variant: "primary" }]}
          >
            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">Related to</LabelStyled>
                <LabelStyled className="labelForm pt-1">
                  Android App UX/UI Design
                </LabelStyled>
              </Box>
            </FormEleWrapper>

            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Document Name
                </LabelStyled>
                <LabelStyled className="labelForm pt-1">
                  {viewModal.documentName}
                </LabelStyled>
              </Box>
            </FormEleWrapper>

            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Show Document to
                </LabelStyled>
                <LabelStyled className="labelForm pt-1">All</LabelStyled>
              </Box>
            </FormEleWrapper>

            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Upload Date
                </LabelStyled>
                <LabelStyled className="labelForm pt-1">May 24</LabelStyled>
              </Box>
            </FormEleWrapper>

            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Uploader Name
                </LabelStyled>
                <LabelStyled className="labelForm pt-1">Brooklyn</LabelStyled>
              </Box>
            </FormEleWrapper>
          </CustomModal>

          <CustomModal
            showModal={showAddDocModal}
            onHide={() => setShowAddDocModal(false)}
            heading="Add Document"
            closeButton={true}
            buttons={[
              { text: "Add", variant: "primary" },
              { text: "Cancel", variant: "danger" },
            ]}
            size="xl"
          >
            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">Related to</LabelStyled>
                <Box className="d-flex w-100 radioListWrapper">
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Project"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Tasks"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />

                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Others"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />
                </Box>
              </Box>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1"></LabelStyled>
                <Box className="w-100">
                  <InputStyledWrap>
                    <InputWithIcon type="text" placeholder="Task Name" />
                  </InputStyledWrap>
                </Box>
              </Box>
            </FormEleWrapper>
            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Document Name
                </LabelStyled>
                <Box className="w-100">
                  <InputStyledWrap>
                    <InputWithIcon type="text" placeholder="Document Name" />
                  </InputStyledWrap>
                </Box>
              </Box>
            </FormEleWrapper>

            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1">
                  Show Document to
                </LabelStyled>
                <Box className="d-flex w-100 radioListWrapper">
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="All"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Job Role"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />

                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Project Member"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />

                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label="Task Member"
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />
                </Box>
              </Box>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-1"></LabelStyled>
                <Box className="w-100">
                  <SelectList
                    options={["Project Member", "Today"]}
                    className="custom-select-design med-sel"
                  />
                </Box>
              </Box>
            </FormEleWrapper>
            <FormEleWrapper>
              <Box className="formEleContainer align-items-start">
                <LabelStyled className="labelForm pt-2">
                  Upload Document
                </LabelStyled>
                <Box className="w-100">
                  <FileUploader />
                </Box>
              </Box>
            </FormEleWrapper>
          </CustomModal>
        </Row>
      </Box>
    </>
  );
};
