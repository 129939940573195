import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import { ProjectDetials } from "./projectDetails";
import { calendar } from "../../../Styled/AllImages";
import { InputStyledWrap } from "../../../Styled/styled";
import { InputWithIcon, SelectList } from "../../../Styled/FormElements";
import { ProjectsTile } from "../../../Components/Dashboard/Pages/ProjectsTile";
import { ListTable } from "../../../Components/Dashboard/Pages/Tables/ListTable";
import { ProgressBarTile } from "../../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import { ProgressGraph1 } from "../../../Components/Dashboard/Pages/Projects/ProgressGraph1";
import { InventoryColumns } from "../../../Components/Dashboard/Pages/Tables/tableConstants";
import { SecondaryBar } from "../../../Components/Dashboard/Pages/Reports/SecondaryBar";
import {
	Box,
	ButtonStyledMedium,
	HeadingStyledDB,
	SecondaryBarStyled,
} from "../../../Styled/Elements";
import {
	ProjectCostArray,
	ProjectCostProgBarTileArray,
	ProjectsProgBarTileArray,
	ProjectsTileArray,
	ReportsPrProjectsTileArray,
	ReportsPrProjectsProgBarTileArray,
	ReportsPrProjectCostArray,
	ReportsPrProjectCostProgBarTileArray,
	ReportsPrInternalExternalProjectsArray,
	ReportsPrIntExtProgBarTileArray,
} from "../../../Components/Dashboard/Pages/TilesArray";

const rows = [
	{
		id: "00125",
		ProjectName: "Android Development",
		Type: "Internal",
		ProjectManager: "Savannah Nguyen",
		Phase: "Start",
		Start: "Today, May 24",
		Duration: "6 Days",
		End: "May 30",
		percent: "75%",
		ProgGraph: (
			<ProgressGraph1
				series={[75, 25]}
				colors={["var(--orange)", "var(--outlineC)"]}
			/>
		),
	},
	{
		id: "00126",
		ProjectName: "iOS Development",
		Type: "Internal",
		ProjectManager: "Project Manager",
		Phase: "Planning",
		Start: "May 22",
		Duration: "6 Days",
		End: "May 28",
		percent: "50%",
		ProgGraph: (
			<ProgressGraph1
				series={[50, 50]}
				colors={["var(--themeColor)", "var(--outlineC)"]}
			/>
		),
	},
	{
		id: "00127",
		ProjectName: "Web Development",
		Type: "Internal",
		ProjectManager: "Project Manager",
		Phase: "Governance",
		Start: "May 22",
		Duration: "6 Days",
		End: "May 28",
		percent: "60%",
		ProgGraph: (
			<ProgressGraph1
				series={[60, 40]}
				colors={["var(--purple)", "var(--outlineC)"]}
			/>
		),
	},
	{
		id: "00128",
		ProjectName: "Media Channel",
		Type: "Internal",
		ProjectManager: "Project Manager",
		Phase: "Execution",
		Start: "May 22",
		Duration: "6 Days",
		End: "May 28",
		percent: "90%",
		ProgGraph: (
			<ProgressGraph1
				series={[90, 10]}
				colors={["var(--green2)", "var(--outlineC)"]}
			/>
		),
	},
];
export const ReportsProject = () => {
	const [showModal, setModal] = useState(false);
	// const [selectedProject, setSelectedProject] = useState(null);
	const onDelete = () => {
		setModal(true);
	};

	return (
		<Box>
			<SecondaryBar />
			{/* {selectedProject && <ProjectDetials project={selectedProject} />} */}
			<Box className="main-content">
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Projects
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{ReportsPrProjectsTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}

							{ReportsPrProjectsProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Project Cost
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-3">
							{ReportsPrProjectCostArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-3"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsPrProjectCostProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-3"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Internal/External Projects
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-2">
							{ReportsPrInternalExternalProjectsArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-2"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsPrIntExtProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-3"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>

				<Box>
					<ListTable
						send={"/reports-projects/detail"}
						onDelete={onDelete}
						// onView={setSelectedProject}
						options={["send"]}
						idIndex={1}
						value="inventory-Edit"
						rows={rows}
						columns={InventoryColumns}
					/>
				</Box>
			</Box>
		</Box>
	);
};
