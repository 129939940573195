import { useState } from "react";
import { Download } from "react-bootstrap-icons";
import { InputWithIcon, SelectList, SelectListWithIcon } from "../../../../Styled/FormElements";
import {
	Box,
	ButtonStyledMedium,
	ChartBoxStyled,
	HeadingStyledDB,
	PieBoxStyled,
	SecondaryBarStyled,
	SpanStyled,
} from "../../../../Styled/Elements";
import { DateRangePickersBar } from "../DateRangePickerBar";
// import { DateRangePickersBar } from "../Reports/DateRangePickerBar";

export const SecondaryBar = () => {
	return (
		<SecondaryBarStyled>
			<Box className="secondaryBar">
				<SelectList
					options={["All Time", "This Week", "This Month", "This year", "2 years", "3 years", "5 years"]}
					className="custom-select-design"
				/>

				<DateRangePickersBar />

				<Box className="boxRBt">
					<ButtonStyledMedium
					//   onClick={() => navigate("/project/add-project")}
					>
						<Download /> Download as PDF
					</ButtonStyledMedium>
				</Box>
			</Box>
		</SecondaryBarStyled>
	);
};


