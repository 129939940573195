import { useState } from "react";
import { MyMeeting } from "./MyMeeting";
import { Col, Row } from "react-bootstrap";
import { Calendar } from "react-bootstrap-icons";
import { InputStyledWrap } from "../../Styled/styled";
import { Plus, calendar, clock } from "../../Styled/AllImages";
import { CustomModal } from "../../Components/Modals/CustomModal";
import { MeetingArray } from "../../Components/Dashboard/Pages/TilesArray";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { UserDropDown } from "../../Components/Dashboard/Pages/Projects/UserDropDown";
import {
  InputWithIcon,
  RadioButton,
  SelectList,
} from "../../Styled/FormElements";
import {
  Box,
  ButtonStyledMedium,
  FormEleWrapper,
  HeadingStyled,
  LabelStyled,
  PillStyled,
  SecondaryBarStyled,
  SpanStyled,
} from "../../Styled/Elements";

export const Meeting = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <SelectList
              options={["Select By Type", "Today"]}
              className="custom-select-design med-sel"
            />
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search by Date"
                icon={Calendar}
              />
            </InputStyledWrap>

            <SelectList
              options={["Select By Members", "Today"]}
              className="custom-select-design med-sel"
            />
            <InputStyledWrap>
              <InputWithIcon type="text" placeholder="Search by subject" />
            </InputStyledWrap>
            <Box className="boxRBt">
              <ButtonStyledMedium>Search</ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              My Meetings <SpanStyled> ( June 08,2023 ) </SpanStyled>
            </HeadingStyled>
            <Box className="boxRBt">
              <ButtonStyledMedium onClick={() => setShowModal(true)}>
                <Plus /> Schedule a Meeting
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <Box className="main-content">
          <Col xs={12}>
            <Row>
              {MeetingArray.map((item, key) => (
                <Col lg={3} md={6} xs={12} key={item.title + key} className="">
                  <ProjectsTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
          <MyMeeting />
        </Box>
      </Box>
      <CustomModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        heading="Schedule a Meeting"
        closeButton={true}
        buttons={[
          { text: "Send", variant: "primary" },
          { text: "Save", variant: "primary" },
          { text: "Cancel", variant: "danger" },
        ]}
      >
        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">
              Meeting Subject
            </LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="Enter Subject"
                // icon={calendar}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">Meeting Type</LabelStyled>
            <Box className="w-100 radioListWrapper">
              <Box key="new" className="radioList d-flex gap-2">
                <RadioButton
                  color="var(--grey)"
                  name="intExt"
                  label="Remotely"
                // value={item.value}
                // checked={selectedInternalExternal === item.value}
                // onChange={handleInternalExternalRadioButtonChange}
                />
                <RadioButton
                  color="var(--grey)"
                  name="intExt"
                  label="In Person"
                // value={item.value}
                // checked={selectedInternalExternal === item.value}
                // onChange={handleInternalExternalRadioButtonChange}
                />
              </Box>
              <Box className="w-100 mt-4">
                <InputStyledWrap>
                  <InputWithIcon
                    type="text"
                    placeholder="Enter Location"
                  // icon={calendar}
                  />
                </InputStyledWrap>
              </Box>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">Date</LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="Select Date"
                  icon={calendar}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">Time</LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="Select Time"
                  icon={clock}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">Duration</LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="In Mins"
                // icon={clock}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">
              Enter Meeting After
            </LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="In Mins"
                // icon={clock}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>

        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">
              Delete Meeting Before
            </LabelStyled>
            <Box className="w-100">
              <InputStyledWrap>
                <InputWithIcon
                  type="text"
                  placeholder="In Mins"
                // icon={clock}
                />
              </InputStyledWrap>
            </Box>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">
              Contact Meeting If All Members
            </LabelStyled>
            <Box className="w-100 radioListWrapper">
              <Box key="new" className="radioList d-flex gap-2">
                <RadioButton
                  color="var(--grey)"
                  name="intExt"
                  label="Reject"
                // value={item.value}
                // checked={selectedInternalExternal === item.value}
                // onChange={handleInternalExternalRadioButtonChange}
                />
                <RadioButton
                  color="var(--grey)"
                  name="intExt"
                  label="Re-schedule"
                // value={item.value}
                // checked={selectedInternalExternal === item.value}
                // onChange={handleInternalExternalRadioButtonChange}
                />
              </Box>
            </Box>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer align-items-start">
            <LabelStyled className="labelForm pt-1">Agenda</LabelStyled>
            <PillStyled color="var(--grey)">Write or Upload Agenda</PillStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">Add Participent</LabelStyled>
            <UserDropDown userLabel="Participents" />
          </Box>
        </FormEleWrapper>
      </CustomModal>
    </>
  );
};
