import { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Plus, Filter } from "../../Styled/AllImages";
import { DeleteEntry } from "../../Components/Modals/DeleteEntry";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { ProgressBarTile } from "../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import { InventoryColumns } from "../../Components/Dashboard/Pages/Tables/tableConstants";
import { ProgressGraph1 } from "../../Components/Dashboard/Pages/Projects/ProgressGraph1";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  HeadingStyledDB,
  SecondaryBarStyled,
  SpanStyled,
  SecondaryHeading,
} from "../../Styled/Elements";
import {
  ProjectCostArray,
  ProjectCostProgBarTileArray,
  InternalExternalProjectsArray,
  IntExtProgBarTileArray,
} from "../../Components/Dashboard/Pages/TilesArray";
import { ViewTaskModal } from "../../Components/Modals/ViewTask/ViewTask";

export const Projects = () => {
  //const seriesData = [75, 25];

  const [showModal, setModal] = useState(false);
  const navigate = useNavigate();
  // const [ModalShow, setModalShow] = useState(false)
  // const [ModalSettingShow, setModalSettingShow] = useState(false)
  const rows = [
    {
      id: "00125",
      ProjectName: "Android Development",
      Type: "Internal",
      ProjectManager: "Savannah Nguyen",
      Phase: "Start",
      Start: "Today, May 24",
      Duration: "6 Days",
      End: "May 30",
      percent: "75%",
      ProgGraph: (
        <ProgressGraph1
          series={[75, 25]}
          colors={["var(--orange)", "var(--outlineC)"]}
          width={78}
        />
      ),
    },
    {
      id: "00126",
      ProjectName: "iOS Development",
      Type: "Internal",
      ProjectManager: "Project Manager",
      Phase: "Planning",
      Start: "May 22",
      Duration: "6 Days",
      End: "May 28",
      percent: "50%",
      ProgGraph: (
        <ProgressGraph1
          series={[50, 50]}
          colors={["var(--themeColor)", "var(--outlineC)"]}
          width={78}
        />
      ),
    },
    {
      id: "00127",
      ProjectName: "Web Development",
      Type: "Internal",
      ProjectManager: "Project Manager",
      Phase: "Governance",
      Start: "May 22",
      Duration: "6 Days",
      End: "May 28",
      percent: "60%",
      ProgGraph: (
        <ProgressGraph1
          series={[60, 40]}
          colors={["var(--purple)", "var(--outlineC)"]}
          width={78}
        />
      ),
    },
    {
      id: "00128",
      ProjectName: "Media Channel",
      Type: "Internal",
      ProjectManager: "Project Manager",
      Phase: "Execution",
      Start: "May 22",
      Duration: "6 Days",
      End: "May 28",
      percent: "90%",
      ProgGraph: (
        <ProgressGraph1
          series={[90, 10]}
          colors={["var(--green2)", "var(--outlineC)"]}
          width={78}
        />
      ),
    },
  ];
  const onDelete = () => {
    setModal(true);
  };


  const [showAssignTskToTeamModal, setShowAssignTskToTeamModal] = useState(false);
  const openAddTskModal = () => {
    setShowAssignTskToTeamModal(true)
  }
  //const seriesValue = [50, 50];
  //const [seriesValue, setSeriesValue] = useState([75, 25]);

  //   const changeSeriesValue = () => {
  //     // Change the series value as needed
  //     setSeriesValue([50, 50]);
  //   };
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <SecondaryHeading label="Projects" />
            <Box className="boxRBt">
              <Box className="filterWrap">
                <Filter />
                <SpanStyled>Filter</SpanStyled>
              </Box>
              <ButtonStyledMedium
                onClick={() => navigate("/project/add-project")}
              >
                <Plus /> Add Project
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <ProjectWapper className="main-content">
          <Row className="px-row-10 mb-4">
            <Col xs={12}>
              <HeadingStyledDB family="var(--semiBold)" size="">
                Projects Cost
              </HeadingStyledDB>
              <Row className="px-row-10 mb-4 row-tile row-tile-3">
                {ProjectCostArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-tile col-tile-3"
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
                {ProjectCostProgBarTileArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-prog-tile col-prog-tile-3"
                  >
                    <ProgressBarTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="px-row-10 mb-4">
            <Col xs={12}>
              <HeadingStyledDB family="var(--semiBold)" size="">
                Internal/External Projects
              </HeadingStyledDB>
              <Row className="px-row-10 mb-4 row-tile row-tile-2">
                {InternalExternalProjectsArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-tile col-tile-2"
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
                {IntExtProgBarTileArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-prog-tile col-prog-tile-3"
                  >
                    <ProgressBarTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Box>
            {/* <TableWrapper> */}
            <ListTable
              onView={openAddTskModal}
              onDelete={onDelete}
              options={["view", "delete", "edit", "dollar"]}
              idIndex={1}
              value="inventory-Edit"
              rows={rows}
              columns={InventoryColumns}
            />
            {/* </TableWrapper> */}
          </Box>
          <DeleteEntry
            title="Inventory"
            setModal={setModal}
            showModal={showModal}
          />
        </ProjectWapper>
      </Box>
      <ViewTaskModal
        showAssignTskToTeamModal={showAssignTskToTeamModal}
        setShowAssignTskToTeamModal={() => setShowAssignTskToTeamModal(false)}
        showSubmitTask={true}  // Submit button Hide-Show
        showPrintTask={false} // Print button Hide-Show
      />
    </>
  );
};

const ProjectWapper = styled.div``;
// const TableWrapper = styled.div`
//   .gXchYZ {
//     top: -3px;
//   }
//   .fmzuFM {
//     left: -7px;
//   }
// `
