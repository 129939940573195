import styled from "styled-components";
import { Box, SpanStyled, TextStyled } from "../../../Styled/Elements";

export const ProjectsTile = (props) => {
	const showGraph = props.graph !== undefined; // Check if graph is present
	return (
		<TiledWrapperStyled className="TiledWrapper d-flex align-items-center">
			<Box className="tileTextWrapper">
				<Box className="tile-icon" bg={props.bg}>
					<SvgContainerStyled stroke={props.stroke}>
						<props.icon width="24px" height="24px" />
					</SvgContainerStyled>
				</Box>
				<Box>
					<TextStyled
						className="tile-title"
						dangerouslySetInnerHTML={{ __html: props.title }}
					></TextStyled>
					<SpanStyled className="tile-count">{props.count}</SpanStyled>
				</Box>
			</Box>
			{showGraph && (
				<>
					<Box className="graphPercent">
						<Box className="graphTileF">
							<Box className="graphTeamCont">{props.teamCount}</Box>
							{props.graph}
						</Box>
						<SpanStyled color="var(--darkBlue)" family="var(--semiBold)" size="14px">{props.percentCount}</SpanStyled>
					</Box>
				</>
			)}
		</TiledWrapperStyled>
	);
};

const TiledWrapperStyled = styled.div`
  background: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 20px 10px;
  height: 100%;
  flex-wrap: wrap;
  .tileTextWrapper{
	display: flex;
	align-items: center;
	width: 100%;
  }
  .tile-title {
    font-size: 13px;
    color: var(--greyText) !important;
    line-height: 16px !important;
    margin-bottom: 4px;
  }
  .tile-count {
    font-size: 18px;
    color: var(--darkBlue);
    font-family: var(--semiBold);
    line-height: 18px;
  }
  .tile-icon {
    padding: 15px;
    border-radius: 12.0755px;
    margin-right: 10px;
  }
  .graphPercent{
    display: flex;
    width: 100%;
    align-items: center;
	border-top: 1px solid var(--greyBg);
	margin-top: 14px;
	padding-top: 12px;
	.graphTileF{
		width: 54px;
		min-width: 54px;
		height: 34px;
		position: relative;
		.graphTeamCont{
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: var(--semiBold);
			font-size: 12px;
		}
	}
  }
`;
const SvgContainerStyled = styled.div`
  svg {
    [stroke] {
      stroke: ${({ stroke }) => (stroke ? stroke : "")};
    }
  }
`;
