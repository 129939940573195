import React, { useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Box } from "../app/Styled/Elements";
import { AuthRoutes } from "../app/Router/AuthRoute";
import AdminLayout from "../app/Pages/AdminLayout";
import "react-calendar/dist/Calendar.css";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: "smooth" });
  }, [location]);

  return (
    <Box className="w-100">
      <Routes>
        {AuthRoutes.map((list, key) => (
          <Route key={key} {...list} />
        ))}
        <Route path="/*" element={<AdminLayout />}></Route>
        <Route path="*" element={<Box>404 Not found</Box>} />
      </Routes>
    </Box>
  );
}

export default App;
