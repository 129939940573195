import styled from "styled-components";
import { Box, SpanStyled, TextStyled } from "../../../../Styled/Elements";

const PerformanceIndications = (props) => {
  return (
    <TiledWrapperStyled className="TiledWrapper d-flex align-items-center">
      <PerformanceDataContainer>
        <Box className="tile-icon" bg={props.bg}>
          <SvgContainerStyled>
            <props.icon width="24px" height="24px" />
          </SvgContainerStyled>
        </Box>
        <Box>
          <TextStyled
            className="tile-title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></TextStyled>
          <SpanStyled className="tile-count">{props.count}</SpanStyled>
        </Box>
      </PerformanceDataContainer>
    </TiledWrapperStyled>
  );
};

const TiledWrapperStyled = styled.div`
  padding: 15px 0 0 0px;
  .tile-title {
    font-size: 13px;
    color: var(--greyText) !important;
    line-height: 16px !important;
    margin-bottom: 4px;
  }
  .tile-count {
    font-size: 18px;
    color: var(--darkBlue);
    font-family: var(--semiBold);
    line-height: 18px;
  }
  .tile-icon {
    padding: 12px;
    border-radius: 12.0755px;
    margin-right: 10px;
  }
`;
const PerformanceDataContainer = styled.div`
  display: flex;
  width: 100%;
`;
const SvgContainerStyled = styled.div`
  svg {
    [stroke] {
      stroke: ${({ stroke }) => (stroke ? stroke : "")};
    }
  }
`;

export default PerformanceIndications;
