import {
  PrCompleted,
  Deadline,
  NotCompleted,
  DeadlineT,
  PtCompleted,
  NotCompletedT,
} from "../../../../Styled/AllImages";

export const ProjectsPerformanceArray = [
  {
    icon: PrCompleted,
    count: "80%",
    bg: "var(--lightGreen1)",
    title: "% of completed on time",
  },
  {
    icon: Deadline,
    count: "12%",
    bg: "var(--lightYellow)",
    title: "% of completed after deadline",
  },
  {
    icon: NotCompleted,
    count: "8%",
    bg: "var(--lightRed)",
    title: "% of not completed",
  },
];

export const IndPerformanceArray = [
  {
    icon: PtCompleted,
    count: "80%",
    bg: "var(--lightGreen1)",
    title: "% of completed on time",
  },
  {
    icon: DeadlineT,
    count: "12%",
    bg: "var(--lightYellow)",
    title: "% of completed after deadline",
  },
  {
    icon: NotCompletedT,
    count: "8%",
    bg: "var(--lightRed)",
    title: "% of not completed",
  },
];
