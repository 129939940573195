import { Search } from "react-bootstrap-icons";
import { InputWithIcon } from "../../Styled/FormElements";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
} from "../../Styled/Elements";
import { InputStyledWrap } from "../../Styled/styled";
import { TasksList } from "./TasksList";

export const LibraryProjectView = () => {
  return (
    <>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            Projects Tasks
          </HeadingStyled>
          <InputStyledWrap>
            <InputWithIcon
              type="text"
              placeholder="Search Task by name"
              icon={Search}
            />
          </InputStyledWrap>
          <InputStyledWrap>
            <InputWithIcon
              type="text"
              placeholder="Search task by executor"
              icon={Search}
            />
          </InputStyledWrap>
          <Box className="boxRBt">
            <ButtonStyledMedium
            //   onClick={() => navigate("/project/add-project")}
            >
              Search
            </ButtonStyledMedium>
          </Box>
        </Box>
      </SecondaryBarStyled>
      <TasksList />
    </>
  );
};
