import styled from "styled-components";
// import { profileList } from '../../../helpers/admin/AdminConstant'
import { Col, Row } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  SecondaryBarStyled,
  SpanStyled,
} from "../../Styled/Elements";
import {
  CallCircle,
  MicCircle,
  VideoCircle,
  VolumnCircle,
} from "../../Styled/AllImages";
// import { InputFloating } from '../../Styled/FormElements';
export const StartMeeting = () => {
  const imgUrl =
    "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&amp;w=1000&amp;q=80";
  const JoinedUser = ({ bg, name }) => {
    return (
      <JoinedUserContainer
        className="d-flex align-items-end"
        bg={
          "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&amp;w=1000&amp;q=80"
        }
      >
        <Box className="w-100 d-flex align-items-center justify-content-between volName">
          <SpanStyled className="position-relative" color="var(--white)">
            {name}
          </SpanStyled>
          <VolumnCircle className="position-relative" />
        </Box>
      </JoinedUserContainer>
    );
  };
  return (
    <>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            Android Development
          </HeadingStyled>
        </Box>
      </SecondaryBarStyled>
      <Box className="flex-1 main-content">
        <Row>
          {[...Array(4)].map((item, key) => (
            <Col md={6} className="mb-4" key={key}>
              <JoinedUser name="Wade Warren" bg={imgUrl} />
            </Col>
          ))}
        </Row>
        <Box>
          <ul className="list-unstyled mb-4 pb-2 pt-2 justify-content-center d-flex align-items-center">
            <li>
              <button className="p-0 bg-transparent border-0">
                <CallCircle height="55px" />
              </button>
            </li>
            <li>
              <button className="p-0 bg-transparent border-0">
                <MicCircle height="55px" />
              </button>
            </li>
            <li>
              <button className="p-0 bg-transparent border-0">
                <VideoCircle height="55px" />
              </button>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};

const JoinedUserContainer = styled.div`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-poition: center;
  background-size: cover;
  height: 220px;
  border-radius: 16px;
  background-color: #d9d9d9;
  position: relative;
  overflow: hidden;
  span {
    z-index: 1;
  }
  &:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .volName {
    padding: 20px 35px;
  }
`;
