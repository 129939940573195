import { Calendar2, Clock, Grid, List } from "react-bootstrap-icons";
import ViewProjectList from "./ViewProjectList";
import { Board } from "../Tasks/Board";
import { MyCalendar } from "../Tasks/Calendar";
import { MyTimeline } from "../Tasks/Timeline";


export const ProjectListArray = [
    {
        title: `List View`,
        component: ViewProjectList,
        icon: <List width="18px" height="18px" />,
    },
    {
        title: `Board`,
        component: Board,
        icon: <Grid width="18px" height="18px" />,
    },
    {
        title: `Calendar`,
        component: MyCalendar,
        icon: <Calendar2 width="18px" height="18px" />,
    },
    {
        title: `Timeline`,
        component: MyTimeline,
        icon: <Clock width="18px" height="18px" />,
    },
];
