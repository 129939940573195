import styled from "styled-components";
import { Box, SpanStyled, TextStyled } from "../../../../Styled/Elements";
import { InputFloating } from "../../../../Styled/FormElements";
import { DateRangeWrapper } from './styled'


const DateRange = (props) => {
	return (
		<DateRangeWrapper className="DateRangeWrapper d-flex align-items-center">
			<Box className="DateRangeIconTitle">
				<Box className="DateRangeIcon">
					<SvgContainerStyled>
						<props.icon width="18px" height="18px" />
					</SvgContainerStyled>
				</Box>
				<TextStyled
					className="DateRangeTitle"
					dangerouslySetInnerHTML={{ __html: props.title }}
				></TextStyled>
			</Box>
			<Box className="DateRangeInputContainer">
				<input className="DateRangeInput" type="text" placeholder="Wednesday, 25 May" />
			</Box>
		</DateRangeWrapper>
	);
};

// const DateRangeWrapper = styled.div``;
const SvgContainerStyled = styled.div``;
const PerformanceDataContainer = styled.div``;
export default DateRange;
