import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  MessageIcon,
  PhoneIcon,
  FinanceIcon,
  MoreIcon,
} from "../../../Styled/AllImages";
import {
  Box,
  LazyImage,
  HeadingStyled,
  SpanStyled,
  ButtonStyled,
} from "../../../Styled/Elements";

export const UserProfileAside = () => {
  const navigate = useNavigate();
  return (
    <UserCardInfo>
      <Box className="text-center">
        <LazyImage
          width="100px"
          height="100px"
          className="rounded-circle"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
          alt="username"
        />
        <HeadingStyled
          color="var(--drColor)"
          size="16px"
          weight={500}
          className="mt-3 mb-0"
        >
          James Walsh
        </HeadingStyled>
        <SpanStyled color="var(--grey-color)" size="12px">
          MAAD01
        </SpanStyled>
        <Box className="seprator"></Box>
        <ul className="list-unstlyled mb-0 p-0">
          <li className="d-flex align-items-center mb-3 pb-1">
            <MessageIcon width="18px" className="me-2" />
            <SpanStyled size="12px" style={{ wordBreak: "break-all" }}>
              jameswalsh@maad.com
            </SpanStyled>
          </li>
          <li className="d-flex align-items-center mb-3 pb-1">
            <PhoneIcon width="18px" className="me-2" />
            <SpanStyled size="12px" style={{ wordBreak: "break-all" }}>
              +966987654321
            </SpanStyled>
          </li>
          <li className="d-flex align-items-center mb-3 pb-1">
            <FinanceIcon width="18px" className="me-2" />
            <SpanStyled size="12px" style={{ wordBreak: "break-all" }}>
              Finance
            </SpanStyled>
          </li>
          <li className="d-flex align-items-center">
            <MoreIcon width="18px" className="me-2" />
            <SpanStyled size="12px" style={{ wordBreak: "break-all" }}>
              None
            </SpanStyled>
          </li>
        </ul>
        <ButtonStyled
          onClick={() => navigate("/edit/users")}
          className="my-3"
          size="14px"
          padding="6px 20px"
        >
          Edit User
        </ButtonStyled>
      </Box>
    </UserCardInfo>
  );
};

const UserCardInfo = styled.aside`
  width: 280px;
  border-right: 1px solid var(--greyBg);
  padding: 20px;
  .seprator {
    border-bottom: 1px solid var(--greyBg);
    height: 1px;
    margin: 20px -20px;
  }
`;
