import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AvatarGroup, Box, FormEleWrapper, SpanStyled } from "../../Styled/Elements";
import styled from "styled-components";
import { useCallback } from "react";
import { RadioButton } from "../../Styled/FormElements";
import { useDispatch } from "react-redux";
import { showMembersModal } from "../../Store/slices/HelperSlice";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const localizer = momentLocalizer(moment);

// Radio buttons component
const RadioButtons = () => (
  <FormEleWrapper>
    <Box className="formEleContainer align-items-baseline">
      <RadioButton
        color="#b1b1b1"
        name="intExt"
        label="To Do"
        width="16px"
        height="16px"
      // value={item.value}
      // checked={selectedInternalExternal === item.value}
      // onChange={handleInternalExternalRadioButtonChange}
      />
      <RadioButton
        color="#018de4"
        name="intExt"
        label="In Progress"
        width="16px"
        height="16px"
      // value={item.value}
      // checked={selectedInternalExternal === item.value}
      // onChange={handleInternalExternalRadioButtonChange}
      />
      <RadioButton
        color="#e8374f"
        name="intExt"
        label="Overdue"
        width="16px"
        height="16px"
      // value={item.value}
      // checked={selectedInternalExternal === item.value}
      // onChange={handleInternalExternalRadioButtonChange}
      />
      <RadioButton
        color="#74d66e"
        name="intExt"
        label="Completed"
        width="16px"
        height="16px"
      // value={item.value}
      // checked={selectedInternalExternal === item.value}
      // onChange={handleInternalExternalRadioButtonChange}
      />
    </Box>
  </FormEleWrapper>
);

// Custom Toolbar for calender
const CustomToolbar = ({ date, onNavigate, onView }) => {
  const goToToday = () => {
    onNavigate('TODAY');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToPrevious = () => {
    onNavigate('PREV');
  };

  const handleViewChange = (view) => {
    onView(view);
  };

  return (
    <ToolbarContainer>
      <ToolbarButton onClick={goToToday} className="today-btn">
        <SpanStyled color="var(--grey)">
          Today
        </SpanStyled>
      </ToolbarButton>
      <ToolbarButton onClick={goToPrevious}>
        <SpanStyled color="var(--grey)">
          <ChevronLeft />
        </SpanStyled>
      </ToolbarButton>
      <ToolbarButton onClick={goToNext}>
        <SpanStyled color="var(--grey)">
          <ChevronRight />
        </SpanStyled>
      </ToolbarButton>
      <ToolbarLabel>
        <SpanStyled color="var(--grey)">
          {`${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`}
        </SpanStyled>
      </ToolbarLabel>
      <RadioButtons />
    </ToolbarContainer>
  );
};

export const MyCalendar = (props) => {
  const dispatch = useDispatch()
  const myEventsList = [
    {
      id: "00124",
      title: "UX Research",
      start: "2024-1-11",
      end: "2024-1-14",
      color: "#018de4",
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: "00125",
      title: "Android Development",
      start: "2024-1-14",
      end: "2024-1-18",
      color: "#0fbc00",
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: "00126",
      title: "Requirement Gathering",
      start: "2024-1-17",
      end: "2024-1-19",
      color: "#e8374f",
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: "00127",
      title: "UI Design",
      start: "2024-1-25",
      end: "2024-1-25",
      color: "#b1b1b1",
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
  ];

  const eventPropGetter = useCallback(
    ({ color: backgroundColor }) => {
      return {
        ...(backgroundColor && { style: { backgroundColor } }),
      };
    },
    [myEventsList]
  );

  const CustomEvent = ({ event }) => {
    const width = `${((event.end - event.start) / (60 * 60 * 1000)) * 100}%`; // Calculate width based on duration
    return (
      <Box
        style={{
          backgroundColor: event.color,
          borderRadius: "4px",
          padding: "1px 2px",
          width,
          overflow: "hidden",
        }}
        className="d-flex align-items-center"
      >
        <AvatarGroup data={event.executer} dialog={() => dispatch(showMembersModal(true))} width='26px' height='24px' />
        <SpanStyled className="mx-1">
          {event.title} (ID:{event.id})
        </SpanStyled>
      </Box>
    );
  };

  return (
    <>
      <CalendarContainer className="p-4">
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 650 }}
          onSelecting={(values) => console.log("value is", values)}
          popup={true}
          eventPropGetter={eventPropGetter}
          defaultView={"month"}
          components={{
            toolbar: (props) => <CustomToolbar {...props} />,
            event: CustomEvent,
          }}
          drilldownView={false}
        />
      </CalendarContainer>
    </>
  );
};

const CalendarContainer = styled.div`
  .rbc-header {
    border-bottom: none;
    text-align: left;
    color: var(--grey);
  }
  .rbc-date-cell {
    text-align: right;
    color: var(--grey);
  }
`;
const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ToolbarButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
  margin-right: 25px;
`;

const ToolbarLabel = styled.span`
  width: 120px;
  font-size: 16px;
`;
