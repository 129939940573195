import { Check2, Dash, X } from "react-bootstrap-icons";
import { UserProfileAside } from "./UserProfileAside";
import { PermissionsTableStyled, arrayList } from "../userAdded/UserPermission";
import {
  Box,
  CardStyled,
  SecondaryBarStyled,
  HeadingStyled,
} from "../../../Styled/Elements";

export const UserDetail = () => {
  return (
    <>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            User Detail
          </HeadingStyled>
        </Box>
      </SecondaryBarStyled>

      <Box className=" min-h-100 main-content">
        <CardStyled className="d-flex flex-1 w-100">
          <UserProfileAside />
          <Box className="px-3 w-100">
            <PermissionsTableStyled className="w-100">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Create</th>
                  <th>Edit</th>
                  <th>View</th>
                  <th>Member</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {arrayList.map((item, key) => (
                  <tr key={key}>
                    <td>{item}</td>
                    <td>
                      <Check2 size={20} color="var(--themeColor)" />
                    </td>
                    <td>
                      <X size={24} color="var(--red)" />
                    </td>
                    <td>
                      <Dash size={24} color="var(--grey)" />
                    </td>
                    <td>
                      <Check2 size={20} color="var(--themeColor)" />
                    </td>
                    <td>
                      <Check2 size={20} color="var(--themeColor)" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </PermissionsTableStyled>
          </Box>
        </CardStyled>
      </Box>
    </>
  );
};
