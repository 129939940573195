import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Eye } from "react-bootstrap-icons";

import { ProjectsTile } from "../../../Components/Dashboard/Pages/ProjectsTile";
import { ProgressBarTile } from "../../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import {
	Box,
	ButtonStyledMedium,
	HeadingStyledDB,
	SecondaryBarStyled,
	SecondaryHeading,
	SpanStyled,
} from "../../../Styled/Elements";
import {
	EmployeeTileArray,
	ReportsProjectsTeamTileArray,
	ReportsProjectsTeamProgBarTileArray,
	ReportsTasksTeamTileArray,
	ReportsTasksTeamProgBarTileArray,
} from "../../../Components/Dashboard/Pages/TilesArray";

export const ReportsTeams = () => {
	const navigate = useNavigate();
	return (
		<Box>
			<SecondaryBarStyled>
				<SecondaryHeading label="Teams" />
			</SecondaryBarStyled>
			<Box className="main-content">
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Employees
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-4">
							{EmployeeTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4 col-tile-graph"
								>
									<ProjectsTile {...item} />
								</Col>
							))}

							{/* {ProjectsProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4"
								>
									<ProgressBarTile {...item} />
								</Col>
							))} */}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<Box className="d-flex justify-content-between">
							<HeadingStyledDB family="var(--semiBold)" size="">
								Project Teams
							</HeadingStyledDB>
							<Box className="boxRBt">
								<ButtonStyledMedium
									onClick={() => navigate("/reports-teams/project-teams")}
								>
									<Eye /> View Project Teams
								</ButtonStyledMedium>
							</Box>
						</Box>
						<Row className="px-row-10 mb-4 row-tile row-tile-33">
							{ReportsProjectsTeamTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}

							{ReportsProjectsTeamProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4 position-relative"
								>
									<ProgressBarTile {...item} />
									<SpanStyled className="extDataCount">27%</SpanStyled>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mb-4">
					<Col xs={12}>
						<Box className="d-flex justify-content-between">
							<HeadingStyledDB family="var(--semiBold)" size="">
								Team Tasks
							</HeadingStyledDB>
							<Box className="boxRBt">
								<ButtonStyledMedium
									onClick={() => navigate("/reports-teams/task-teams")}
								>
									<Eye /> View Task Teams
								</ButtonStyledMedium>
							</Box>
						</Box>
						<Row className="px-row-10 mb-4 row-tile row-tile-33">
							{ReportsTasksTeamTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-4"
								>
									<ProjectsTile {...item} />
								</Col>
							))}

							{ReportsTasksTeamProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-4 position-relative"
								>
									<ProgressBarTile {...item} />
									<SpanStyled className="extDataCount">23%</SpanStyled>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Box>
		</Box>
	);
};
