import { useState } from "react";
import styled from "styled-components";
import { Dropdown, avatarImg } from "../../Styled/AllImages";
import { CustomCheckbox } from "../../Styled/FormElements";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
  Box,
  FormEleWrapper,
  LabelStyled,
  SpanStyled,
  TextWithIcon,
} from "../../Styled/Elements";

const InventoryColumns = [
  {
    name: "taskName",
    label: "Task Name",
  },
  {
    name: "type",
    label: "Type",
    render: (text) => (
      <CustomCheckbox
        name={text}
        // label={item?.name}
        value={text}
      />
    ),
  },
  {
    name: "status",
    label: "Status",
  },
  {
    name: "executor",
    label: "Executor",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "Start",
    label: "Start",
  },
  {
    name: "Duration",
    label: "Duration",
  },
  {
    name: "End",
    label: "End",
    render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
  },
];

export const TasksList = () => {
  const [showModal, setModal] = useState(false);
  const rows = [
    {
      taskName: "Android Development",
      type: "type",
      status: "Started",
      executor: "Executor",
      Start: "Today, May 24",
      Duration: "6 Days",
      End: "May 30",
    },
  ];

  const onDelete = () => {
    setModal(true);
  };
  return (
    <Box className="main-content">
      <FormEleWrapper>
        <Box className="formEleContainer align-items-baseline">
          <Box className="d-flex w-25 align-items-baseline">
            <Box className="pb-2">
              <Dropdown width="18px" height="18px" iconColor="var(--grey)" />
            </Box>
            <Pill color="#e8374f" textColor="white">
              <SpanStyled weight={500} size="14px">
                Overdue
              </SpanStyled>
            </Pill>
            <LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
          </Box>
        </Box>
      </FormEleWrapper>
      <Box>
        <ListTable
          onDelete={onDelete}
          options={["view", "edit", "delete"]}
          idIndex={1}
          value="inventory-Edit"
          rows={rows}
          columns={InventoryColumns}
        />
      </Box>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-baseline">
          <Box className="d-flex w-25 align-items-baseline">
            <Box className="pb-2">
              <Dropdown width="18px" height="18px" iconColor="var(--red)" />
            </Box>
            <Pill color="#20c012" textColor="white">
              <SpanStyled weight={500} size="14px">
                Completed
              </SpanStyled>
            </Pill>
            <LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
          </Box>
        </Box>
      </FormEleWrapper>
      <Box>
        <ListTable
          onDelete={onDelete}
          options={["view", "edit", "delete"]}
          idIndex={1}
          value="inventory-Edit"
          rows={rows}
          columns={InventoryColumns}
        />
      </Box>
    </Box>
  );
};

const Pill = styled.div`
  border: none;
  background-color: ${(props) => (props.color ? props.color : "#e4e5e7")};
  border-radius: 8px;
  height: 43px;
  padding: 9px 18px 0px 18px;
  margin: 0px 10px 0px 10px;

  span {
    padding-left: 9px;
    color: ${(props) => (props.textColor ? props.textColor : "#92929d")};
  }
`;
