import styled from "styled-components";

export const ButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid var(--greyText);
    border-radius: 8px;
    padding: 6px 16px;
    gap: 8px;
    color: var(--greyText);
    transition: all 0.5s ease;
    svg {
      [stroke] {
        stroke: var(--greyText);
      }
      [fill] {
        fill: var(--greyText);
      }
    }
    &:hover {
      background-color: var(--themeColor);
      border: 1px solid var(--themeColor);
      color: var(--white);
      svg {
        [stroke] {
          stroke: var(--white);
        }
        [fill] {
          fill: var(--white);
        }
      }
    }
  }
`;

export const CustomCheckboxStyled = styled.label`
  display: flex;
  position: relative;
  padding-left: 35px;
  min-height: 23px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkLabel{
    cursor: pointer;
  line-height: 22px;
  display: inline-block;
  color: var(--grey);
  font-size: 14px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 6px;
    border: 1px solid var(--greyText);
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 6px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  &:hover {
    input ~ .checkmark {
      background-color: var(--lightBlue);
    }
  }
  input:checked ~ .checkmark {
    background-color: var(--themeColor);
    border-color: transparent;
  }
`;

export const LayoutBox = styled.div`
  position: relative;
  padding: 50px;
  background-color: var(--darkBlue);
  height: 100%;
  width: 100%;
  color: var(--white);
  display: flex;
  align-items: center;

  .layout_content {
    width: 70%;
    line-height: 35px;
  }
  .layout_heading {
    display: block;
    font-family: var(--extraBold);
    font-size: 40px;
    font-weight: bold;
  }
  .vector > span > img {
    width: 60%;
  }
  .vector_right {
    position: absolute;
    bottom: 0;
    left: -62px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .vector_left {
    position: absolute;
    top: 0;
    right: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .btnWapper {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
  }
`;

export const InputStyled = styled.input`
  border: none;
  background-color: var(--greyBg);
  border-radius: 8px;
  height: 43px;
  &:not(.no-icon) {
    padding-left: 45px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  &,
  &::placeholder {
    font-size: 14px;
    color: var(--greyText);
  }
`;
export const InputStyledWrap = styled.div`
  svg {
    left: 13px;
    top: 12px;
    height: 20px;
  }
`;

export const RadioButtonStyled = styled.div`
  // [type="radio"]:checked,
  // [type="radio"]:not(:checked) {
  //     position: absolute;
  //     left: -9999px;
  // }
  [type="radio"] {
    display: none;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: ${(props) => (props.color ? props.color : " var(--greyText)")};
    font-size: 14px;
    // padding-top: 2px;
    transition: 0.2s ease-in-out all;
  }
  [type="radio"]:checked + label {
    color: ${(props) => (props.color ? props.color : "var(--themeColor)")};
    /* font-weight: 500; */
    &.border-radio {
      border-color: ${(props) =>
    props.color ? props.color : "var(--themeColor)"};
    }
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 5px;
    top: 2px;
    width: ${(props) => props.width ? props.width : '20px'};
    height: ${(props) => props.height ? props.height : '20px'};
    border: 1px solid ${(props) => props.color ? props.color : '#e1e1e1'};
    border-radius: 50%;
    background: transparent;
  }
  [type="radio"]:checked + label:before {
    background: ${(props) =>
    props.background ? props.background : props.color ? props.color : "var(--themeColor)"};
  }
  [type="radio"]:checked + label:before {
    border: none;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: var(--white);
    position: absolute;
    top: 6px;
    left: 11px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    background: ${(props) => (props.color ? props.color : "var(--themeColor)")};
  }
`;

/*
const UserUpload = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .userPlaceholder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: var(--greyBg);
        border-radius: 50%;
        border: 1px dashed var(--greyText);
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    input[type="file"]{
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

`
*/

export const FileUploadContainer = styled.div`
  .fileUpload {
    width: 100%;
    &.drag-over {
      background-color: var(--greyBg);
    }
  }

  .file-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 5px 0px 5px 0px;
      padding: 7px 10px 7px 10px;
      background-color: var(--greyBg);
      color: var(--grey);
      font-size: 13px;
      border-radius: 6px;
    }
    svg {
      min-width: 15px;
      cursor: pointer;
    }
  }
`;

export const UserUpload = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 5px 15px 5px 15px;
  background-color: var(--greyBg);
  border-radius: 8px;
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--greyBg);
    border-radius: 50%;
    // border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .abtUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      // width: 100%;
    }
  }
`;
