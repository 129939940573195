import { useEffect, useState } from "react";
import { Box, FormEleWrapper, LabelStyled } from "../../../Styled/Elements";
import {
  InputFloating,
  RadioButton,
  FileUploader,
} from "../../../Styled/FormElements";
import styled from "styled-components";
import { UserDropDown } from "../../../Components/Dashboard/Pages/Projects/UserDropDown";
import AddMember from "../../../Components/Dashboard/Pages/Projects/addMember/MainData";

export const Step1 = (props) => {
  const InternalExternal = [
    {
      label: "Internal",
      value: "internal",
    },
    {
      label: "External",
      value: "external",
    },
  ];
  const ContractOrder = [
    {
      label: "Contract",
      value: "contract",
    },
    {
      label: "Purchase order",
      value: "purchase",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const PaymentMethod = [
    {
      label: "Percentage Based",
      value: "percentage",
    },
    {
      label: "Monthly Based",
      value: "monthly",
    },
    {
      label: "Other",
      value: "others",
    },
  ];

  const [chkPaymentMethod, setchkPaymentMethod] = useState(null);
  const [selectedInternalExternal, setSelectedInternalExternal] = useState(
    null
    //InternalExternal[0].value
  );

  const [showContractOrderRadioButtons, setShowContractOrderRadioButtons] =
    useState(false);

  const [selectedContractOrder, setSelectedContractOrder] = useState(null);

  const [showDivs, setShowDivs] = useState(false);

  useEffect(() => {
    if (selectedInternalExternal === "external") {
      setShowContractOrderRadioButtons(true);
      setShowDivs(true);
    } else {
      setShowContractOrderRadioButtons(false);
      setShowDivs(false);
      setSelectedContractOrder(false);
    }
  }, [selectedInternalExternal]);

  const handleInternalExternalRadioButtonChange = (e) => {
    setSelectedInternalExternal(e.target.value);
    if (e.target.value === "internal") {
      setShowDivs(false);
    }
  };

  const handleContractOrderRadioButtonChange = (e) => {
    setSelectedContractOrder(e.target.value);
  };

  const handlePaymentMethodBtnCage = (e) => {
    setchkPaymentMethod(e.target.value);
  };

  if (props.currentStep !== 1) {
    return null;
  }

  // const toggleDropdown = () => {
  // 	// You can add any logic here to determine when to toggle the dropdown
  // 	// In this example, it always remains open
  // 	setShow(true);
  // };

  return (
    <>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Project Name</LabelStyled>
          <InputFloating type="text" placeholder="Enter Project Name" />
        </Box>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Purpose</LabelStyled>
          <InputFloating type="text" placeholder="Enter Project Purpose" />
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Implementor type</LabelStyled>
          <Box className="w-100">
            <Box className="d-flex w-100 radioListWrapper">
              {InternalExternal.map((item, i) => (
                <Box key={i} className="radioList">
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    label={item.label}
                    value={item.value}
                    checked={selectedInternalExternal === item.value}
                    onChange={handleInternalExternalRadioButtonChange}
                  />
                </Box>
              ))}
            </Box>

            {showContractOrderRadioButtons && (
              <Box className="d-flex w-100 radioListWrapper mt-4 mb-3">
                {ContractOrder.map((item, i) => (
                  <Box key={i} className="radioList">
                    <RadioButton
                      name="contractOrder"
                      label={item.label}
                      value={item.value}
                      onChange={handleContractOrderRadioButtonChange}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {selectedInternalExternal === "external" &&
              selectedContractOrder === "other" && (
                <InputFloating type="text" placeholder="Other" />
              )}

            {selectedInternalExternal === "external" &&
              (selectedContractOrder === "contract" ||
                selectedContractOrder === "purchase" ||
                selectedContractOrder === "other") && (
                <InnerOpContainer className="second">
                  <Box className="formEleContainer inp-white">
                    <LabelStyled className="labelForm">
                      Implementor Name
                    </LabelStyled>
                    <InputFloating type="text" placeholder="Person / Company" />
                  </Box>
                  <Box className="formEleContainer inp-white">
                    <LabelStyled className="labelForm">
                      Contact Person
                    </LabelStyled>
                    <InputFloating
                      type="text"
                      placeholder="Contact Person Name"
                    />
                  </Box>
                  <Box className="formEleContainer inp-white">
                    <LabelStyled className="labelForm">Email</LabelStyled>
                    <InputFloating type="text" placeholder="Email" />
                  </Box>
                  <Box className="formEleContainer inp-white">
                    <LabelStyled className="labelForm">Cell Phone</LabelStyled>
                    <InputFloating type="text" placeholder="Cell Phone" />
                  </Box>
                </InnerOpContainer>
              )}
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Owner</LabelStyled>
          <UserDropDown userLabel="Select Owner" />

          {/* <ImageUploader label="Select Owner" /> */}
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Users</LabelStyled>
          {/* <UserDropDown userLabel="Add User" /> */}
          <AddMember userTitle="Add Userdd" />
          {/* <ImageUploader label="Add User" /> */}
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <LabelStyled className="labelForm">Project Manager</LabelStyled>
          <UserDropDown userLabel="Add Project Manager" />
        </Box>
      </FormEleWrapper>

      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">
            Project Approval Letter
          </LabelStyled>
          <Box className="w-100">
            <FileUploader />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">
            Contract Document
          </LabelStyled>
          <Box className="w-100">
            <FileUploader />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">Attachment</LabelStyled>
          <Box className="w-100">
            <FileUploader />
          </Box>
        </Box>
      </FormEleWrapper>

      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Payment Method</LabelStyled>
          <Box className="w-100">
            <Box className="d-flex w-100 radioListWrapper mb-2">
              {PaymentMethod.map((item, i) => (
                <Box key={i} className="radioList">
                  <RadioButton
                    color="var(--grey)"
                    name="payMet"
                    label={item.label}
                    value={item.value}
                    checked={chkPaymentMethod === item.value}
                    onChange={handlePaymentMethodBtnCage}
                  />
                </Box>
              ))}
            </Box>

            {/* {chkPaymentMethod === "percentage" && (
              <InputFloating type="text" placeholder="Percentage Based" />
            )}
            {chkPaymentMethod === "monthly" && (
              <InputFloating type="text" placeholder="Monthly Based" />
            )} */}
            {chkPaymentMethod === "others" && (
              <InputFloating type="text" placeholder="Other Payment Method" />
            )}
          </Box>
        </Box>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">Description</LabelStyled>
          <InputFloating textarea placeholder="Add Description" />
        </Box>
      </FormEleWrapper>
    </>
  );
};

const InnerOpContainer = styled.div`
  padding: 20px;
  background-color: var(--greyBg);
  border-radius: 10px;
  margin-top: 16px;
`;
