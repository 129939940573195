import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { ReasonModal } from "../ReasonModal";
import { avatarImg, calendar, Attach, Voice, Print } from "../../../Styled/AllImages";
import Calendar from "react-calendar";
import styled from "styled-components";
import {
	Box,
	ButtonStyled,
	Container,
	FormEleWrapper,
	HeadingStyled,
	LabelStyled,
	SecondaryBarStyled,
	DataContainerStyled,
	SpanStyled,
	MainContainer,
	TextWithIcon,
	ButtonStyledDiv,
	CalendarContainer,
} from "../../../Styled/Elements";
import {
	BackButton,
	CustomCheckbox,
	Pill,
	SelectList,
	FileUploader,
	InputWithIcon,
	InputFloating,
	RadioButton,
} from "../../../Styled/FormElements";
import { InputStyledWrap } from "../../../Styled/styled";

import { X } from "react-bootstrap-icons";
import { TaskActivities } from "./TaskActivities";
import { ListTaskViewWrap } from './styledViewTask';
import AddTasks from "../../Dashboard/Pages/Projects/addTasks/MainData";
import AddMemberRole from "../../Dashboard/Pages/Projects/addMemberRole/MainData";
import { CustomModal } from "../CustomModal";
import { StaticDatePickerContainer } from "../../Dashboard/Pages/StaticRangePicker";

// import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
// import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';

export const InventoryColumns = [
	{
		name: "taskName",
		label: "Task Name",
	},
	{
		name: "type",
		label: "Type",
		render: (text) => (
			<CustomCheckbox
				name={text}
				// label={item?.name}
				value={text}
			/>
		),
	},
	{
		name: "status",
		label: "Status",
	},
	{
		name: "ProjectManager",
		label: "Project Manager",
		render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
	},
	{
		name: "Start",
		label: "Start",
	},
	{
		name: "Duration",
		label: "Duration",
	},
	{
		name: "End",
		label: "End",
		render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
	},
];

export const ViewTaskModal = ({
	showAssignTskToTeamModal,
	setShowAssignTskToTeamModal,
	showSubmitTask, showPrintTask,
}) => {
	// const [selectedOption, setSelectedOption] = useState(null);
	// const handleSelectChange = (selectedValue) => {
	// 	setSelectedOption(selectedValue);
	// };
	const [showDateModal, setShowDateModal] = useState(false);
	const [date, onDataChange] = useState(new Date());

	const [selectedOption, setSelectedOption] = useState(null);

	const handleSelectChange = (selectedValue) => {
		setSelectedOption(selectedValue);
	};
	const [showModal, setModal] = useState(false);

	const navigate = useNavigate();
	const rows = [
		{
			taskName: "Android Development",
			type: "type",
			status: "Started",
			executor: "Executor",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
		},
	];
	const onDelete = () => {
		setModal(true);
	};

	// const firstChildRef = useRef(null);
	// const secondChildRef = useRef(null);

	// useEffect(() => {
	// 	const firstChildHeight = firstChildRef.current.clientHeight;
	// 	secondChildRef.current.style.height = `${firstChildHeight}px`;
	// }, []);

	const firstChildRef = useRef(null);
	const secondChildRef = useRef(null);
	const [firstChildHeight, setFirstChildHeight] = useState(0);
	useEffect(() => {
		const handleResize = () => {
			if (firstChildRef.current) {
				setFirstChildHeight(firstChildRef.current.scrollHeight);
			}
		};

		// Initial height calculation
		handleResize();

		// Listen for changes that may affect the height of the first child
		window.addEventListener('resize', handleResize);

		return () => {
			// Cleanup the event listener
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (firstChildRef.current && secondChildRef.current) {
			secondChildRef.current.style.height = `${firstChildHeight}px`;
		}
	}, [firstChildHeight]);

	/*
		useEffect(() => {
			const handleResize = () => {
				setFirstChildHeight(firstChildRef.current.scrollHeight);
			};
	
			handleResize();
	
			window.addEventListener('resize', handleResize);
	
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, []);
	
		useEffect(() => {
			secondChildRef.current.style.height = `${firstChildHeight}px`;
		}, [firstChildHeight]);
	*/
	const [chkTaskType, setchkTaskType] = useState(null);
	const [chkImplementor, setchkImplementor] = useState(null);
	const TaskType = [
		{
			label: "Standalone",
			value: "standalone",
		},
		{
			label: "Relational",
			value: "relational",
		},
	];
	const Implementor = [
		{
			label: "Task Manager",
			value: "tTaskmanager",
		},
		{
			label: "Team",
			value: "team",
		},
	];
	const handleTaskTypeBtnCage = (e) => {
		setchkTaskType(e.target.value);
	};
	const handleImplementorBtnCage = (e) => {
		setchkImplementor(e.target.value);
	};
	return (
		<>
			<CustomModal
				showModal={showAssignTskToTeamModal}
				onHide={() => setShowAssignTskToTeamModal(false)}
				heading="Android Development"
				size="xxl"
				closeButton={true}
				scrollable={false}
			// buttons={[
			// 	{ text: "Save", variant: "primary" },
			// 	{ text: "Clear All", variant: "danger" },
			// ]}
			>
				<Box>
					{/* <Box className="main-content p-0"> */}
					<DataContainerStyled className="allDataContainer p-0">
						{/* <Row>
							<Col xs={12} className="listTaskViewContainer"> */}
						<ListTaskViewWrap>
							{/* <Box className="topBarInner">
											<HeadingStyled
												className="topBarInnerHeading"
											>
												<BackButton

													label=" Android Development"
												/>
											</HeadingStyled>
										</Box> */}
							<Box className="listTaskActWrapper">
								<Container className="listTaskView" borderRight="1px solid var(--outlineC)" ref={firstChildRef}>

									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">Task Name</LabelStyled>
											<LabelStyled className="labelForm pt-1">
												Android Development
											</LabelStyled>
										</Box>
									</FormEleWrapper>


									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">Type</LabelStyled>

											{/* <Box className="listRowWrapper">
												<Box className="d-flex radioListWrapper">
													<SelectList options={["Relational", "Standalone"]} placeholder="Select by Task" />
												</Box>

												<AddTasks />
											</Box> */}
											<Box className="w-100">
												<Box className="d-flex w-100 radioListWrapper mb-2">
													{TaskType.map((item, i) => (
														<Box key={i} className="radioList">
															<RadioButton
																color="var(--grey)"
																name="taskType"
																label={item.label}
																value={item.value}
																checked={chkTaskType === item.value}
																onChange={handleTaskTypeBtnCage}
															/>
														</Box>
													))}
												</Box>
												{chkTaskType === "relational" && (

													<AddTasks />


												)}
											</Box>
										</Box>
									</FormEleWrapper>


									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">Duration</LabelStyled>
											<Pill text="3 Days, May  9 - 12" icon={calendar} />
										</Box>
									</FormEleWrapper>


									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">
												Implementer
											</LabelStyled>
											{/* <Box className="listRowWrapper">
												<Box className="d-flex radioListWrapper">
													<SelectList options={["Team", "Alone"]} />
												</Box>

												<AddMemberRole />
											</Box> */}
											<Box className="w-100">
												<Box className="d-flex w-100 radioListWrapper mb-2">
													{Implementor.map((item, i) => (
														<Box key={i} className="radioList">
															<RadioButton
																color="var(--grey)"
																name="implementor"
																label={item.label}
																value={item.value}
																checked={chkImplementor === item.value}
																onChange={handleImplementorBtnCage}
															/>
														</Box>
													))}
												</Box>
												{chkImplementor === "team" && (
													<AddMemberRole />
												)}
											</Box>
										</Box>
									</FormEleWrapper>
									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">
												Description
											</LabelStyled>
											<Box className="w-100">
												<SpanStyled color="var(--grey)" size="15px" lheight="18px">
													Introducing our groundbreaking Android mobile app, your one-stop solution for unrivaled productivity and endless entertainment. Experience the pinnacle of convenience with our feature-rich app, boasting a user-friendly interface and an array of powerful tools.
												</SpanStyled>
											</Box>
										</Box>
									</FormEleWrapper>

									<FormEleWrapper>
										<Box className="formEleContainer align-items-start">
											<LabelStyled className="labelForm pt-1">Attachment</LabelStyled>
											<Box className="w-100">
												<FileUploader />
											</Box>
										</Box>
									</FormEleWrapper>
									<Box className="formBtns w-100">
										{showSubmitTask && (
											<>
												<ButtonStyled type="button" bg="var(--themeColor)">
													Submit Task
												</ButtonStyled>
												<ButtonStyled onClick={() => setShowDateModal(true)}
													border="1px solid var(--themeColor)"
													color="var(--themeColor)"
													bg="var(--white)"
												>
													Extend Request
												</ButtonStyled>
											</>
										)}
										{showPrintTask && (
											<ButtonStyled type="button" bg="var(--themeColor)">
												<Print /> Print
											</ButtonStyled>

										)}
									</Box>

								</Container>
								<Container className="listTaskActivities" ref={secondChildRef}>
									<TaskActivities />

								</Container>
							</Box>
						</ListTaskViewWrap>
						{/* </Col>
						</Row > */}
					</DataContainerStyled >
					{/* </Box > */}
				</Box >
				<ReasonModal
					showModal={showDateModal}
					onHide={() => setShowDateModal(false)}
					//heading="Task Timeline"
					closeButton={true}
					buttons={[
						{ text: "Ok", variant: "primary" },
						{ text: "Cancel", variant: "danger" },
					]}
					size="sm"
				>
					<CalendarContainer>
						<SpanStyled size="15px" family="var(--medium)" color="var(--darkBlue)" >Reason</SpanStyled>
						<Box className="w-100">
							<Box className=" pb-2 textarea-field-lg">
								<InputFloating
									row={5}
									textarea
									type="textarea"
									name={"reason"}
									placeholder="Enter Reason"

								/>
							</Box>
						</Box>
						<Box className="w-100 text-center pt-2 pb-2">
							<SpanStyled size="18px" family="var(--semiBold)" color="var(--darkBlue)" >Extended Due Date</SpanStyled>
						</Box>
						<StaticDatePickerContainer />
						{/* <Calendar onChange={onDataChange} value={date} />
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer
								components={[
									'DateRangePicker',
									'MobileDateRangePicker',
									'DesktopDateRangePicker',
									'StaticDateRangePicker',
								]}
							>
								<DemoItem label="Static variant" component="StaticDateRangePicker">
									<StaticDateRangePicker
										defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
										sx={{
											[`.${pickersLayoutClasses.contentWrapper}`]: {
												alignItems: 'center',
											},
										}}
									/>
								</DemoItem>
							</DemoContainer>
						</LocalizationProvider> */}
					</CalendarContainer>
				</ReasonModal>
			</CustomModal >
		</>
	);
};

