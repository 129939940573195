// import React from "react";
import { useState } from "react";
import { InputStyledWrap } from "../../../../Styled/styled";

// import DatePicker from 'react-date-picker';


import {
	Box,
	FormEleWrapper,
	LabelStyled,
	TextStyled,
} from "../../../../Styled/Elements";
import {
	RadioButton,
	InputWithIcon,
	CustomCheckbox,
} from "../../../../Styled/FormElements";
import { DayPick } from "../DayPicker";
import { TimePick } from "../TimePicker";

// type ValuePiece = Date | null;

const MeetingStatus = () => {

	// const [value, onChange] = useState<ValuePiece>(new Date());
	const [checkedValues, setCheckedValues] = useState([]);


	const meetingOption = [
		{
			label: "Weekly",
			value: "weekly",
		},
		{
			label: "Monthly",
			value: "monthly",
		},
		{
			label: "Quarterly",
			value: "quarterly",
		},
	];

	const quorumOption = [
		{
			label: "Full",
			value: "full",
		},
		{
			label: "Two Third",
			value: "two third",
		},
	];

	const mustSend = [
		{
			label: "Send agenda day before the meeting schedule. ",
			value: "Send agenda day before the meeting schedule. ",
		},
		{
			label: "Agenda must be sent before two hours from ending hours day, a day before the meeting schedule",
			value: "Agenda must be sent before two hours from ending hours day, a day before the meeting schedule",
		},
	];

	const agendaOption = [
		{
			label: "Completed Tasks",
			value: "completed tasks",
		},
		{
			label: "Financial Status",
			value: "financial status",
		},
		{
			label: "Challenges",
			value: "challenges",
		},
		{
			label: "Project Deviations",
			value: "project deviations",
		},
		{
			label: "New Topics",
			value: "new topics",
		},
		{
			label: "Must Send",
			value: "must send",
		},
	];
	const handleCheckboxChange = (value) => {
		const isChecked = checkedValues.includes(value);
	
		if (isChecked) {
		  setCheckedValues(checkedValues.filter((val) => val !== value));
		} else {
		  setCheckedValues([...checkedValues, value]);
		}
	};
	const isMustSendChecked = checkedValues.includes('must send');

	return (
		<>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Meeting</LabelStyled>
					<Box className="w-100">
						<Box className="d-flex w-100 radioListWrapper">
							{meetingOption.map((item, i) => (
								<Box key={i} className="radioList">
									<RadioButton
										color="var(--grey)"
										name="intExt"
										label={item.label}
										value={item.value}
									// checked={selectedInternalExternal === item.value}
									// onChange={handleInternalExternalRadioButtonChange}
									/>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Meeting Day</LabelStyled>
					<InputStyledWrap>
						<DayPick />
						{/* <InputWithIcon type="date" placeholder="Monday" icon={calendar} /> */}
						{/* <DatePicker onChange={onChange} value={value} />
							<input aria-label="Date" type="date" /> */}
										{/* <DatePicker
						onChange={(newValue) => onChange(newValue as ValuePiece)}
						value={value}
					/> */}
					</InputStyledWrap>
					<InputStyledWrap>
						<TimePick />
					</InputStyledWrap>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Quorum</LabelStyled>
					<Box className="w-100">
						<Box className="d-flex w-100 radioListWrapper">
							{quorumOption.map((item, i) => (
								<Box key={i} className="radioList">
									<RadioButton
										color="var(--grey)"
										name="intExt"
										label={item.label}
										value={item.value}
									// checked={selectedInternalExternal === item.value}
									// onChange={handleInternalExternalRadioButtonChange}
									/>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Agenda</LabelStyled>
					<Box>
						<ul className="d-flex list-unstyled d-flex align-items-center flex-wrap gap-4">
							{agendaOption?.map((item) => (
								<li className="me-3 custom-radio d-flex align-items-center">
									<CustomCheckbox
										name={item?.name}
										label={item?.label}
										value={item?.value}
										labelClass=""
										onChange={() => handleCheckboxChange(item.value)}
									/>
								</li>
							))}
						</ul>
						{isMustSendChecked && (
							<Box className="w-100 mt-3">
								<Box className="d-flex w-100 radioListWrapper flex-wrap gap-2">
									{mustSend.map((item, i) => (
										<Box key={i} className="radioList">
											<RadioButton
												color="var(--grey)"
												name="intExt"
												label={item.label}
												value={item.value}
											// checked={selectedInternalExternal === item.value}
											// onChange={handleInternalExternalRadioButtonChange}
											/>
										</Box>
									))}
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</FormEleWrapper>
		</>
	);
};

export default MeetingStatus;
