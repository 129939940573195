import { Component } from "react";
import Chart from "react-apexcharts";

class ReportsColumnStackedChart extends Component {
  constructor(props) {
    super(props);
    const seriesArray = [
      {
        name: "Completed Tasks",
        data: [13, 23, 20, 28, 13, 27, 30],
      },
      {
        name: "Project Tasks",
        data: [13, 23, 20, 28, 13, 27, 33],
      },
    ];
    const normalizedSeries = seriesArray.map((s) => {
      const maxDataVal = Math.max(...s.data);
      const normalizedData = s.data.map((d) => (d / maxDataVal) * 25);
      return {
        name: s.name,
        data: normalizedData,
      };
    });
    this.state = {
      series: normalizedSeries,
      options: {
        colors: ["var(--green2)", "var(--orange)"],
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: "var(--darkBlue)",
              fontSize: "14px",
              fontFamily: "var(--medium)",
            },
          },
        },
        yaxis: {
          show: true,
          tickAmount: 5,
          forceNiceScale: false,
          floating: false,
          decimalsInFloat: undefined,
          showForNullSeries: true,
          seriesName: undefined,
          opposite: false,
          reversed: false,
          logarithmic: false,
          min: 0,
          max: 50,
          axisBorder: {
            show: false,
          },
          labels: {
            show: true,
            formatter: (value) => {
              return value;
            },
            style: {
              colors: ["var(--grey-color)"],
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#D3D3D3",
          strokeDashArray: 3,
          position: "back",
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "13px",
          horizontalAlign: "center",
          inverseOrder: true,
          markers: {
            width: 12,
            height: 12,
            radius: 12,
          },
          itemMargin: {
            horizontal: 13,
            vertical: 10,
          },
          labels: {
            useSeriesColors: true,
          },
        },
        plotOptions: {
          bar: {
            stacked: true,
            columnWidth: "11px",
            horizontal: false,
            borderRadius: 6,
            colors: {
              backgroundBarOpacity: 1,
            },
            distributeSeries: true,
          },
        },
      },
    };
  }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        width="100%"
        height="370px"
      />
    );
  }
}

export default ReportsColumnStackedChart;
