import { Dashboard } from "../Pages/Dashboard";
import { Projects } from "../Pages/project/Projects";
import { AddProject } from "../Pages/project/AddProject";
import { Users } from "../Pages/Users/Users";
import { UserDetail } from "../Pages/Users/userdetail/UserDetail";
import { AddUser } from "../Pages/Users/userAdded/AddUser";
import { EditUser } from "../Pages/Users/EditUsers/EditUser";
import { ReceivedMeeting } from "../Pages/Meeting/ReceivedMeeting";
import { Meeting } from "../Pages/Meeting/Meeting";
import { StartMeeting } from "../Pages/Meeting/StartMeeting";
import { ViewProjects } from "../Pages/project/ViewProject";
import { Tasks } from "../Pages/Tasks/Tasks";
import { Overview } from "../Pages/Reports/Overview";
import { ReportsProject } from "../Pages/Reports/Project";
import { ProjectDetials } from "../Pages/Reports/Project/projectDetails";
import { ReportsTasks } from "../Pages/Reports/Tasks";
import { ReportsTeams } from "../Pages/Reports/Teams";
import { ProjectTeams } from "../Pages/Reports/Teams/projectTeams";
import { TaskTeams } from "../Pages/Reports/Teams/taskTeams";
import { ViewTask } from "../Pages/project/ViewTask";
import { Performance } from "../Pages/Performance";
import Messages from "../Pages/Messages/messages";
import { ViewPerformance } from "../Pages/Performance/ViewPerformance";
import { LibraryOverview } from "../Pages/Library/libraryOverview";
import { LibraryProjects } from "../Pages/Library/libraryProjects";
import { LibraryTasks } from "../Pages/Library/libraryTasks";
import { LibraryOthers } from "../Pages/Library/libraryOthers";
import { LibraryProjectView } from "../Pages/Library/libraryProjectView";
import { Payments } from "../Pages/payment/Payments";


export const routesAdminConfig = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/projects/view-projects",
    element: <ViewProjects />,
  },
  {
    path: "/projects/view-task",
    element: <ViewTask />,
  },
  {
    path: "/reports-overview",
    element: <Overview />,
  },
  {
    path: "/reports-teams",
    element: <ReportsTeams />,
  },
  {
    path: "/reports-teams/project-teams",
    element: <ProjectTeams />,
  },
  {
    path: "/reports-teams/task-teams",
    element: <TaskTeams />,
  },
  {
    path: "/reports-projects",
    element: <ReportsProject />,
  },
  {
    path: "/reports-projects/detail",
    element: <ProjectDetials />,
  },
  {
    path: "/reports-tasks",
    element: <ReportsTasks />,
  },
  {
    path: "/tasks",
    element: <Tasks />,
  },
  {
    path: "/project/add-project",
    element: <AddProject />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/add/users",
    element: <AddUser />,
  },
  {
    path: "/edit/users",
    element: <EditUser />,
  },
  {
    path: "/users/:id",
    element: <UserDetail />,
  },
  {
    path: "/meeting",
    element: <Meeting />,
  },
  {
    path: "/received-meeting",
    element: <ReceivedMeeting />,
  },
  {
    path: "/start-meeting",
    element: <StartMeeting />,
  },
  {
    path: "/performance",
    element: <Performance />,
  },
  {
    path: "/contact",
    element: <Messages />,
  },
  {
    path: "/performance/details",
    element: <ViewPerformance />,
  },
  {
    path: "/library-overview",
    element: <LibraryOverview />,
  },
  {
    path: "/library-projects",
    element: <LibraryProjects />,
  },
  {
    path: "/library-tasks",
    element: <LibraryTasks />,
  },

  {
    path: "/library-others",
    element: <LibraryOthers />,
  },
  {
    path: "/library-projects-view",
    element: <LibraryProjectView />,
  },
  {
    path: "/payments",
    element: <Payments />,
  },
];
