import styled from "styled-components";

export const ListTaskViewWrap = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .topBarInner{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--outlineC);
        padding: 20px;
    }
    .listTaskActWrapper{
        display: flex;
        align-items: flex-start;
        .listTaskView{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex: 1;
            // overflow: hidden;
            position: relative;
        }
        .listRowWrapper{
            display: flex;
            align-items: flex-start;
            width: 100%;
            gap: 15px;
        }
        .listTaskActivities{
            width: 100%;
            max-width: 400px;
            min-width: 400px;
            flex: 1;
            min-height: 100%;
            max-height: 100%;
            position: relative;
            // overflow-y: auto;
            
            
        }
    }
    
    
`