import React, { useState } from "react";
import { StdcpPicker } from './Datestyled';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StaticDatePickerContainer = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(null);
	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};
	return (
		<StdcpPicker>
			<DatePicker
				selected={startDate}
				onChange={onChange}
				minDate={new Date()}
				//maxDate={addMonths(new Date(), 5)}
				startDate={startDate}
				endDate={endDate}
				selectsRange
				inline
				showDisabledMonthNavigation
			/>
		</StdcpPicker>
	);
};


