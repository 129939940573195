import { FormStyled } from "./style";
import OtpField from "react-otp-field";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  ButtonStyled,
  SpanStyled,
  TextStyled,
} from "../../../Styled/Elements";

export const CodeGenerate = () => {
  const navigate = useNavigate();
  const [optValue, setOtpValue] = useState("");
  const [time, setTime] = useState(30);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => {
        if (t < 1) {
          clearInterval(timerId);
          return "00";
        }
        return t <= 10 ? "0" + (t - 1).toString() : "" + t - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);
  return (
    <FormStyled>
      <>
        <ul className="list-unstyled counter-wrapper">
          <li className="counter-box">00</li>
          <li>
            <SpanStyled size="14px" weight={600}>
              :
            </SpanStyled>
          </li>
          <li className="counter-box">{time}</li>
        </ul>
      </>
      <Box>
        <OtpField
          value={optValue}
          onChange={setOtpValue}
          numInputs={4}
          onChangeRegex={/^([0-9]{0,})$/}
          autoFocus
          isTypeNumber
          inputProps={{
            className: "otp-field__input w-100 text-center",
            disabled: false,
          }}
        />
      </Box>
      <Box className="button_text">
        <ButtonStyled onClick={() => navigate("/dashboard")}>
          <SpanStyled>VERIFY</SpanStyled>
        </ButtonStyled>
        <TextStyled size="14px">
          Did not receive OTP?
          <Link to="/" className="text-decoration-none mx-2">
            <SpanStyled color="var(--themeColor)">Resend</SpanStyled>
          </Link>
        </TextStyled>
      </Box>
    </FormStyled>
  );
};
