import Chart from "react-apexcharts";

export const ProgressGraphTeam = ({ series, colors, width, height }) => {
	const options = {
		chart: {
			type: "donut",
		},
		dataLabels: {
			enabled: false,
		},
		//labels: ['Progress 75%', 'Remaining 25%'],
		colors: colors,
		tooltip: {
			enabled: false,
		},
		stroke: {
			width: 0,
		},
		plotOptions: {
			pie: {
				donut: {
					size: "75%",
				},
			},
		},
		legend: {
			show: false,
		},
		responsive: [
			{
				options: {
					chart: {},
				},
			},
		],
	};

	return (
		<div className="ProgressBarContainer tbl-graph">
			<Chart
				options={options}
				series={series}
				type="donut"
				width={width ? width : 65}
				height={height && height}
			/>
		</div>
	);
};
