import { useState } from "react";

import { DateRangePickWrapper, SpanStyled, Box, TextWithIcon, text } from "../../../Styled/Elements";
import { InputWithIcon } from "../../../Styled/FormElements";
import { calendar } from "../../../Styled/AllImages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

export const DateRangePickersBar = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	// Format the date as 'DD / MM / YYYY'
	//const formattedDate = format(date, 'dd/MM/yyyy');

	return (
		<DateRangePickWrapper>
			<SpanStyled size="14px" color="var(--greyText)">
				Select Date Range
			</SpanStyled>
			<Box className='d-flex gap-2 align-items-center'>
				<Box className="rangeIconText">
					<TextWithIcon icon={calendar} title="From" />
				</Box>

				<Box className="rangeInner">
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						selectsStart
						startDate={startDate}
						endDate={endDate}
						dateFormat="MMM dd yyyy"
					/>
				</Box>
				<Box className="rangeIconText">
					<TextWithIcon icon={calendar} title="To" />
				</Box>
				<Box className="rangeInner">
					<DatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						dateFormat="MMM dd yyyy"
					/>
				</Box>
			</Box>
		</DateRangePickWrapper>
	);
};


