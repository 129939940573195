import { Col, Row } from "react-bootstrap";
import { Box, SpanStyled } from "../../../Styled/Elements";
import { ButtonWithIcon, InputFloating } from "../../../Styled/FormElements";
import { ArrowLeft, ArrowRight } from "../../../Styled/AllImages";

export const UsersPersonalInformation = () => {
  return (
    <Box className="px-16 pb-4 border-bottom-custom ">
      <Row className="px-2 py-4">
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Name</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="text" placeholder="Name" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">ID</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="Number" placeholder="ID" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Job Title</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="text" placeholder="Job Title" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Email</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="Email" placeholder="Email" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Department</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="Number" placeholder="Department" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Other Info</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="text" placeholder="Other Info" />
          </Col>
        </Box>
        <Box className="inputFieldBox">
          <Col lg={2}>
            <SpanStyled className="formLabel">Password</SpanStyled>
          </Col>
          <Col lg={10}>
            <InputFloating type="Passowrd" placeholder="Password" />
          </Col>
        </Box>
      </Row>
      <Box className="d-flex align-items-center justify-content-between">
        <ButtonWithIcon icon={ArrowLeft} label="Previous" />
        <ButtonWithIcon icon={ArrowRight} label="Next" />
      </Box>
    </Box>
  );
};
