import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { SingleProfile } from "../../../../../Styled/AllImages";
import { Box, SpanStyled } from "../../../../../Styled/Elements";
import { CustomCheckbox } from "../../../../../Styled/FormElements";

const DataWrapper = ({
	mainParents,
	onMainParentClick,
	selectedMainParents,
	props,
}) => {
	return (
		<UserUpload>
			<Dropdown>
				<Dropdown.Toggle
					bsPrefix
					className="bg-transparent position-relative border-0 p-0 UserUploadBtn"
				>
					<Box className="userPlaceholder">
						<SingleProfile />
					</Box>
					<SpanStyled className="Replac" color="var(--grey)">
						Add User
					</SpanStyled>

					{/* <SpanStyled className="Replac" color="var(--grey)">
						bbbb
					</SpanStyled> */}
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropMNListContainer">
					{mainParents.map((mainParent) => (
						<Dropdown.Item
							href="#!"
							className={`p-0 ${selectedMainParents.some(
								(selected) => selected.id === mainParent.id
							)
									? "selected"
									: ""
								}`}
							key={mainParent.id}
							onClick={() => onMainParentClick(mainParent)}
						>
							<DropListRow className="dropChild">
								<Box className="dropRadioContainer">
									<CustomCheckbox
										name="members"
										//value={props.value}

										checked={selectedMainParents.some(
											(selected) => selected.id === mainParent.id
										)}
										as="div"
									// key={mainParent.id}
									// onClick={() => onMainParentClick(mainParent)}
									/>
									{/* readOnly */}
								</Box>
								<Box className="selUserImg">
									<img src={mainParent.profile} alt="User" />
								</Box>
								<SpanStyled color="grey" className="ms-1">
									{mainParent.userName}
								</SpanStyled>
								{/* <SpanStyled color="grey" className="ms-1">
								{mainParent.userRole}
							</SpanStyled> */}
							</DropListRow>

							<Dropdown.Divider />
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</UserUpload>
	);
};

export default DataWrapper;

const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  display: flex;
  align-items: center;

  .UserUploadBtn {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--greyBg);
    border-radius: 50%;
    border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;

const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 7px;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
