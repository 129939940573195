import styled from "styled-components";
import { useState } from "react";
import {
  Box,
  ButtonStyled,
  CardStyled,
  SecondaryBarStyled,
  HeadingStyled,
} from "../../../Styled/Elements";
import { usersList } from "../../../Components/Dashboard/AdminConstant";

export const AddUser = () => {
  const [activeTab, setActiveTab] = useState(usersList[0]);
  return (
    <AddUserWapper>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            Add Detail
          </HeadingStyled>

          <TabStyled className="mb-0 list-unstyled d-flex justify-content-between">
            {usersList.map((item, key) => (
              <li
                className={` ${item.title === activeTab.title ? "active" : ""}`}
                onClick={() => setActiveTab(usersList[key])}
                key={key}
              >
                {item.title}
              </li>
            ))}
          </TabStyled>
        </Box>
      </SecondaryBarStyled>
      <CardStyled className="main-card-content">
        <activeTab.component />
        <Box className="bTNS">
          <ButtonStyled
            className="py-2 mx-2"
            bg="var(--themeColor)"
            width="120px"
          >
            Save
          </ButtonStyled>
          <ButtonStyled className="py-2 mx-2" bg="var(--red)" width="120px">
            Cancel
          </ButtonStyled>
        </Box>
      </CardStyled>
    </AddUserWapper>
  );
};
const AddUserWapper = styled.div`
  .secondaryBar {
    align-items: baseline;
    justify-content: flex-start;
    gap: 250px;
  }
  .main-card-content {
    margin: 18px;
    padding: 0 15px;
  }
  .bTNS {
    padding: 25px 0;
    border-top: 1px solid var(--greyBg);
  }
`;
export const TabStyled = styled.ul`
  gap: 50px;
  li {
    // width: 50%;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    &:not(.active) {
      color: var(--greyText);
      font-weight: 400;
    }
    &.active,
    &:hover {
      color: var(--themeColor);
      font-weight: 600;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 100%;
        height: 2px;
        border-radius: 20px;
        background-color: var(--themeColor);
      }
    }
  }
`;
