import ProjectBoard from "./ProjectBoard";
import MeetingStatus from "./MeetingStatus";
import Decisions from "./Decisions";
import ProjectStartDate from "./ProjectStartDate";

export const governancePhaseList = [
  {
    title: "Project Board",
    component: ProjectBoard,
  },
  {
    title: "Meeting Status",
    component: MeetingStatus,
  },
  {
    title: "Decisions",
    component: Decisions,
  },
  {
    title: "Project Start Date",
    component: ProjectStartDate,
  },
];
