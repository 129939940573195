import styled from "styled-components";

export const Badge = styled.div`
  padding: 8px 15px 0px;
  border-radius: 10px;
  font-family: var(--bold);
  font-weight: 700;
  font-size: 18px;
  color: var(--themeColor);
  & > span {
    padding: 0 6px;
  }
`;
export const BadgeDownload = styled.button`
   padding: 6px 15px;
    border-radius: 8px;
    border: 1px solid var(--themeColor);
    background: transparent;
    font-family: var(--medium);
    font-weight: 400;
    font-size: 12px;
    color: var(--themeColor);
    transition: all 0.4s ease-in
    &:hover{
        background:var(--themeColor);
        color:var(--white);
    }
`;
