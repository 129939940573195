import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { name, email, phone } from "../../../Styled/AllImages";
import { InputWithIcon } from "../../../Styled/FormElements";
import {
  Box,
  ButtonStyled,
  SpanStyled,
  TextStyled,
} from "../../../Styled/Elements";
import { FormBox } from "./style";
import { InputStyledWrap } from "../../../Styled/styled";

export const SignupForm = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormBox>
        <InputStyledWrap>
          <SpanStyled className="formLabel">Name</SpanStyled>
          <InputWithIcon type="text" placeholder="Name" icon={name} />
        </InputStyledWrap>
        <InputStyledWrap>
          <SpanStyled className="formLabel">Email</SpanStyled>
          <InputWithIcon type="email" placeholder="Email" icon={email} />
        </InputStyledWrap>
        <InputStyledWrap>
          <SpanStyled className="formLabel">Phone</SpanStyled>
          <InputWithIcon type="number" placeholder="Number" icon={phone} />
        </InputStyledWrap>
        <Box className="button_text">
          <ButtonStyled onClick={() => navigate("/Subscription-Plan")}>
            <SpanStyled>Next</SpanStyled>
          </ButtonStyled>
          <TextStyled size="14px">
            Already have an account?
            <Link to="/" className="text-decoration-none mx-2">
              <SpanStyled color="var(--themeColor)">Sign in</SpanStyled>
            </Link>
          </TextStyled>
        </Box>
      </FormBox>
    </>
  );
};
