import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../../Components/Footer/Footer";
import { SiteLogo, phone } from "../../../Styled/AllImages";
import { LandingWrapper, InputWithIcon } from "../../../Styled/FormElements";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  SpanStyled,
  TextStyled,
  ContainerWapper,
  LeftBox,
} from "../../../Styled/Elements";
import { InputStyledWrap } from "../../../Styled/styled";

export const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo">
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="34px" weight="bold" className="my-3">
              {t("mainHeading")}
              <SpanStyled color="var(--themeColor)" size="36px" weight="bold">
                PTM
              </SpanStyled>
            </HeadingStyled>

            <InputStyledWrap>
              <SpanStyled className="formLabel">{t("phone")}</SpanStyled>
              <InputWithIcon type="text" placeholder="Number" icon={phone} />
            </InputStyledWrap>
            <Box className="button_text">
              <ButtonStyled onClick={() => navigate("/otp")}>
                <SpanStyled>Sign In</SpanStyled>
              </ButtonStyled>
              <TextStyled size="14px">
                Don’t have an account?
                <Link to="/signup" className="text-decoration-none mx-2">
                  <SpanStyled color="var(--themeColor)">Sign Up</SpanStyled>
                </Link>
              </TextStyled>
            </Box>
          </LeftBox>
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Efficiently organize tasks, collaborate seamlessly, and boost productivity with our intuitive and powerful task management tool. Simplify your workflow today!"
          />

        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
