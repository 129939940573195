import { avatarImg } from "../../Styled/AllImages";

export const MessagesListArray = [
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
];

export const NotificationsListArray = [
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
  {
    userImg: avatarImg,
    title: "Your have received message from",
    userName: "Marvin McKinney",
    messageTime: "3:34 PM",
  },
];
