import {
  Box,
  ContainerWapper,
  HeadingStyled,
  LeftBox,
  SpanStyled,
} from "../../../Styled/Elements";
import { SiteLogo, BackArrow } from "../../../Styled/AllImages";
import { LandingWrapper } from "../../../Styled/FormElements";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { CodeGenerate } from "./CodeGenerate";
import { Footer } from "../../../Components/Footer/Footer";

export const Otp = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo mb-4">
            <BackArrow className="back_arrow" onClick={() => navigate("/")} />
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="36px" weight="bold" className="mb-2">
              We just
              <SpanStyled className='px-1' color="var(--themeColor)" size="36px" weight="bold">
                texted you
              </SpanStyled>
            </HeadingStyled>
            <Box>
              <HeadingStyled size="24px" weight="bold" className="">
                Bank Account Information
              </HeadingStyled>
            </Box>
            <Box>
              <SpanStyled color="var(--grey)" className="my-3">
                Please see the account info below and send total amount
              </SpanStyled>
              <HeadingStyled size='18px' color="var(--themeColor)" className="my-1">
                +923238913202
              </HeadingStyled>
            </Box>
          </LeftBox>
          <CodeGenerate />
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Experience a new level Efficiently organize tasks, collaborate seamlessly, and boost productivity with our intuitive and powerful task management tool. Simplify your workflow today!s project organization. Join our Task Management platform today."
          />
        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
