export const PlanDataArray = [
  {
    label: "SAR 300 / 3 Months + 1 Month",
    // value: 'trial'
  },
  {
    label: "SAR 600 / 12 Months + 1 Month",
    // value: '200/month'
  },
  {
    label: "SAR 900 / 36 Months + 1 Month",
    // value: '200/6 month'
  },
  {
    label: "SAR 900 / 60 Month + 1 Months",
    // value: '200/12 month'
  },
];
export const PaymentMethodArray = [
  {
    label: "Bank Transfer",
    value: "bank",
  },
  {
    label: "Card",
    value: "card",
  },
];
