import styled from "styled-components";

export const StdcpPicker = styled.div`
	display: flex;
	width: 100%;
	.react-datepicker{
		width: 100%;
		border: none;
		.react-datepicker__month-container {
			width: 100%;
		}
		.react-datepicker__month{
			margin-left: 0;
			margin-right: 0;
		}
	}
`;

