import { createSlice } from "@reduxjs/toolkit";
export const LanguageSlice = createSlice({
  name: "language",
  initialState: {
    language: "en",
    dir: "ltr",
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
      state.dir = state.language == "en" ? "ltr" : "rtl";
    },
  },
  extraReducers: (builder) => {},
});

export const { changeLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
