import {
  avatarImg,
  profile,
  profile2,
  profile3,
  profile4,
} from "../../../Styled/AllImages";

export const UsersListArray = [
  {
    userImg: profile,
    label: "",
    value: "user1",
    name: "Savannah Nguyen",
  },
  {
    userImg: profile2,
    label: "",
    value: "user2",
    name: "Kristin Watson",
  },
  {
    userImg: profile3,
    label: "",
    value: "user3",
    name: "Brooklyn Simmons",
  },
  {
    userImg: profile4,
    label: "",
    value: "user4",
    name: "Annette Black",
  },
];
