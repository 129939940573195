import { useState } from "react";
import { Step1 } from "./addProject/Step1";
import { Step2 } from "./addProject/Step2";
import { Step3 } from "./addProject/Step3";
import { Step4 } from "./addProject/Step4";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { MultiStepProgressBar } from "./addProject/MultiStepProgressBar";
import {
	Box,
	ButtonStyledMedium,
	HeadingStyled,
	DataContainerStyled,
	SecondaryBarStyled,
	SpanStyled,
	ButtonStyled,
} from "../../Styled/Elements";
import { SaveProjectLayout } from '../project/addProject/SaveProjectLayout'

export const AddProject = () => {
	const [SaveProjectmodalShow, setSaveProjectModalShow] = useState(false);
	const [currentStep, setCurrentStep] = useState(1);
	const navigate = useNavigate();
	// const [email, setEmail] = useState('');
	// const [username, setUsername] = useState('');
	// const [password, setPassword] = useState('');

	// const handleChange = event => {
	//   const { name, value } = event.target;
	//   switch (name) {
	// 	case 'email':
	// 	  setEmail(value);
	// 	  break;
	// 	case 'username':
	// 	  setUsername(value);
	// 	  break;
	// 	case 'password':
	// 	  setPassword(value);
	// 	  break;
	// 	default:
	// 	  break;
	//   }
	// };

	// const handleSubmit = event => {
	//   event.preventDefault();
	//   alert(`Your registration detail: \n
	// 	Email: ${email} \n
	// 	Username: ${username} \n
	// 	Password: ${password}`);
	// };

	const next = () => {
		setCurrentStep(currentStep >= 3 ? 4 : currentStep + 1);
	};

	const prev = () => {
		setCurrentStep(currentStep <= 1 ? 1 : currentStep - 1);
	};

	const previousButton = () => {
		if (currentStep !== 1) {
			return (
				<ButtonStyled type="button" bg="var(--themeColor)" onClick={prev}>
					Previous
				</ButtonStyled>
			);
		}
		return null;
	};

	const nextButton = () => {
		if (currentStep < 4) {
			return (
				<ButtonStyled type="button" bg="var(--themeColor)" onClick={next}>
					Next
				</ButtonStyled>
			);
		}
		return null;
	};

	return (
		<>
			<Box className="main-content-inner">
				<SecondaryBarStyled className="secondaryBar">
					<Box className="flex-wrap">
						<HeadingStyled
							className="mb-0 w-100"
							size="18px"
							lh="25px"
							weight="normal"
							color="var(--darkBlue)"
							family="var(--semiBold)"
						>
							Android App UX/Styled Design
						</HeadingStyled>
						<SpanStyled
							color="var(--grey)"
							size="14px"
							lh="15px"
							className="pt-hf"
						>
							ID 03254875
						</SpanStyled>
					</Box>
					<Box className="boxRBt">
						{currentStep === 4 && (
							<ButtonStyled onClick={() => setSaveProjectModalShow(true)} size='14px' border="1px solid var(--white)" color="var(--white)" bg="var(--green)">
								Save Project layout
							</ButtonStyled>
						)}
						<ButtonStyledMedium onClick={() => navigate("/projects/view-projects")}> View Project </ButtonStyledMedium>

					</Box>
				</SecondaryBarStyled>
				<Box className="main-content">
					<DataContainerStyled className="allDataContainer mb-4 p-0">
						<Row>
							<Col xs={12}>
								<Form>
									<Box className="pBarContainer">
										<MultiStepProgressBar currentStep={currentStep} />
									</Box>
									<Box className="formDataContainer">
										{currentStep === 1 && <Step1 currentStep={currentStep} />}
										{currentStep === 2 && <Step2 currentStep={currentStep} />}
										{currentStep === 3 && <Step3 currentStep={currentStep} />}
										{currentStep === 4 && <Step4 currentStep={currentStep} />}

										{currentStep === 4 ? (
											<Box className="formBtns">
												<ButtonStyled bg="var(--red)">Clear All</ButtonStyled>
												<ButtonStyled color="var(--white)" bg="var(--green2)">Start</ButtonStyled>
												{previousButton()}
											</Box>
										) : (
											<Box className="formBtns">
												<ButtonStyled bg="var(--red)">Clear All</ButtonStyled>
												<ButtonStyled border="1px solid var(--themeColor)" color="var(--themeColor)" bg="var(--white)">Save as Draft</ButtonStyled>
												{previousButton()}
												{nextButton()}
											</Box>
										)}
									</Box>
								</Form>
							</Col>
						</Row>
					</DataContainerStyled>
				</Box>
				<Modal size='lg' className='custom-setting-modal' show={SaveProjectmodalShow} centered onHide={setSaveProjectModalShow} >
					<Modal.Header closeButton>
						<Modal.Title>
							<HeadingStyled size='18px' weight={600}>Save Project Layout</HeadingStyled>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<SaveProjectLayout />
					</Modal.Body>
				</Modal>
			</Box>
		</>
	);
};
