import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import HelperSlice from "./slices/HelperSlice";
import LanguageSlice from "./slices/LanguageSlice";
const persistConfig = {
  key: "auth",
  storage,
};

const rootReducer = combineReducers({
  helpers: HelperSlice,
  language: LanguageSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
