import styled from "styled-components";

export const ChatWrapper = styled.div`
    .card-styled-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 27px 15px;
    }
    
    .chat-card{
        background-color: var(--white);
    }
    .chat-body {
        padding: 50px 15px;
        overflow-y: auto;
        height: calc(100vh - 180px);
    }
    .chat-footer {
        position:absolute;
        padding: 20px 15px 0px;
        bottom: 0;
        left:0;
        width: 100%;
        background-color: var(--greyBg);
        ul{
            padding: 10px 12px;
            background-color: var(--white);
            border-radius: 8px;
            border:1px solid var(--greyBg);
            li {
                line-height: normal;
            }
        }
    }
    .article-field {
        width: 100%;
        article {
            color: var(--greyText);
            &:focus {
                &:before {
                    display: none;
                }
            }
            position: relative;
            outline: none;
            font-size: 15px;
            font-weight: 400;
            word-break: break-all;
            &[contenteditable]:empty:before {
                content: attr(placeholder);
                position: absolute;
                top: 0px;
                left: 0;
                font-weight: 400;
                width: fit-content;
                background: var(--white);
                color: var(--greyText);
                font-size: 15px;
            }
        }
    }
    .menu-customized {
        width: 500px;
        box-shadow: 0px 18px 30px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        padding: 20px;
        max-height: calc(100vh - 225px);
        overflow-y: scroll;
        .badge {
            background: var(--lightBlue);
            border: 1px solid var(--lightBlue);
            color: var(--themeColor);
            padding: 5px 8px;
            font-weight: 400;
            font-size: 14px;
        }
        .exit-btn {
            background: var(--red);
            border: 1.5px solid var(--red);
            border-radius: 6px;
        }
        hr {
            margin-left: -20px;
            margin-right: -20px;
            border-color: var(--greyText);
            opacity: 1;
        }
        // .ps-customized {
        //     padding-left: 53px;
        // }
        .direct-member{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius:10px;
            &:hover{
                background: var(--lightBlue);
            }
        }
    }
    
    }
    .chat-send-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
    .card-styled-header .messages{
        display: flex !important;
    }
`
export const MessageTileWrap = styled.div`
&.receiver {
    .message-area {
        background: var(--greyBg);
        border-radius: 0px 15px 15px 15px;
        color: #4B4B5A;
        span {
            color: #A4A4A4;
        }
    }
}
&.sender {
    .message-area {
        background: var(--themeColor);
        border-radius: 15px 0px 15px 15px;
        color: #fff;
        text-align: left;
        span {
            color: #fff;
        }
    }
}
.message-area {
    padding: 10px 15px;
}
`
export const MessageSidebarWrapper = styled.aside`
width: 300px;
background: var(--white);
box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
height: 100%;
overflow-y: auto;
    border-right: 1px solid var(--outlineC);

.profile-view, .direct-message-list, .group-view{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.edit-btn {
    width: 37px;
    height: 37px;
    background-color: var(--themeColor);
}
.add-btn {
    font-weight: 600;
    font-size: 14px;
    color: var(--themeColor);
    line-height: normal;
}
.group-list{
    display: flex;
    align-items: center;
    gap:6px;
}
.bage-hash {
    width: 24px;
    height: 24px;
}
.list-group {
    li {
        padding: 8px 15px;
        &:hover {
            background-color: var(--lightBlue);
        }
    }
}
`