import Timeline, { CustomMarker, TodayMarker } from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import styled, { css } from "styled-components";
import { AvatarGroup, Box, SpanStyled } from "../../Styled/Elements";
import { ChevronDown } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { showMembersModal } from "../../Store/slices/HelperSlice";
import { TimelineMarkers } from "react-calendar-timeline";

const Pill = styled.div`
  span {
    background-color: ${(props) => (props.color ? props.color : "#e4e5e7")};
    border-radius: 4px;
    height: 43px;
    padding: 3px 8px;
    margin: 0px 5px;
    color: ${(props) => (props.textColor ? props.textColor : "#92929d")};
  }
`;

const groups = [
  {
    id: 1,
    title: (
      <Box className="formEleContainer align-items-baseline">
        <Box className="d-flex">
          <Box className="pb-2">
            <ChevronDown width="20px" height="20px" color="var(--bs-gray-600)" style={{ border: `1px solid var(--bs-gray-500)` }} className="p-1 rounded-circle cursor-pointer" />
          </Box>
          <Pill color="var(--bs-gray-500)" textColor="var(--greyBg)">
            <SpanStyled weight={500} size="14px" className='cursor-pointer'>
              TO DO
            </SpanStyled>
          </Pill>
        </Box>
      </Box>
    ),
    height: 130,
    className: "groupHeader"
  },
  {
    id: 2,
    title: (
      <Box className="formEleContainer align-items-baseline">
        <Box className="d-flex">
          <Box className="pb-2">
            <ChevronDown width="20px" height="20px" color="var(--themeColor)" style={{ border: `1px solid var(--themeColor)` }} className="p-1 rounded-circle cursor-pointer" />
          </Box>
          <Pill color="var(--themeColor)" textColor="#fff">
            <SpanStyled weight={500} size="14px" className='cursor-pointer'>
              IN PROGRESS
            </SpanStyled>
          </Pill>
        </Box>
      </Box>
    ),
    height: 130,
    className: "groupHeader"
  },
  {
    id: 3,
    title: (
      <Box className="formEleContainer align-items-baseline">
        <Box className="d-flex">
          <Box className="pb-2">
            <ChevronDown width="20px" height="20px" color="var(--green2)" style={{ border: `1px solid var(--green2)` }} className="p-1 rounded-circle cursor-pointer" />
          </Box>
          <Pill color="var(--green2)" textColor="#fff">
            <SpanStyled weight={500} size="14px" className='cursor-pointer'>
              COMPLETE
            </SpanStyled>
          </Pill>
        </Box>
      </Box>
    ),
    height: 130,
    className: "groupHeader"
  },
];

const items = [
  {
    id: 1,
    group: 1,
    title: "Android Development",
    start_time: moment().add(14, "days"),
    end_time: moment().add(23, "days"),
    color: "#000",
    bgColor: "var(--bs-gray-500)",
    executer: [
      { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
    ]
  },
  {
    id: 2,
    group: 2,
    title: "Android Developement",
    start_time: moment().add(9, "days"),
    end_time: moment().add(24, "days"),
    color: "#fff",
    bgColor: "var(--themeColor)",
    executer: [
      { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
    ]
  },
  {
    id: 4,
    group: 2,
    title: "UI Design",
    start_time: moment().add(5, "days"),
    end_time: moment().add(15, "days"),
    color: "#fff",
    bgColor: "var(--themeColor)",
    executer: [
      { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
    ]
  },
  {
    id: 3,
    group: 3,
    title: "UX Research",
    start_time: moment().add(2, "days"),
    end_time: moment().add(17, "days"),
    color: "#fff",
    bgColor: "var(--green2)",
    executer: [
      { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
    ]
  },
];

export const MyTimeline = () => {
  const dispatch = useDispatch()
  const today = moment().format('YYYY-MM-DD');


  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    return (
      <div
        {...getItemProps({
          style: {
            // backgroundColor: item.bgColor,
            color: item.color,
            marginTop: '15px',
            borderRadius: "4px",
            border: "none",
            padding: "1px 2px",
            overflow: "hidden",
          },
        })}
      >
        <Box className="d-flex align-items-center mx-1">
          <AvatarGroup data={item.executer} dialog={() => dispatch(showMembersModal(true))} width='26px' height='24px' />
          <SpanStyled className="mx-1 text-nowrap">
            {item.title} (ID:{item.id})
          </SpanStyled>
        </Box>
      </div>
    );
  };

  const markerStyles = ({ styles, date }) => {
    const customBar = {
      ...styles,
      backgroundColor: 'var(--themeColor)',
      width: '2px',
    }
    const customStyles = {
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: 'var(--themeColor)',
    }

    return (
      <>
        <div style={customBar}>
          <div style={{ position: 'relative' }}>
            <div style={customStyles}></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <TimelineContainer className="main-content">
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={moment().startOf("month")}
        defaultTimeEnd={moment().endOf("month")}
        canMove={false}
        canResize={false}
        itemRenderer={itemRenderer}
        stackItems
      >
        <TimelineMarkers>
          <TodayMarker>
            {markerStyles}
          </TodayMarker>
          <CustomMarker date={today}>
            {markerStyles}
          </CustomMarker>
          {/* <CursorMarker>
            {markerStyles}
          </CursorMarker> */}
        </TimelineMarkers>
      </Timeline>
    </TimelineContainer>
  );
};

const TimelineContainer = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }
  .rct-items .rct-item {
    height: 26px!important;
  }
  ${(props) =>
    props.children.props.items &&
    css`
      ${props.children.props.items.map(
      (item, index) => `
          .rct-items .rct-item:nth-child(${index + 1}) {
            background: ${item.bgColor}!important;
          }
        `
    ).join('')}
    `}
  .react-calendar-timeline .rct-header-root {
    background: none;
  }
  .rct-dateHeader.rct-dateHeader-primary {
    color: var(--grey);
  }
  .react-calendar-timeline .rct-dateHeader {
    color: var(--grey);
    border-bottom: none;
    border-left: none;
    background-color: none !important;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      background: #f0f0f0;
  }
  .react-calendar-timeline .rct-calendar-header {
      border: none;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl {
      border-left: none;
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0px 12px 0px 2px;
  }
  .react-calendar-timeline .rct-dateHeader {
    background-color: var(--greyBg);
  }
  .react-calendar-timeline .rct-dateHeader {
    justify-content: left;
    padding-left: 5px;
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: none;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: none;
  }
`;
