import React, { useState } from 'react'
import { AvatarGroup, Box, DataContainerStyled, HeadingStyled, HeadingStyledDB, SpanStyled, TextWithIcon, BoxCheck } from '../../Styled/Elements'
import { Col, Modal, Row } from 'react-bootstrap'
import { ProjectViewTiles } from '../../Components/Dashboard/Pages/TilesArray'
import { ProjectsTile } from '../../Components/Dashboard/Pages/ProjectsTile'
import { ListTable } from '../../Components/Dashboard/Pages/Tables/ListTable'
import { DeleteEntry } from '../../Components/Modals/DeleteEntry'
import { useDispatch, useSelector } from 'react-redux'
import { showMembersModal } from '../../Store/slices/HelperSlice'
import { Clander } from '../../Styled/AllImages'
import Select from 'react-dropdown-select'


const ViewProjectList = () => {
    const [showModal, setModal] = useState(false);
    const helpers = useSelector((state) => state.helpers);
    const dispatch = useDispatch();
    const InventoryColumns = [
        {
            name: "taskName",
            label: "Task Name",
            render: (text) => {
                const desginTextBgClass = text === "Android Development" ? "var(--grey)" : text === "IOS Development" ? "var(--orange)" : text === "Web Development" ? "var(--purple)" : text === "Media Channel" ? "var(--green2)" : "";
                return (
                    <>
                        <SpanStyled border={`1px solid ${desginTextBgClass}`} className={`check-design`}>
                            <SpanStyled bg={`${desginTextBgClass}`} className={`check-design-inner`}></SpanStyled>
                        </SpanStyled>
                        <SpanStyled className='px-2'>{text}</SpanStyled>
                    </>
                )
            },
        },
        {
            name: "type",
            label: "Type",
            render: (text) => {
                const desginBgClass = text === "standalone" ? "var(--lightGreen1)" : text === "relational" ? "var(--lightPurple)" : "";
                const desginBorderClass = text === "standalone" ? "var(--green2)" : text === "relational" ? "var(--purple)" : "";
                return (
                    <SpanStyled bg={desginBgClass} border={`1px solid ${desginBorderClass}`} className={`radio-design `}></SpanStyled>
                )
            },
        },
        {
            name: "status",
            label: "Status",
        },
        {
            name: "executer",
            label: "Executer",
            render: (executer, row, dispatch) => (
                <AvatarGroup data={executer} dialog={() => dispatch(showMembersModal(true))} />
            ),
        },
        {
            name: "Start",
            label: "Start",
            render: (text) => {
                return (
                    <TextWithIcon icon={Clander} title={text} />
                )
            }
        },
        {
            name: "Duration",
            label: "Duration",
        },
        {
            name: "End",
            label: "End",
            render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
        },
    ];
    const rows = [
        {
            taskName: "Android Development",
            type: "standalone",
            status: "Started",
            executer: [
                {
                    name: "Arham",
                },
                {
                    name: "naeem",
                },
                {
                    name: "naeem",
                },
            ],
            Start: "Today, May 24",
            Duration: "6 Days",
            End: "May 30",
        },
        {
            taskName: "Web Development",
            type: "relational",
            status: "Started",
            executer: [
                {
                    name: "Arham",
                },
                {
                    name: "naeem",
                },
                {
                    name: "naeem",
                },
            ],
            Start: "Today, May 24",
            Duration: "6 Days",
            End: "May 30",
        },
    ];
    const [selectedValues, setSelectedValues] = useState(1);
    const handleSelectChange = (values) => {
        setSelectedValues(values);
    };
    const options = [
        {
            value: 1,
            label: <div className='position-relative px-4'><span className="option-all"></span>All</div>,
        },
        {
            value: 2,
            label: <div className='position-relative px-4'><span className="option-Standalone"></span>Standalone Task</div>,
        },
        {
            value: 3,
            label: <div className='position-relative px-4'><span className="option-Relational"></span>Relational Task</div>,
        }
    ];


    return (
        <>
            <Box className="main-content">
                <DataContainerStyled className="mb-4 p-0">
                    <Box className="flex-wrap p-4 border-bottom-custom">
                        <HeadingStyled className="mb-0 w-100" size="18px" lh="25px" weight="normal" color="var(--darkBlue)" family="var(--semiBold)">
                            Android App UX/Styled Design
                        </HeadingStyled>
                        <SpanStyled color="var(--grey)" size="14px" lh="15px" className="pt-hf">
                            ID 03254875
                        </SpanStyled>
                    </Box>
                    <Row className="mb-4 p-4">
                        <Col xs={12}>
                            <Box className='d-flex align-item-center justify-content-between'>
                                <HeadingStyledDB family="var(--semiBold)" className='pb-3'>
                                    Project Tasks
                                </HeadingStyledDB>
                                <Box>
                                    <Select className='view-project-list' options={options} value={selectedValues} onChange={handleSelectChange} />
                                </Box>
                            </Box>
                            <Row className="px-row-10 mb-4 row-tile row-tile-3">
                                {ProjectViewTiles.map((item, key) => (
                                    <Col key={item.title + key} className="mt-2 col-tile col-tile-3 pro-tas-list">
                                        <ProjectsTile {...item} />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    <Box>
                        <ListTable
                            send={"/projects/view-task"}
                            options={["send"]}
                            idIndex={1}
                            value="inventory-Edit"
                            rows={rows}
                            columns={InventoryColumns}
                        />
                    </Box>
                    <DeleteEntry
                        title="Inventory"
                        setModal={setModal}
                        showModal={showModal}
                    />
                </DataContainerStyled>
            </Box>

            <Modal
                show={helpers?.membersModal}
                className="members-popup"
                onHide={() => dispatch(showMembersModal(false))}
                centered
            >
                <Modal.Header closeButton>
                    <HeadingStyled size="18px" weight="600" color="var(--white)">
                        Members
                    </HeadingStyled>
                </Modal.Header>
                <Modal.Body>
                    <Box className="d-flex align-items-center mb-3">
                        <Box
                            className="meeting-dropdown mx-2"
                            style={{ width: "30px", height: "30px" }}
                        >
                            <img
                                alt="img"
                                className="img-fluid"
                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                            />
                        </Box>
                        <HeadingStyled
                            size="14px"
                            className=" mx-1 "
                            color="var(--drColor)"
                            weight={500}
                        >
                            Dell Alienware
                        </HeadingStyled>
                    </Box>
                    <Box className="d-flex align-items-center mb-3">
                        <Box
                            className="meeting-dropdown mx-2"
                            style={{ width: "30px", height: "30px" }}
                        >
                            <img
                                alt="img"
                                className="img-fluid"
                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                            />
                        </Box>
                        <HeadingStyled
                            size="14px"
                            className=" mx-1 "
                            color="var(--drColor)"
                            weight={500}
                        >
                            Dell Alienware
                        </HeadingStyled>
                    </Box>
                    <Box className="d-flex align-items-center">
                        <Box
                            className="meeting-dropdown mx-2"
                            style={{ width: "30px", height: "30px" }}
                        >
                            <img
                                alt="img"
                                className="img-fluid"
                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                            />
                        </Box>
                        <HeadingStyled
                            size="14px"
                            className=" mx-1 "
                            color="var(--drColor)"
                            weight={500}
                        >
                            Dell Alienware
                        </HeadingStyled>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewProjectList
