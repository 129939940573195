import { Col, Row } from "react-bootstrap";
import { Box, TextStyled } from "../../../Styled/Elements";

export const BankDetailList = (props) => {
  return (
    <>
      <Row className="align-item-center">
        <Col md={12} lg={6} className="colBkList ps-0">
          <Box className="iconBkList">
            <props.icon width="24px" />
          </Box>
          <TextStyled
            className="mb-0"
            size="15px"
            family="var(--regular)"
            color="var(--grey)"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></TextStyled>
        </Col>
        <Col md={12} lg={6} className="colBkList">
          <Box>
            <TextStyled
              color="var(--grey)"
              size="15px"
              family="var(--semiBold)"
            >
              {props.text}
            </TextStyled>
          </Box>
        </Col>
      </Row>
    </>
  );
};
