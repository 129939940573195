import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { RadioButton } from "../../../Styled/FormElements";
import { Box, SpanStyled } from "../../../Styled/Elements";

export const UsersList = (props) => {
  const {
    selectedItem,
    setSelectedItem,
    setSelectedItemImage,
    setSelectedItemName,
  } = props;

  //good solution
  // const handleMainChildClick = () => {
  //     setSelectedItem(props.value);
  // };

  // const handleMainChildClick = (itemValue) => {
  //   // If the clicked item is the currently selected item, deselect it.
  //   setSelectedItem(itemValue);
  // };

  const handleMainChildClick = (e) => {
    console.log(selectedItem, "selectedItem");
    console.log(props.value, "props.value");
    if (selectedItem === props.value) {
      setSelectedItem(null);
      setSelectedItemImage(null);
      setSelectedItemName(null);
    } else {
      setSelectedItem(props.value);
      setSelectedItemImage(props.userImg);
      setSelectedItemName(props.name);
    }
  };

  return (
    <>
      <Dropdown.Item
        href="#!"
        className={`p-0 ${selectedItem === props.value ? "selected" : ""}`}
      >
        <DropListRow className="dropChild" onClick={handleMainChildClick}>
          <Box className="dropRadioContainer">
            <RadioButton
              color="var(--grey)"
              // name="testww"
              // label={props.label}
              // value={props.value}
              checked={selectedItem === props.value}
              as="div"
              disabled
            />
          </Box>
          <Box className="selUserImg">
            <img src={props.userImg} alt="User" />
          </Box>
          <SpanStyled color="grey" className="ms-1">
            {props.name}
          </SpanStyled>
        </DropListRow>
        {/* <MNListWrapper>
                    <Box className='MNListImg'>
                        <props.userImg />
                    </Box>
                    <Box className='userMNDetail'>
                        <Box className='titleName bluDot'>
                            <TextStyled size='12px' className='MNtitle mb-1'>
                                {props.userName}
                            </TextStyled>
                            <TextStyled size='12px' family='var(--semiBold)' className='MNuserName mb-1'>
                                {props.userName}
                            </TextStyled>
                        </Box>
                        
                        <SpanStyled size='12px' family='var(--medium)' color='var(--themeColor)'>
                            {props.messageTime}
                        </SpanStyled>
                        
                    </Box>
                </MNListWrapper> */}
        <Dropdown.Divider />
      </Dropdown.Item>

      {/* <Dropdown.Item href="#!" className="parentDiv">
            {UsersListArray.map((item) => (
                <div key={item.value}
                className={`mainChild ${selectedItem === item.value ? 'selected' : ''}`}
                onClick={() => handleMainChildClick(item.value)}
                >
                <RadioButton
                    color="var(--grey)"
                    name="test"
                    label={item.label}
                    value={item.value}
                />
                <div className="">
                    <img src={profile} alt="Preview" width={25} height={25} />
                    <avatarImg />
                </div>
                <SpanStyled color="grey">{item.label}</SpanStyled>
                </div>
            ))}
        </Dropdown.Item> */}
    </>
  );
};
const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 7px;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
