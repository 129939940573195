import React, { useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import { AssignTaskToTeam } from "./AssignTaskToTeam";
import { DeleteEntry } from "../../../Components/Modals/DeleteEntry";
import {
  AvatarGroup,
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SpanStyled,
  TextWithIcon,
} from "../../../Styled/Elements";
import { ListTable } from "../../../Components/Dashboard/Pages/Tables/ListTable";
import { showMembersModal } from "../../../Store/slices/HelperSlice";
import { useDispatch, useSelector } from "react-redux";

const rows = [
  {
    id: "00125",
    taskName: "Android Development",
    Type: "standalone",
    Start: "May 24, 2023",
    Duration: "6 Days",
    End: "May 30, 2023",
    executer: [
      {
        name: "Arham",
      },
      {
        name: "naeem",
      },
      {
        name: "Ali cheema",
      },
    ],
    status: "Assigned",
    actions: ["Assign", "Delete"],
  },
  {
    id: "00125",
    taskName: "Android Development",
    Type: "sequence",
    Start: "May 24, 2023",
    Duration: "6 Days",
    End: "May 30, 2023",
    executer: [
      {
        name: "Arham",
      },
      {
        name: "naeem",
      },
    ],
    status: "Not Assigned",
    actions: ["Assign", "Delete"],
  },
];
export const Step4 = (props) => {
  const [showModal, setModal] = useState(false);
  const [showAssignTaskToTeamModal, setShowAssignTaskToTeamModal] =
    useState(false);
  const helpers = useSelector((state) => state.helpers);
  const dispatch = useDispatch();

  if (props.currentStep !== 4) {
    return null;
  }

  const InventoryColumns = [
    {
      name: "id",
      label: "#",
    },
    {
      name: "taskName",
      label: "Task Name",
      render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
    },
    {
      name: "Type",
      label: "Type",
      render: (text) => {
        const TypeBadgeClass =text === "standalone"? "var(--green)": text === "sequence"? "var(--orange)": "";
        return (
          <ButtonStyledMedium bg={TypeBadgeClass} padding="2px 12px">
            {text}
          </ButtonStyledMedium>
        );
      },
    },
    {
      name: "Start",
      label: "Start",
    },
    {
      name: "Duration",
      label: "Duration",
    },
    {
      name: "End",
      label: "End",
      render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
    },
    {
      name: "executer",
      label: "Executer",
      render: (executer, row, dispatch) => (
        <AvatarGroup
          data={executer}
          dialog={() => dispatch(showMembersModal(true))}
        />
      ),
    },
    {
      name: "status",
      label: "Status",
      render: (text) => {
        const badgeClass =
          text === "Start"
            ? "bg-orange"
            : text === "Governance"
            ? "bg-purple"
            : text === "Execution"
            ? "bg-green"
            : text === "Planning"
            ? "bg-blue"
            : text === "Assigned"
            ? "bg-blue"
            : text === "Not Assigned"
            ? "bg-orange"
            : "";
        return (
          <Badge className={`py-2 px-3 d-inline-flex align-items-center cursor-pointer ${badgeClass}`}>
            <SpanStyled className="text-capitalize">{text}</SpanStyled>
          </Badge>
        );
      },
    },
    {
      name: "actions",
      label: "Actions",
      render: (actions) =>
        actions.map((text, index) => {
          const actionBgClass =
            text === "Assign" ? "var(--themeColor)" : text === "Delete" ? "bg-red" : "";
          return (
            <Badge className={`py-2 mt-1 mx-1 ${actionBgClass}`} onClick={() => handleActions(text)} >
              <SpanStyled className="text-capitalize">{text}</SpanStyled>
            </Badge>
          );
        }),
    },
  ];

  const handleActions = (action) => {
    if (action === "Assign") {
      setShowAssignTaskToTeamModal(true);
    } else if (action === "Delete") {
      setModal(true);
    }
  };
  const onDelete = () => {
    setModal(true);
  };

  return (
    <>
      <Box>
        <ListTable
          onDelete={onDelete}
          // options={["view", "delete"]}s
          idIndex={1}
          value="inventory-Edit"
          rows={rows}
          columns={InventoryColumns}
        />
      </Box>
      <DeleteEntry
        title="Inventory"
        setModal={setModal}
        showModal={showModal}
      />
      {/* <AssignTaskToManager
        showAssignTaskModal={showAssignTaskModal}
        setShowAssignTaskModal={() => setShowAssignTaskModal(false)}
      /> */}
      <AssignTaskToTeam
        showAssignTaskToTeamModal={showAssignTaskToTeamModal}
        setShowAssignTaskToTeamModal={() => setShowAssignTaskToTeamModal(false)}
      />

      <Modal
        show={helpers?.membersModal}
        className="members-popup"
        onHide={() => dispatch(showMembersModal(false))}
        centered
      >
        <Modal.Header closeButton>
          <HeadingStyled size="18px" weight="600" color="var(--white)">
            Members
          </HeadingStyled>
        </Modal.Header>
        <Modal.Body>
          <Box className="d-flex align-items-center mb-3">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
          <Box className="d-flex align-items-center mb-3">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
          <Box className="d-flex align-items-center">
            <Box
              className="meeting-dropdown mx-2"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                alt="img"
                className="img-fluid"
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
              />
            </Box>
            <HeadingStyled
              size="14px"
              className=" mx-1 "
              color="var(--drColor)"
              weight={500}
            >
              Dell Alienware
            </HeadingStyled>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};
