import { Row } from "react-bootstrap";
import { avatarImg } from "../../Styled/AllImages";
import { RadioButton, SelectList } from "../../Styled/FormElements";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { ProgressGraph1 } from "../../Components/Dashboard/Pages/Projects/ProgressGraph1";
import {
  Box,
  HeadingStyled,
  SecondaryBarStyled,
  SpanStyled,
  TblGraphStyled,
  TextStyled,
  TextWithIcon,
} from "../../Styled/Elements";
import { PerformanceArray } from "../../Components/Dashboard/Pages/TilesArray";
import styled from "styled-components";

const rows = [
  {
    name: "Savannah Nguyen",
    costCenter: "Riyadh",
    jobTitle: "UI/UX Designer",
    department: "IOS Development",
    performance: "75%",
    ProgGraph: (
      <ProgressGraph1
        series={[75, 25]}
        colors={["var(--orange)", "var(--outlineC)"]}
      />
    ),
  },
  {
    name: "Media Channel",
    costCenter: "Jeddah",
    jobTitle: "Android Developer",
    department: "UI/UX",
    performance: "90%",
    ProgGraph: (
      <ProgressGraph1
        series={[90, 10]}
        colors={["var(--green2)", "var(--outlineC)"]}
      />
    ),
  },
];

const InventoryColumns = [
  {
    name: "name",
    label: "Name",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "costCenter",
    label: "Cost Center",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "jobTitle",
    label: "Job Title",
  },
  {
    name: "department",
    label: "Department",
  },
  {
    name: "performance",
    label: "Performance",
    render: (text, { performance, ProgGraph }) => (
      <TblGraphStyled title={performance} graph={ProgGraph} />
    ),
  },
];

export const Performance = () => {
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <SelectList
              options={["By Department", "Today"]}
              className="custom-select-design med-sel"
            />

            <SelectList
              options={["By Job Title", "Today"]}
              className="custom-select-design med-sel"
            />

            <SelectList
              options={["By Assigned Role", "Today"]}
              className="custom-select-design med-sel"
            />

            <SelectList
              options={["By Performance", "Today"]}
              className="custom-select-design med-sel"
            />
          </Box>
        </SecondaryBarStyled>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Performance
            </HeadingStyled>
          </Box>
        </SecondaryBarStyled>

        <Row className=" main-content px-row-10 mb-4">
          <Box className="d-flex flex-wrap gap-2">
            {PerformanceArray.map((item, key) => (
              <TiledWrapperStyled className="d-flex flex-column align-items-start border-bottom">
                <Box className="position-relative radio w-100">
                  <RadioButton
                    color="var(--grey)"
                    name="intExt"
                    className="position-absolute"
                    // label={"label"}
                    // value={item.value}
                    // checked={selectedInternalExternal === item.value}
                    // onChange={handleInternalExternalRadioButtonChange}
                  />
                </Box>
                <Box className="d-flex p-3">
                  <Box className="tile-icon" bg={item.bg}>
                    <SvgContainerStyled>
                      <item.icon width="24px" height="24px" />
                    </SvgContainerStyled>
                  </Box>
                  <Box>
                    <TextStyled
                      className="tile-title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    ></TextStyled>
                    <SpanStyled className="tile-count">{item.count}</SpanStyled>
                  </Box>
                </Box>

                <Box className="position-relative w-100 border-top d-flex align-items-baseline">
                  <Box className="pBarCompleted">
                    <SpanStyled className="pBarComTitle">
                      {item.percent}
                    </SpanStyled>
                  </Box>
                  <ProgressGraph1
                    series={[75, 25]}
                    colors={["var(--orange)", "var(--outlineC)"]}
                    width={120}
                    height={120}
                  />
                  <SpanStyled className="pBarTitle">{item.title}</SpanStyled>
                </Box>
              </TiledWrapperStyled>
            ))}
          </Box>
          <ListTable
            send="/performance/details"
            options={["send"]}
            idIndex={1}
            value="inventory-Edit"
            rows={rows}
            columns={InventoryColumns}
          />
        </Row>
      </Box>
    </>
  );
};

const TiledWrapperStyled = styled.div`
  background: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  width: 250px;
  .radio {
    height: 34px;
    padding-left: 16px;
  }
  .tile-title {
    font-size: 13px;
    color: var(--greyText) !important;
    line-height: 16px !important;
    margin-bottom: 4px;
  }
  .tile-count {
    font-size: 18px;
    color: var(--darkBlue);
    font-family: var(--semiBold);
    line-height: 18px;
  }
  .tile-icon {
    padding: 12px;
    border-radius: 12.0755px;
    margin-right: 10px;
  }
  .pBarCompleted {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 20px;
    bottom: 0;
    left: 33px;
    width: 60px;
    height: 60px;
    flex-direction: column;
  }
  .pBarTitle {
    position: absolute;
    font-family: var(--semiBold);
    font-size: 14px;
    color: var(--darkBlue);
    top: 32px;
    left: 127px;
  }
`;
const SvgContainerStyled = styled.div`
  svg {
    [stroke] {
      stroke: ${({ stroke }) => (stroke ? stroke : "")};
    }
  }
`;
