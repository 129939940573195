import { Col, Row } from "react-bootstrap";
import { Plus } from "../../Styled/AllImages";
import { Calendar } from "react-bootstrap-icons";
import { InputStyledWrap } from "../../Styled/styled";
import { ReceviedMeetingList } from "./ReceivedMeetingList";
import { InputWithIcon, SelectList } from "../../Styled/FormElements";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { MeetingArray } from "../../Components/Dashboard/Pages/TilesArray";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
} from "../../Styled/Elements";

export const ReceivedMeeting = () => {
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <SelectList
              options={["Select By Type", "Today"]}
              className="custom-select-design med-sel"
            />
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search by Date"
                icon={Calendar}
              />
            </InputStyledWrap>

            <SelectList
              options={["Select By Members", "Today"]}
              className="custom-select-design med-sel"
            />
            <InputStyledWrap>
              <InputWithIcon type="text" placeholder="Search by subject" />
            </InputStyledWrap>
            <Box className="boxRBt">
              <ButtonStyledMedium>Search</ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Received Meetings
            </HeadingStyled>
            <Box className="boxRBt">
              <ButtonStyledMedium>
                <Plus /> Schedule a Meeting
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <Box className="main-content">
          <Box>
            <Col xs={12}>
              <Row>
                {MeetingArray.map((item, key) => (
                  <Col
                    lg={3}
                    md={6}
                    xs={12}
                    key={item.title + key}
                    className=""
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Box>
          <ReceviedMeetingList />
        </Box>
      </Box>
    </>
  );
};
