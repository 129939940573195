import React from "react";
import Modal from "react-bootstrap/Modal";
import { DeleteIcon } from "../../Styled/AllImages";
import { Box, ButtonStyled, TextStyled } from "../../Styled/Elements";

export const DeleteEntry = ({ setModal, showModal, title }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setModal(false)}
      centered
      className="custom-action-modal"
    >
      <Modal.Header className="justify-content-center">
        <TextStyled
          className="mb-0 me-2"
          color="var(--darkBlue)"
          family="var(--semiBold)"
          size="16px"
        >
          Delete {title}
        </TextStyled>
        {/* <Box className='border-outlined-icon'>
					<DeleteIcon width={14} />
				</Box> */}
      </Modal.Header>
      <Modal.Body className="py-4 px-4">
        <TextStyled
          color="var(--darkBlue)"
          className="text-center mt-2"
          size="14px"
        >
          Are you sure you want to delete {title}
        </TextStyled>
        <Box className="d-flex align-items-center justify-content-center mb-3 pt-2 mt-4">
          <ButtonStyled
            bg="transparent"
            color="var(--grey)"
            onClick={() => setModal(false)}
            className="border-0 me-3 text-uppercase font-regular"
          >
            NO, Keep it
          </ButtonStyled>
          <ButtonStyled
            className="py-2 border-0 font-regular text-uppercase"
            bg="var(--red)"
            style={{ minWidth: "85px" }}
          >
            Yes, Delete
          </ButtonStyled>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
