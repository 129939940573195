import { Button, Modal } from "react-bootstrap";
import { HeadingStyled } from "../../Styled/Elements";

export const ReasonModal = ({
	showModal,
	onHide,
	heading,
	closeButton,
	children,
	buttons,
	size,
}) => {
	return (
		<Modal
			size={size ? size : "lg"}
			show={showModal}
			onHide={() => onHide(false)}
			centered
			scrollable={true}
		>
			{/* <Modal.Header closeButton={closeButton}>
				{heading && (
				<HeadingStyled size="18px" weight="600">
					{heading}
				</HeadingStyled>
				)}
			</Modal.Header> */}
			<Modal.Body>{children}</Modal.Body>
			{buttons && (
				<Modal.Footer>
					{buttons.map((button, index) => (
						<Button variant={button?.variant ? button.variant : "secondary"}>
							{button?.text}
						</Button>
					))}
				</Modal.Footer>
			)}
		</Modal>
	);
};
