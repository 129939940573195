import styled from "styled-components";

export const MultiUserRow = styled.div`
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
`;

export const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  display: flex;
  align-items: center;

  .UserUploadBtn {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    background-color: var(--greyBg);
    border-radius: 8px;
    overflow: hidden;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;

export const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 0;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .addListContent{
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    .listContentStRow{
        display: flex;
        width:100%;
        align-items: baseline;
        gap: 10px;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;


export const UserUploadClone = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  background-color: var(--greyBg);
  border-radius: 8px;
  .addListContent{
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    flex-direction: column;
    .listContentStRow{
        display: flex;
        width:100%;
        align-items: baseline;
        gap: 10px;
    }
  }
  svg.removeTaskTrigger{
    width: 14px;
    height: 14px;
    margin-left: 7px;
    &:hover{
        stroke: var(--red);
        fill: var(--red);
        path{
            stroke: var(--red);
        fill: var(--red);
        }
    }
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .abtUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      // width: 100%;
    }
  }
`;