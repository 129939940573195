import { Login } from "../Pages/Auth/Login/Login";
import { Signup } from "../Pages/Auth/SignUp/Signup";
import { SignupPlan } from "../Pages/Auth/SignupPlan/SignupPlan";
import { BankPlan } from "../Pages/Auth/SignupBank/BankPlan";
import { CardPlan } from "../Pages/Auth/SignupCard/CardPlan";
import { Otp } from "../Pages/Auth/SignupCodeGenerate/Otp";

export const AuthRoutes = [
  {
    path: "/",
    element: <Login />,
    exact: true,
  },
  {
    path: "/signup",
    element: <Signup />,
    exact: true,
  },
  {
    path: "/Subscription-Plan",
    element: <SignupPlan />,
    exact: true,
  },
  {
    path: "/Bank-Plan",
    element: <BankPlan />,
    exact: true,
  },
  {
    path: "/Card-Plan",
    element: <CardPlan />,
    exact: true,
  },
  {
    path: "/otp",
    element: <Otp />,
    exact: true,
  },
];
