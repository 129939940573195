import React, { useState } from "react";
import {
  Box,
  ButtonStyled,
  FormEleWrapper,
  TabsContainer,
  TabStyled,
} from "../../../Styled/Elements";
import { governancePhaseList } from "../../../Components/Dashboard/Pages/Projects/GovernancePhaseArray";
import { ArrowLeft, ArrowRightTc } from "../../../Styled/AllImages";
import styled from "styled-components";

export const Step3 = (props) => {
  const [activeTab, setActiveTab] = useState(governancePhaseList[0]);

  const handlePrev = (e) => {
    e.preventDefault();
    const currentIndex = governancePhaseList.findIndex(
      (item) => item.title === activeTab.title
    );
    const prevIndex = Math.max(0, currentIndex - 1);
    setActiveTab(governancePhaseList[prevIndex]);
  };

  const handleNext = (e) => {
    e.preventDefault();
    const currentIndex = governancePhaseList.findIndex(
      (item) => item.title === activeTab.title
    );
    const nextIndex = Math.min(
      governancePhaseList.length - 1,
      currentIndex + 1
    );
    setActiveTab(governancePhaseList[nextIndex]);
  };

  if (props.currentStep !== 3) {
    return null;
  }

  const currentIndex = governancePhaseList.findIndex(
    (item) => item.title === activeTab.title
  );
  const isFirstTab = currentIndex === 0;
  const isStartDateTab = currentIndex === 3;
  const isLastTab = currentIndex === governancePhaseList.length - 1;

  return (
    <>
      <FormEleWrapper>
        <Box className="formEleContainer">
          <TabsContainer>
            <Box className="tabsList">
              <TabStyled className="mb-0 list-unstyled d-flex justify-content-between">
                {governancePhaseList.map((item, key) => (
                  <li
                    className={` ${
                      item.title === activeTab.title ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(governancePhaseList[key])}
                    key={key}
                  >
                    {item.title}
                  </li>
                ))}
              </TabStyled>
            </Box>
            <activeTab.component />
            <Box className="formBtns w-100 justify-content-between">
              {!isFirstTab && (
                <ButtonStyled
                  onClick={handlePrev}
                  border="1px solid var(--darkBlue)"
                  color="var(--darkBlue)"
                  bg="var(--white)"
                >
                  <ArrowLeft /> &nbsp;Previous
                </ButtonStyled>
              )}
              {!isLastTab && (
                <ButtonStyled
                  className="ms-auto"
                  onClick={handleNext}
                  border="1px solid var(--themeColor)"
                  color="var(--themeColor)"
                  bg="var(--white)"
                >
                  Next &nbsp;
                  <ArrowRightTc />
                </ButtonStyled>
              )}
              {isStartDateTab && (
                <ButtonStyled
                  
                  size="14px"
                  border="1px solid var(--white)"
                  color="var(--white)"
                  bg="var(--green)"
                >
                  Send for Approval
                </ButtonStyled>
              )}
            </Box>
          </TabsContainer>
        </Box>
      </FormEleWrapper>
    </>
  );
};
