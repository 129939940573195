import React, { useState } from 'react'
import { AvatarGroup, Box, ButtonStyledMedium, HeadingStyled, LazyImage, SecondaryBarStyled, SpanStyled, TextStyled } from '../../Styled/Elements'
import { Badge, Dropdown, Modal } from 'react-bootstrap'
import { IconTitleStyled, InputWithIcon, SelectList } from '../../Styled/FormElements'
import { UsersListStyled } from '../../Components/Dashboard/Pages/chat/Styled'
import MessageTiles from './messageTiles'
import EmojiBox from '../../Components/Dashboard/Pages/chat/EmojiBox'
import MessagesUsersSidebar from './messageUserSidebar'
import { ChatWrapper } from './styled'
import { Hash, LogoutIcon, SearchIcon } from '../../Styled/AllImages'
import { InputStyledWrap } from '../../Styled/styled'
import CreateGroupForm from '../../Components/Dashboard/Pages/chat/createGroupForm'
import DirectMemberSelection from '../../Components/Dashboard/Pages/chat/directMemberSelect'
import GroupMemberSelection from '../../Components/Dashboard/Pages/chat/groupMemberSelection'

const Messages = () => {
    const imgUrl = 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80'

    const [modalShow, setModalShow] = useState(false)
    const [modalGroupShow, setModalGroupShow] = useState(false)
    const [modalDirectShow, setModalDirectShow] = useState(false)
    return (
        <>
            <SecondaryBarStyled>
                <Box className="secondaryBar">
                    <Box className='contact'>
                        <HeadingStyled className="mb-0" size="18px" weight="normal" color="var(--darkBlue)" family="var(--semiBold)">Contact</HeadingStyled>
                    </Box>
                    <Box className='contact-bar'>
                        <SelectList
                            options={["Select By Type", "Today"]}
                            className="custom-select-design med-sel"
                        />
                        <SelectList
                            options={["Select By Members", "Today"]}
                            className="custom-select-design med-sel"
                        />
                        <InputStyledWrap>
                            <InputWithIcon type="text" placeholder="Search by ID or Name" icon={SearchIcon} />
                        </InputStyledWrap>
                        <Box className="boxRBt">
                            <ButtonStyledMedium>Search</ButtonStyledMedium>
                        </Box>
                    </Box>
                </Box>
            </SecondaryBarStyled>
            <ChatWrapper className='d-flex h-100'>
                <Box className='h-100'>
                    <MessagesUsersSidebar setModalDirectShow={() => setModalDirectShow(true)} onModalShow={() => { setModalShow(true) }} />
                </Box>
                <Box className='h-100 position-relative'>
                    <Box className='card-styled-header border-bottom-custom'>
                        <Dropdown drop={'end'}>
                            <Dropdown.Toggle variant='' className='p-0 border-0' bsPrefix>
                                <IconTitleStyled className='chat-title' icon={Hash} headingSize={'16px'} title='Android App UX/UI Design' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='custom-scroll menu-customized border-0 dropdown-menu-end'>
                                <Box className='d-flex align-items-center justify-content-between'>
                                    <IconTitleStyled icon={Hash} headingSize={'16px'} title='PR-Managers' />
                                    <button className='exit-btn d-flex align-items-center justify-content-center py-1'>
                                        <SpanStyled color='var(--white)' size='12px'>Exit Group</SpanStyled>
                                        <LogoutIcon className='flip-item mx-2' width='14px' height='20px' />
                                    </button>
                                </Box>
                                <Box className='mt-2 ps-customized'>
                                    <SpanStyled size='12px' color='var(--greyText)'>
                                        Created
                                    </SpanStyled>
                                    <HeadingStyled className='mb-2' size='16px' weight={600}>
                                        14/3/2023 at 10:55
                                    </HeadingStyled>
                                    <TextStyled size='14px' lh='1.6'>
                                        Members of this group will have access to valuable resources, tips, and tools to help them excel in their role as purchase managers.
                                    </TextStyled>
                                </Box>
                                <hr />
                                <Box>
                                    <HeadingStyled className='mb-2' size='16px' weight={600}>
                                        Members
                                    </HeadingStyled>
                                    <UsersListStyled className='p-0 m-0' listPadding='0'>
                                        {[...Array(5)].map((list, key) => (
                                            <   li className={` direct-member`}
                                                key={`item-${key + 1}`}
                                            >
                                                <Box className='d-flex align-items-center'>
                                                    <Box className='active dot position-relative'>
                                                        <LazyImage src={imgUrl} width='40px' height='40px' className='rounded-circle' />
                                                    </Box>
                                                    <HeadingStyled size='14px' weight={500} className='ms-3' color='var(--drColor)'>
                                                        Cameron Williamson
                                                    </HeadingStyled>
                                                </Box>
                                                {key + 1 === 1 ?
                                                    <Badge bg='var(--lightBlue)' color='var(--themeColor)'>
                                                        Admin
                                                    </Badge>
                                                    : null}
                                            </li>
                                        ))}
                                    </UsersListStyled>
                                </Box>
                            </Dropdown.Menu>
                        </Dropdown>
                        <AvatarGroup add data={[1, 2, 3, 4, 5]} onAddAvatar={() => setModalGroupShow(true)} />
                    </Box>
                    <Box className='pb-3 chat-card position-relative flex-1 d-flex flex-column'>
                        <Box className='custom-scroll chat-body'>
                            <MessageTiles type='receiver' text='Hi, Abdullah any update today?' />
                            <MessageTiles type='sender' text='Here’s the new landing page design! Please check and provide your feedback' />
                            <MessageTiles type='receiver' text='Hi, Abdullah any update today?' />
                            <MessageTiles type='sender' text='Here’s the new landing page design! Please check and provide your feedback' />
                            <MessageTiles type='receiver' text='Cool!! i have some feedback on the “How it Works” section. but overall it looks good. Thanks' />
                            <MessageTiles type='sender' text='Thanks a lot, looking forward to your kind feedback. i will updated the “How it works” section as per your instructions' />
                        </Box>
                        <Box className='chat-footer'>
                            <EmojiBox />
                        </Box>
                    </Box>
                </Box>
            </ChatWrapper>
            <Modal className='custom-setting-modal' show={modalShow} centered onHide={() => { setModalShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <HeadingStyled size='18px' weight={600}>Create Group</HeadingStyled>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4 px-4'>
                    <CreateGroupForm />
                </Modal.Body>
            </Modal>
            {/* Select group users */}
            <Modal className='custom-setting-modal' show={modalGroupShow} centered onHide={() => { setModalGroupShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <HeadingStyled size='18px' weight={600}>Add Member</HeadingStyled>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4 px-0'>
                    <GroupMemberSelection />
                </Modal.Body>
            </Modal>
            {/* Direct Message user */}
            <Modal className='custom-setting-modal' show={modalDirectShow} centered onHide={() => { setModalDirectShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <HeadingStyled size='18px' weight={600}>Select Member</HeadingStyled>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4 px-0'>
                    <DirectMemberSelection />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Messages
