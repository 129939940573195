import styled from "styled-components";

export const UsersListStyled = styled.ul`
  li {
    padding: 7px ${({ listPadding }) => listPadding ? 0 : '1rem'};
    margin-top: 5px;
    cursor: pointer;
    &:hover, &.active {
        background: ${({ listPadding }) => listPadding ? 'transparent' : '#EFF3FF'};
    }
    .padding-l-25 {
        padding-left: 25px !important;
    }
    .radio-set ~ label{
        padding-bottom: 15px !important;
    }
    .dot {
        &.active:before {
            background: var(--green2);
        }
        &.deactive:before {
            background: var(--greyText);
        }
       &:before {
        content: '';
        border: 1px solid #F8F8F8;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        z-index: 1;
        position: absolute;
        right: -2px;
        top: 2px;
       }
    }
  }
`