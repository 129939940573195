import styled from "styled-components";

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
  }
`;
