
import { Box, LazyImage, SpanStyled, TextStyled } from "../../Styled/Elements";
import { MessageTileWrap } from "./styled";

const MessageTiles = ({ type, text }) => {
    const imgUrl = 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80'
    return (
        <MessageTileWrap className={`d-flex align-items-start mb-3 ${type} ${type == 'sender' ? 'flex-row-reverse' : ''}`}>
            <Box className={`${type == 'sender' ? 'ms-3' : 'me-3'}`}>
                <LazyImage className='rounded-circle' src={imgUrl} width='40px' height='40px' />
            </Box>
            <TextStyled size='14px' className='message-area'>
                {text}
                <SpanStyled size='12px' className={`d-block mt-1 pt-1 ${type == 'sender' ? 'text-start' : 'text-end'}`}>
                    5:06 PM
                </SpanStyled>
            </TextStyled>
        </MessageTileWrap>
    )
}



export default MessageTiles;