import { useState } from "react";
import Calendar from "react-calendar";
import { CustomModal } from "../../../Components/Modals/CustomModal";
import { calendar } from "../../../Styled/AllImages";
import { InputStyledWrap } from "../../../Styled/styled";
import { MembersDropDown } from "../../../Components/Dashboard/Pages/Projects/MemberDropDown";
import {
  Box,
  CalendarContainer,
  FormEleWrapper,
  LabelStyled,
} from "../../../Styled/Elements";
import {
  FileUploader,
  InputFloating,
  InputWithIcon,
  SelectList,
} from "../../../Styled/FormElements";

export const AssignTaskToTeam = ({
  showAssignTaskToTeamModal,
  setShowAssignTaskToTeamModal,
}) => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [date, onDataChange] = useState(new Date());

  return (
    <CustomModal
      showModal={showAssignTaskToTeamModal}
      onHide={() => setShowAssignTaskToTeamModal(false)}
      heading="Assign Task To Team"
      closeButton={true}
      buttons={[
        { text: "Save", variant: "primary" },
        { text: "Clear All", variant: "danger" },
      ]}
    >
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">
            Implementer Status
          </LabelStyled>
          <Box className="w-100">
            <MembersDropDown userLabel="Team" />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Duration</LabelStyled>
          <Box className="w-100">
            <LabelStyled className="labelForm pt-1">10 Days</LabelStyled>
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Start Date</LabelStyled>
          <Box className="w-100" onClick={() => setShowDateModal(true)}>
            <InputStyledWrap>
              <InputWithIcon type="text" placeholder="Monday" icon={calendar} />
            </InputStyledWrap>
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">End Date</LabelStyled>
          <Box className="w-100">
            <LabelStyled className="labelForm pt-1">-</LabelStyled>
          </Box>
        </Box>
      </FormEleWrapper>

      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Implementor</LabelStyled>
          <Box className="w-100">
            <LabelStyled className="labelForm pt-1">Team</LabelStyled>
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Team Leader</LabelStyled>
          <Box className="w-100">
            <SelectList options={[]} placeholder="Select Team Leader" />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Members</LabelStyled>
          <Box className="w-100">
            <SelectList options={[]} placeholder="Select Members" />
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-1">Other Info</LabelStyled>
          <Box className="w-100">
            <Box className=" pb-2 textarea-field-lg">
              <InputFloating
                row={5}
                textarea
                type="textarea"
                name={"technical_aspect"}
                placeholder="Write Additional Info"
              />
            </Box>
          </Box>
        </Box>
      </FormEleWrapper>
      <FormEleWrapper>
        <Box className="formEleContainer align-items-start">
          <LabelStyled className="labelForm pt-2">Attachment</LabelStyled>
          <Box className="w-100">
            <FileUploader />
          </Box>
        </Box>
      </FormEleWrapper>

      <CustomModal
        showModal={showDateModal}
        onHide={() => setShowDateModal(false)}
        heading="Task Timeline"
        closeButton={true}
        buttons={[
          { text: "Ok", variant: "primary" },
          { text: "Cancel", variant: "danger" },
        ]}
        size="sm"
      >
        <CalendarContainer>
          <Calendar onChange={onDataChange} value={date} />
        </CalendarContainer>
      </CustomModal>
    </CustomModal>
  );
};
