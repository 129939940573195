import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AddUserIcon } from "./AllImages";
import { sliceProps } from "./FormElements";
import { Dropdown } from "react-bootstrap";



export const Box = styled.div`
  background-color: ${({ bg }) => (bg ? bg : "")};
`;
// export const SvgContainerStyled = styled.div`
//   background-color: ${({ iColor }) => (iColor ? iColor : "")};
// `;


export const IconBoxColor = styled.div`
  svg {
    [stroke] {
      stroke: ${({ iconColor }) => (iconColor ? iconColor : "")};
    }
  }
`;

export const ContainerWapper = styled.div`
  display: flex;
  height: ${(props) => !props.noHeight && "100vh"};
`;

export const LeftBox = styled.div`
  width: 80%;
  display: flex;
  // height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const LazyImage = (props) => {
	return <LazyLoadImage {...props} effect="blur" />;
};
export const HeadingStyled = styled.div`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) => (props.family ? props.family : "var(--bold)")};
  color: ${(props) => (props.color ? props.color : "var(--darkBlue)")};
  line-height: ${(props) => (props.lh ? props.lh : "normal")};
`;
export const HeadingStyledDB = styled.div`
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) => (props.family ? props.family : "var(--bold)")};
  color: ${(props) => (props.color ? props.color : "var(--darkBlue)")};
  line-height: normal;
`;
export const SpanStyled = styled.span`
  background-color: ${({ bg }) => (bg ? bg : "")};
  border: ${({ border }) => (border ? border : "")};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) => (props.family ? props.family : "")};
  color: ${(props) => (props.color ? props.color : "")};
  font-style: ${(props) => (props.fstyle ? props.fstyle : "")};
  line-height: ${(props) => (props.lheight ? props.lheight : "")};
`;
export const FormEleWrapper = styled.div`
  padding: 9px 20px 9px 20px;
  border-bottom: 1px solid var(--outlineC);
  width: 100%;
  .formEleContainer {
    display: flex;
    padding: 6px 0px 6px 0px;
    margin: 6px 0 6px 0;
    align-items: center;
    gap: 10px;
    & > label {
      min-width: 160px;
    }
  }
  .formEleContainer.inp-white {
    input {
      background-color: var(--white);
    }
  }
  .form-floating {
    width: 100%;
  }
  input[type="text"] {
    width: 100%;
  }
`;

export const TextStyled = styled.p`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arRegular)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--regular)"};
  color: ${(props) => (props.color ? props.color : "var(--greyText)")};
  line-height: ${(props) => (props.lh ? props.lh : "normal")};
`;
export const LabelStyled = styled.label`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  color: ${(props) => (props.color ? props.color : "var(--grey)")};
  line-height: ${(props) => (props.lh ? props.lh : "22px")};
  font-family: ${(props) =>
		// props.direction === "rtl" && props.family
		//   ? props.family
		//   : "var(--arMedium)"},
		// ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--medium)"};
`;

export const TextWithIcon = (props) => (
	<TextWithStyledWrapper
		color={props.color}
		className={`d-flex ${props.desc ? "align-items-start" : "align-items-center"
			}`}
	>
		<Box className="me-2 d-flex align-items-center justify-content-center">
			<props.icon width="20px" height="20px" />
		</Box>
		<Box>
			<TextStyled
				className="mb-0"
				weight={400}
				size={props.headingSize ? props.headingSize : "14px"}
				color={!props.alsotextColor ? "var(--greyText)" : "var(--themeColor)"}
				dangerouslySetInnerHTML={{ __html: props.title }}
			></TextStyled>
			{props.desc ? (
				<SpanStyled
					className="text-uppercase"
					color={"var(--greyText)"}
					size="12px"
					weight={300}
				>
					{props?.desc}
				</SpanStyled>
			) : null}
		</Box>
	</TextWithStyledWrapper>
);
export const BoxCheck = (props) => {
	const unwantedProps = ["BoxCheckClass"];
	return (
		<BoxChecked {...sliceProps(props, unwantedProps)} className={`box-check ${props.BoxCheckClass || ''}`}>

		</BoxChecked>
	);
};
export const TblGraphStyled = ({ headingSize, title, graph }) => (
	<Box className="position-relative">
		<FixedGraph>
			<PercentStyled
				className="tblPercent"
				family="var(--medium)"
				size={headingSize ? headingSize : "11px"}
				color="var(--grey)"
				dangerouslySetInnerHTML={{ __html: title }}
			></PercentStyled>
			<SpanStyled>{graph}</SpanStyled>
		</FixedGraph>
	</Box>
);
export const AvatarGroup = ({ data, add, onAddAvatar, dialog, width, height }) => {
	return (
		<AvatarGroupContainer className="p-0 list-unstyled mb-0 d-flex align-items-center" customWidth={width} customHeight={height}>
			{data.map((item, key) => (
				<li key={`user-${key + 1}-${item.name}`}>
					<img
						alt={item.name}
						title={item.name}
						src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
					/>
				</li>
			))}
			<li onClick={onAddAvatar} className='d-flex avatarIcon messages'>
				{add ? <AddUserIcon height='14px' /> : 2}
			</li>
			{dialog ? (
				<li className="d-flex avatarIcon meeting" onClick={() => dialog()}>
					{add ? <AddUserIcon height="14px" /> : 2}
				</li>
			) : (
				<Dropdown>
					<Dropdown.Toggle
						bsPrefix
						className="bg-transparent active-dot position-relative border-0 p-0"
					>
						{/* <li className="cursor-pointer bg-blue text-white d-flex align-items-center justify-content-center">
              {add ? <AddUserIcon height="14px" /> : 2}
            </li> */}
					</Dropdown.Toggle>
					<Dropdown.Menu
						className="dropdown-menu-end border"
						style={{
							width: "300px",
							boxShadow: "0px 18px 10px 0px rgba(0, 0, 0, 0.15)",
						}}
					>
						<Dropdown.Item
							href="#/action-1"
							className="d-flex align-items-center"
						>
							<Box
								className="meeting-dropdown"
								style={{ width: "30px", height: "30px" }}
							>
								<img
									alt="img"
									className="img-fluid"
									src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
								/>
							</Box>
							<HeadingStyled
								size="14px"
								className=" mx-1 "
								color="var(--drColor)"
								weight={500}
							>
								Dell Alienware
							</HeadingStyled>
						</Dropdown.Item>
						<Dropdown.Item
							href="#/action-1"
							className="d-flex align-items-center"
						>
							<Box
								className="meeting-dropdown"
								style={{ width: "30px", height: "30px" }}
							>
								<img
									alt="img"
									src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
								/>
							</Box>
							<HeadingStyled
								size="14px"
								className="mx-1"
								color="var(--drColor)"
								weight={500}
							>
								Dell Alienware
							</HeadingStyled>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</AvatarGroupContainer>
	);
};
export const BadgeBtn = (props) => {
	const unwantedProps = ["label"];
	return (
		<BadgeBtnWrapper {...sliceProps(props, unwantedProps)} type="button">
			{props.label}
		</BadgeBtnWrapper>
	);
};
export const StateBtn = (props) => {
	const unwantedProps = ["label"];
	return (
		<StateButton {...sliceProps(props, unwantedProps)} color={props.color}>
			<SpanStyled size="14px" color="var(--white)">
				{props.label}
			</SpanStyled>
		</StateButton>
	);
};
export const SecondaryHeading = (props) => {
	const unwantedProps = ["label"];
	return (
		<SecondaryBarHeading {...sliceProps(props, unwantedProps)}>
			{props.label}
		</SecondaryBarHeading>
	);
};
export const SecondaryBarHeading = styled.div`
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) => (props.family ? props.family : "var(--semiBold)")};
  color: ${(props) => (props.color ? props.color : "var(--darkBlue)")};
  line-height: ${(props) => (props.lh ? props.lh : "30px")};
`;
const StateButton = styled.div`
display: flex;
align-items: center;
width: fit-content;
  border-radius: 4px;
  padding: 2px 8px;
  border: none;
  background-color: ${(props) => (props.color ? props.color : "var(--themeColor)")};
`;
const BoxChecked = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 18px;
    height: 18px;
    background-color: var(--white);
    border: 1px solid var(--grey);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    &:after{
        content: '';
        width: 14px;
        height: 14px;
        background-color: var(--grey);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    &.boxThemeColor{
      border: 1px solid var(--themeColor);
      &:after{
          background-color: var(--themeColor);
      }
    }
    &.boxGrey{
      border: 1px solid var(--grey);
      &:after{
          background-color: var(--grey);
      }
    }
    &.boxLightGrey{
      border: 1px solid var(--outlineGreyBG);
      &:after{
          background-color: var(--outlineGreyBG);
      }
    }
    &.boxThemeColor{
      border: 1px solid var(--themeColor);
      &:after{
          background-color: var(--themeColor);
      }
    }
    &.boxGreen{
      border: 1px solid var(--green2);
      &:after{
          background-color: var(--green2);
      }
    }
    &.boxRed{
      border: 1px solid var(--red);
      &:after{
          background-color: var(--red);
      }
    }
`;
const BadgeBtnWrapper = styled.button`
  border: 1px solid ${({ color }) => color || "var(--themeColor)"};
  color: ${({ color }) => color || "var(--themeColor)"};
  background: ${({ bg }) => bg || ""};
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 15px;
`;

const AvatarGroupContainer = styled.ul`
  li {
    width: ${({ customWidth, width }) => customWidth || width || "30px"};
    height: ${({ customHeight, height }) => customHeight || height || "30px"};
    border-radius: 50%;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border: 2px solid #fff;
    }
    &:nth-child(n + 2) {
      margin-left: -15px;
    }
    &.bg-green {
      border: 2px solid #fff;
    }
  }
  &:hover li:nth-child(n + 2) {
    margin-left: 0px;
  }
`;

export const FixedGraph = styled.div`
  // position: absolute;
  // top: -20px;
`;

export const PercentStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arRegular)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--regular)"};
  color: ${(props) => (props.color ? props.color : "var(--greyText)")};
  line-height: ${(props) => (props.lh ? props.lh : "normal")};
`;

export const ButtonStyled = styled.button`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arMedium)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--medium)"};
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
  color: ${(props) => (props.color ? props.color : "var(--white)")};
  padding: ${(props) => (props.padding ? props.padding : "8px 20px")};
  border: ${(props) => (props.border ? props.border : "none")};
  width: ${(props) => (props.width ? props.width : "100%")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  border-radius: 8px;
  transition: 0.3s ease-in-out opacity;
  letter-spacing: 0.8px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonStyledMedium = styled.button`
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arMedium)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--medium)"};
  background-color: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
  color: ${(props) => (props.color ? props.color : "var(--white)")};
  padding: ${(props) => (props.padding ? props.padding : "8px 12px")};
  border: ${(props) => (props.border ? props.border : "none")};
  width: ${(props) => (props.width ? props.width : "auto")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  border-radius: 6px;
  transition: 0.3s ease-in-out opacity;
  letter-spacing: 0.8px;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonStyledDiv = styled.div`
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arMedium)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--medium)"};
  background-color: ${(props) => (props.bg ? props.bg : "var(--white)")};
  color: ${(props) => (props.color ? props.color : "var(--grey)")};
  padding: ${(props) => (props.padding ? props.padding : "8px 15px 8px 15px")};
  border: ${(props) =>
		props.border ? props.border : "1px solid var(--outlineGreyBG)"};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  border-radius: 6px;
  transition: 0.3s ease-in-out opacity;
  letter-spacing: 0.8px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.8;
  }
  &.btn-add-files{
    &:hover{
      background-color: var(--themeColor);
      color: var(--white);
      svg {
        
          path {
            fill: var(--theme-danger);
          }
          [stroke] {
            stroke: var(--white);
          }
      }
    }
  }
`;

export const ButtonStyledSmall = styled.button`
  font-size: ${(props) => props.size || "12px"};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arRegular)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--regular)"};
  background-color: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
  color: ${(props) => (props.color ? props.color : "var(--white)")};
  padding: ${(props) => (props.padding ? props.padding : "4px 8px 6px 8px")};
  border: ${(props) => (props.border ? props.border : "none")};
  width: ${(props) => (props.width ? props.width : "auto")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "12px")};
  border-radius: 4px;
  transition: 0.3s ease-in-out opacity;
  letter-spacing: 0.8px;
  &:hover {
    opacity: 0.9;
  }
`;

export const SecondaryBarStyled = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  padding: 12px 18px;
  border-bottom: 1px solid var(--outlineC);
  top: 76px;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  // height: 68px;
  z-index: 100;
  transition: 0.3s;
`;
export const DataContainerStyled = styled.div`
  background: var(--white);
  // box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  // border-radius: 15px;
  padding: ${(props) => (props.padding ? props.padding : "20px 20px")};
`;
export const InputStyled = styled.input`
  border: none;
  background-color: var(--greyBg);
  border-radius: 10px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  &,
  &::placeholder {
    color: var(--greyText);
    font-size: 12px;
    font-weight: 400;
  }
`;
export const ListTileStyled = styled.div`
  background: ${(props) => props.bg || "rgb(122 187 181 / 15%)"};
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 60px;
  margin-bottom: 20px;
  max-width: 689px;
  svg {
    cursor: pointer;
    &.fill-danger {
      path {
        fill: var(--theme-danger);
      }
    }
  }
`;

export const BorderSeprator = styled.div`
  border-bottom: 1px solid var(--themeColor);
  position: relative;
  &:before {
    content: attr(data-content);
    position: absolute;
    left: calc(50% - 27.5px);
    top: -17px;
    padding: 5px 18px;
    font-size: 14px;
    background: transparent;
    color: var(--themeColor);
  }
`;
export const ButtonChange = styled.button`
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  font-family: ${(props) =>
		props.direction === "rtl" && props.family
			? props.family
			: "var(--arMedium)"},
    ${(props) =>
		props.direction === "ltr" && props.family
			? props.family
			: "var(--medium)"};
  background-color: ${(props) => (props.bg ? props.bg : "var(--greyBg)")};
  color: ${(props) => (props.color ? props.color : "")};
  padding: 0 10px;
  border: none;
  border-radius: 8px;
  transition: 0.3s ease-in-out opacity;
  letter-spacing: 0.8px;
  &:hover {
    opacity: 0.8;
  }
`;

const TextWithStyledWrapper = styled.div`
  svg {
    [stroke] {
      stroke: ${({ color }) => (color ? color : "")};
    }
  }
`;

export const CardStyled = styled.div`
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  background-color: ${(props) => (props.bg ? props.bg : "var(--white)")};
  .card-styled-header {
    padding: 16px;
    border-bottom: 1px solid var(--grey-light);
    margin-bottom: 16px;
  }
`;

export const CalendarContainer = styled.div`
  width: 100%;
  .react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    line-height: 1.125em;
  }
`;

export const SvgContainerSmallStyled = styled.div`
  display: flex;
  align-items: center;
  .tile-icon {
    width: 35px;
    height: 35px;
    background-color: ${(props) => (props.bg ? props.bg : "#E6F8EE")};
    border-radius: 6px;
  }
  svg {
    [stroke] {
      stroke: ${({ stroke }) => (stroke ? stroke : "")};
    }
  }
`;

export const BackButtonWrapper = styled.div`
  button {
    &.icon-box {
      border: 1px solid #f1f1f1;
      // width: 28px;
      // height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin-right: 13px;
    }
  }
`;

export const PillStyled = styled.div`
  border: none;
  background-color: var(--greyBg);
  border-radius: 8px;
  height: 43px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 9px;
  color: ${({ color }) => (color ? color : "")};
  span {
    padding-left: 9px;
    color: #92929d;
  }
`;

export const MainContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
`;
// export const MainContainerChat = styled.div`
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
//   background-color: var(--white);
// `;
export const Container = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-right: ${(props) => (props.borderRight ? props.borderRight : "none")};
`;

export const ImageIcon = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 11px;
  background-color: var(--white);
  box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.25);
  .tabsList {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 17px 0 17px 0;
    border-bottom: 1px solid var(--outlineGreyBG);
  }
`;

export const TabStyled = styled.ul`
  gap: 50px;
  li {
    // width: 50%;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    &:not(.active) {
      color: var(--greyText);
    }
    &.active,
    &:hover {
      color: var(--themeColor);
      font-family: var(--medium);
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -17px;
        width: 100%;
        height: 2px;
        border-radius: 20px;
        background-color: var(--themeColor);
      }
    }
  }
`;

export const ChartBoxStyled = styled.div`
  background-color: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  height: 460px;
  padding: 25px;
`;
export const PieBoxStyled = styled.div`
  background-color: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  height: 100%;
  padding: 25px;
`;

export const DateRangePickWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  	.rangeInner{
		position: relative;
		.react-datepicker-popper{
			transform: translate(-80px, 23px) !important;
			//-ms-transform: translate(-50%, 23px) !important;
			//transform: translate(-50%, 23px) !important;
		}
		.react-datepicker{
			.react-datepicker__triangle{
				//-ms-transform: translate(130px , -0px) !important;
				transform: translate(136px , -0px) !important;
				&::before{
					border-bottom-color: #aeaeae !important;
				}
				&::after{
					border-bottom-color: var(--white) !important;
				}
			}
			// .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
			// 	border-bottom-color: var(--white) !important;
			// }
			// .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
			// 	border-bottom-color: #000 !important;
			// }
		}
	}
  .react-datepicker-wrapper{
	width: 95px;
	
	
	input{
		width: 100%;
		color: var(--themeColor);
		font-size: 14px;
	}
  }
`;


export const Dropdownicon = styled.div`
  cursor: pointer;
`
export const RotatedDropdownIcon = styled(Box)`
  cursor: pointer;
  transform: rotate(-90deg);
`;
export const RadioPositionStyled = styled.div`
  [type="radio"]:not(:checked)+label:before, [type="radio"]:checked+label:before {
    left: 2px;
    top: -16px;
  }
  [type="radio"]:checked+label:after, [type="radio"]:not(:checked)+label:after {
    top: -8px;
    left: 8px;
  }
`
export const Pill = styled.div`
  border: none;
  background-color: ${(props) => (props.color ? props.color : "#e4e5e7")};
  // height: 43px;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 10px;
  text-transform: uppercase;
  
  span {
    color: ${(props) => (props.textColor ? props.textColor : "#92929d")};
  }
`;