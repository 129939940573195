import { Calendar2, Clock, Grid, List } from "react-bootstrap-icons";
import { Board } from "./Board";
import { MyCalendar } from "./Calendar";
import { ListView } from "./ListView";
import { MyTimeline } from "./Timeline";


export const TasksArray = [
  {
    title: `List View`,
    component: ListView,
    icon: <List width="18px" height="18px" />,
  },
  {
    title: `Board`,
    component: Board,
    icon: <Grid width="18px" height="18px" />,
  },
  {
    title: `Calendar`,
    component: MyCalendar,
    icon: <Calendar2 width="18px" height="18px" />,
  },
  {
    title: `Timeline`,
    component: MyTimeline,
    icon: <Clock width="18px" height="18px" />,
  },
];
