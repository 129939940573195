import React, { useState } from 'react';
import { ClockGrey } from "../../../Styled/AllImages";
import styled from "styled-components";
import { TimePicker } from 'react-ios-time-picker';


export const TimePick = () => {
	//const [isBodyClassAdded, setIsBodyClassAdded] = useState(false);
	const [value, setValue] = useState('10:00 AM');

   const onChange = (timeValue) => {
      setValue(timeValue);
	  document.body.classList.remove('no-scroll');
   }

//    const handleInputClick = () => {
//     // Add the class to the body when the input is clicked
//     document.body.classList.add('your-body-class'); // Replace 'your-body-class' with your desired class name
//   };

	const handleOpen = () => {
		document.body.classList.add('no-scroll');
	};

	const handleClose = () => {
		document.body.classList.remove('no-scroll');
	};
   
	return (
		<TimePickerWrapper>
			<ClockGrey />
			<TimePicker onChange={onChange} value={value} use12Hours={true}/>
		</TimePickerWrapper>
	);
};

const TimePickerWrapper = styled.div`
position: relative;
input{
	font-size: 14px;
  color: var(--greyText);
  border: none;
  background-color: var(--greyBg);
  border-radius: 8px;
  height: 43px;
  padding-right: 18px;
  outline: none;
  width: 100%;
  padding-left: 45px;
}
svg {
	left: 13px;
  top: 12px;
  height: 20px;
  position: absolute;
	[stroke] {
	  stroke: var(--grey);
	}
	[fill] {
	  fill: var(--grey);
	}
  }
`
