import { Link } from "react-router-dom";
import { Box, SpanStyled } from "../../../../Styled/Elements";
import { ButtonStyledMedium, LazyImage } from "../../../../Styled/Elements";
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  DollarIcon,
} from "../../../../Styled/AllImages";
import { Send } from "react-bootstrap-icons";

export const ActionButtons = ({
  send,
  onView,
  onEdit,
  onSetting,
  onDelete,
  options,
  index,
  value,
  onButton,
  type
}) => {
  return (
    <ul className="d-flex list-unstyled d-flex align-items-center mb-0 actionsList">
      {options && options.includes("view") && (
        <li>
          <Link
            to={type ? `/${type}/${index}` : "#"}
            {...(onView && { onClick: onView })}
            type="button"
            className="border-0 bg-transparent text-decoration-none"
          >
            <EyeIcon width="20px" />
          </Link>
        </li>
      )}
      {/* {options && options.includes('download') && (
                <li className='me-2'>
                    <button type='button' className='border-0 bg-transparent'>
                        <DownloadIcon width='20px' style={{ marginTop: '-6px' }} />
                    </button>
                </li>
            )} */}
      {options && options.includes("send") && (
        <li>
          <Link
            to={send ? send : "#"}
            type="button"
            className="border-0 bg-transparent text-decoration-none"
          >
            <EyeIcon width="20px" />
          </Link>
        </li>
      )}
      {options && options.includes("dollar") && (
        <li>
          <button
            type="button"
            {...(onSetting && { onClick: onSetting })}
            className="border-0 bg-transparent"
          >
            <DollarIcon width="20px" />
          </button>
        </li>
      )}
      {options && options.includes("button") && (
        <li>
          <ButtonStyledMedium
            className="py-1 px-3"
            size="12px"
            type="button"
            {...(onButton && { onClick: onButton })}
          >
            Start Meeting
          </ButtonStyledMedium>
        </li>
      )}
      {options && options.includes("edit") && (
        <li>
          <Link
            to={value ? `/${value}/${index}` : "#"}
            {...(onEdit && { onClick: onEdit })}
            type="button"
            className="border-0 bg-transparent text-decoration-none"
          >
            <EditIcon width="20px" />
          </Link>
        </li>
      )}
      {options && options.includes("delete") && (
        <li>
          <button
            type="button"
            {...(onDelete && { onClick: onDelete })}
            className="border-0 bg-transparent"
          >
            <DeleteIcon width="20px" />
          </button>
        </li>
      )}
    </ul>
  );
};

export const UserAvatar = ({ text, url }) => (
  <Box className="d-flex align-items-center">
    <LazyImage
      src={url}
      width="30px"
      height="30px"
      className="rounded-circle"
    />
    <SpanStyled weight="700" size="12px" className="ms-2">
      {text}
    </SpanStyled>
  </Box>
);
