import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { SingleProfile, Add } from "../../../../../Styled/AllImages";
import { Box, SpanStyled } from "../../../../../Styled/Elements";
import { CustomCheckbox } from "../../../../../Styled/FormElements";
import { UserUpload, DropListRow } from './styledTaskList';

const DataWrapper = ({
	mainParents,
	onMainParentClick,
	selectedMainParents,
	props,
}) => {

	return (
		<UserUpload>
			<Dropdown>
				<Dropdown.Toggle
					bsPrefix
					className="bg-transparent position-relative border-0 p-0 UserUploadBtn"
				>

					<Box className="userPlaceholder">
						<Add />
					</Box>

				</Dropdown.Toggle>
				<Dropdown.Menu className="dropMNListContainer dropLeft">
					{mainParents.map((mainParent) => (
						<Dropdown.Item
							href="#!"
							className={`p-0 ${selectedMainParents.some(
								(selected) => selected.id === mainParent.id
							)
								? "selected"
								: ""
								}`}
							key={mainParent.id}
							onClick={() => onMainParentClick(mainParent)}
						>
							<DropListRow className="dropChild">
								<Box className="dropRadioContainer">
									<CustomCheckbox
										name="members"
										//value={props.value}

										checked={selectedMainParents.some(
											(selected) => selected.id === mainParent.id
										)}
										as="div"
									// key={mainParent.id}
									// onClick={() => onMainParentClick(mainParent)}
									/>
									{/* readOnly */}
								</Box>
								<Box className="addListContent">
									<Box className="listContentStRow">
										<SpanStyled className="listContentDt" color="var(--grey)" size="12px" lheight="18px">
											{mainParent.taskDate}
										</SpanStyled>
										<SpanStyled color="var(--grey)" size="18px" lheight="18px">
											.
										</SpanStyled>
										<SpanStyled color="var(--grey)" size="15px" lheight="18px">
											{mainParent.taskName}
										</SpanStyled>
									</Box>
									<SpanStyled color="var(--greyText)" size="10px" >
										{mainParent.taskID}
									</SpanStyled>
								</Box>


							</DropListRow>

							<Dropdown.Divider />
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</UserUpload>
	);
};

export default DataWrapper;

