import styled from "styled-components";
import { Box } from "../../../../Styled/Elements";
import { ActionButtons } from "./tableComponent";
import { useDispatch } from "react-redux";
import { ActionsStyled } from "./styled";
export const ListTable = ({
  columns,
  rows,
  type,
  send,
  onSetting,
  onEdit,
  onView,
  onDelete,
  options,
  idIndex,
  deleteApi,
  value,
  onButton,
}) => {
  const dispatch = useDispatch();

  return (
    <Box className="table-responsive custom-scroll overflow-x-auto">
      <TableStyled className="w-100 table">
        <thead>
          <tr>
            {columns.map((list, key) => (
              <th className="text-uppercase" key={key}>
                {list.label}
              </th>
            ))}

            {options && options.length > 0 && (
              <th className="text-uppercase" style={{ width: "150px" }}>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((list, key) => (
            <tr key={key}>
              {columns.map(({ name, render }, key) =>
                name === "actions" ? (
                  <ActionsStyled key={key}>
                    {render ? render(list[name], list, dispatch) : list[name]}
                  </ActionsStyled>
                ) : (
                  <td key={key}>
                    {render ? render(list[name], list, dispatch) : list[name]}
                  </td>
                )
              )}

              {options && options.length > 0 && (
                <td>
                  {
                    <ActionButtons
                      onSetting={onSetting}
                      onButton={onButton}
                      onEdit={onEdit}
                      {...(onView && { onView: () => onView(list) })}
                      onDelete={onDelete}
                      deleteApi={deleteApi}
                      type={type}
                      options={options}
                      index={idIndex}
                      value={value}
                      send={send}
                    />
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </TableStyled>
    </Box>
  );
};

const TableStyled = styled.table`
  white-space: nowrap;
  th {
    color: var(--darkBlue);
    padding: 13px 12px;
    border: none;
    font-family: var(--semiBold);
    color: var(--grey-color);
    font-size: 12px;
    background-color: var(--greyBg);
  }
  tbody {
    tr {
      td {
        position:relative;
        color: var(--grey);
        font-size: 14px;
        padding: 10px 12px;
        border-bottom: 1px solid var(--outlineC);
        vertical-align: middle;
        .tblDbMdText {
          font-family: var(--medium);
          color: var(--darkBlue);
        }
      }
      &:last-child td {
        border-bottom: none;
      }
    }
  }
  tfoot {
    td {
      // border-top: 1px solid var(--grey-light);
    }
  }
`;
