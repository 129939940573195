import { Col } from "react-bootstrap";
import { SignupForm } from "./SignupForm";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../Components/Footer/Footer";
import { LandingWrapper } from "../../../Styled/FormElements";
import { SiteLogo, BackArrow } from "../../../Styled/AllImages";
import {
  Box,
  ButtonStyled,
  ContainerWapper,
  HeadingStyled,
  LeftBox,
  SpanStyled,
} from "../../../Styled/Elements";

export const CardPlan = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo mb-4">
            <BackArrow
              className="back_arrow"
              onClick={() => navigate("/Subscription-Plan")}
            />
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="36px" weight="bold" className="mb-3">
              Signup With
              <SpanStyled color="var(--themeColor)" size="36px" weight="bold">
                PTM
              </SpanStyled>
            </HeadingStyled>
            <Box>
              <HeadingStyled size="24px" weight="bold" className="mt-3">
                Pay through card
              </HeadingStyled>
            </Box>
            <Box>
              <SpanStyled color="var(--grey)" className="mb-3">
                Please enter the card info below and complete sign up process
              </SpanStyled>
            </Box>
            <SignupForm />
            <ButtonStyled className="my-4" onClick={() => navigate("/")}>
              <SpanStyled>Pay</SpanStyled>
            </ButtonStyled>
          </LeftBox>
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Efficiently organize tasks, collaborate seamlessly, and boost productivity with our intuitive and powerful task management tool. Simplify your workflow today! a new level of project organization. Join our Task Management platform today."
          />
        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
