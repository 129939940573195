import { Box } from "../../../Styled/Elements";
import "../../../Assets/styles/progressBar.scss";
import { ProgressBar, Step } from "react-step-progress-bar";

export const MultiStepProgressBar = (props) => {
  const stepText = [
    "Start Phase",
    "Planning Phase",
    "Governance Phase",
    "Execution Phase",
  ];
  let stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 34;
  } else if (props.currentStep === 3) {
    stepPercentage = 67;
  } else if (props.currentStep === 4) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    // <ProgressBar percent={stepPercentage}>
    //   {[1, 2, 3, 4].map((index) => (
    //     <Step key={index}>
    //       {({ accomplished }) => (
    //         <div
    //           className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
    //         >
    //           {index}
    //         </div>
    //       )}
    //     </Step>
    //   ))}
    // </ProgressBar>
    // <ProgressBar percent={stepPercentage}>
    // 	{[1, 2, 3, 4].map((index) => (
    // 		<Step key={index}>
    // 		{({ accomplished }) => (
    // 			<div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
    // 			Step {index}: {index}
    // 			</div>
    // 		)}
    // 		</Step>
    // 	))}
    // </ProgressBar>
    <ProgressBar percent={stepPercentage}>
      {[1, 2, 3, 4].map((index) => (
        <Step key={index}>
          {({ accomplished }) => (
            <Box
              className={`indexedStep ${accomplished ? "accomplished" : ""}`}
            >
              <Box className="indexedCount">{index}</Box>
              <Box className="stepTitle">{stepText[index - 1]}</Box>
            </Box>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};
