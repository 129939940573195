import { useState } from "react";
import styled from "styled-components";
import { profile2, Dropdown } from "../../../Styled/AllImages";
import { ArrowDownCircle } from "react-bootstrap-icons";
import { ImageUploader } from "../../../Styled/FormElements";
import { CustomModal } from "../../../Components/Modals/CustomModal";
import { ListTable } from "../../../Components/Dashboard/Pages/Tables/ListTable";
import {
	Box,
	FormEleWrapper,
	HeadingStyled,
	LabelStyled,
	SecondaryBarStyled,
	SecondaryHeading,
	SpanStyled,
	BoxCheck,
	TextStyled,
	StateBtn,
	Dropdownicon,
	RotatedDropdownIcon,
	Pill,
	RadioPositionStyled,
} from "../../../Styled/Elements";
import { SecondaryBar } from "../../../Components/Dashboard/Pages/Reports/SecondaryBar";

const InventoryColumns = [
	{
		name: "projectName",
		label: "Task Name",
	},
	{
		name: "Start",
		label: "Start Date",
	},
	{
		name: "Duration",
		label: "Duration",
	},
	{
		name: "End",
		label: "End Date",
		render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
	},
	{
		name: "delivery",
		label: "Delivery",
	},
	{
		name: "deviation",
		label: "Deviation",
	},
];

export const ProjectTeams = () => {
	const [showModal, setModal] = useState(false);
	const [tableVisibility, setTableVisibility] = useState({
		overdue: true,
		onTime: true,
	});
	const rows = [
		{
			projectName: "Android Development",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
		{
			projectName: "iOS Development",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
		{
			projectName: "Web Development",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
		{
			projectName: "Media Channel",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
		{
			projectName: "Android Development",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
		{
			projectName: "iOS Development",
			Start: "Today, May 24",
			Duration: "6 Days",
			End: "May 30",
			delivery: "Jun 30",
			deviation: "Time",
		},
	];
	const toggleTableVisibility = (tableName) => {
		setTableVisibility((prevVisibility) => ({
			...prevVisibility,
			[tableName]: !prevVisibility[tableName],
		}));
	};
	const renderDropdownIcon = (tableName) => {
		const isTableVisible = tableVisibility[tableName];

		if (isTableVisible) {
			return (
				<Box className="" onClick={() => toggleTableVisibility(tableName)}>
					<Dropdownicon>
						<Dropdown width="18px" height="18px" />
					</Dropdownicon>
				</Box>
			);
		} else {
			return (
				<Box className="pb-2" onClick={() => toggleTableVisibility(tableName)}>
					<RotatedDropdownIcon>
						<Dropdown width="18px" height="18px" />
					</RotatedDropdownIcon>
				</Box>
			);
		}
	};
	return (
		<>
			<SecondaryBar />
			<SecondaryBarStyled>
				<Box className="secondaryBar">
					<SecondaryHeading label="Project Teams" />
				</Box>
			</SecondaryBarStyled>
			
			<Box className="main-content">

				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline m-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('onTime')}
							<Pill color="#20c012" textColor="white">
								<SpanStyled weight={500} size="14px">
									On Time
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['onTime'] && (
					<Box>
						<RadioPositionStyled>
						<ListTable
							onDelete={() => setModal(false)}
							onView={() => setModal(true)}
							options={["view"]}
							idIndex={1}
							value="inventory-Edit"
							rows={rows}
							columns={InventoryColumns}
						/>
						</RadioPositionStyled>
					</Box>
				)}

				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline m-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('overdue')}
							<Pill color="var(--red)" textColor="white">
								<SpanStyled weight={500} size="14px">
									Overdue
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['overdue'] && (
					<Box>
						<RadioPositionStyled>
						<ListTable
							onDelete={() => setModal(false)}
							onView={() => setModal(true)}
							options={["view"]}
							idIndex={1}
							value="inventory-Edit"
							rows={rows}
							columns={InventoryColumns}
						/>
						</RadioPositionStyled>
					</Box>
				)}
				

				
				<CustomModal
					showModal={showModal}
					onHide={() => setModal(false)}
					heading="Android Development Team"
					closeButton={true}
				>
					<FormEleWrapper>
						<Box className="formEleContainer">
							<LabelStyled className="labelForm">Task Manager</LabelStyled>
							<ImageUploader label="Ronald Richards" userImg={profile2} />
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer">
							<LabelStyled className="labelForm">Members</LabelStyled>
							<Box className="d-flex flex-wrap gap-2">
								<ImageUploader label="Ronald Richards" role="Team Leader" userImg={profile2} />
								<ImageUploader label="Ronald Richards" role="Project Manager" userImg={profile2} />
								<ImageUploader label="Ronald Richards" role="Developer" userImg={profile2} />
								<ImageUploader label="Ronald Richards" role="Developer" userImg={profile2} />
							</Box>
						</Box>
					</FormEleWrapper>
				</CustomModal>
			</Box>
		</>
	);
};

