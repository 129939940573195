import React from "react";
import styled from "styled-components";

import { Box, SpanStyled, BoxCheck } from "../../../../../Styled/Elements";
import { UserUploadClone } from './styledTaskList';
import { MenuTriggerCross } from "../../../../../Styled/AllImages";


const CloneWrapper = ({ selectedMainParents, onRemoveClick }) => {
	const addParents = selectedMainParents.map((mainParent) => {
		return (

			<UserUploadClone key={mainParent.id}>
				<BoxCheck BoxCheckClass="boxThemeColor" />
				<Box className="addListContent">
					<Box className="listContentStRow">
						<SpanStyled className="listContentDt" color="var(--grey)" size="12px" lheight="18px">
							{mainParent.taskDate}
						</SpanStyled>
						<SpanStyled color="var(--grey)" size="18px" lheight="18px">
							.
						</SpanStyled>
						<SpanStyled color="var(--grey)" size="15px" lheight="18px">
							{mainParent.taskName}
						</SpanStyled>
					</Box>
					<SpanStyled color="var(--greyText)" size="10px" >
						{mainParent.taskID}
					</SpanStyled>

				</Box>
				<MenuTriggerCross className="removeTaskTrigger" onClick={() => onRemoveClick(mainParent.id)} />
			</UserUploadClone>
		);
	});

	return (
		// <div className="cloneWrapper">
		<>
			{addParents}

		</>
	);
};

export default CloneWrapper;


/*
const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  .UserUploadBtn {
	display: flex;
	gap: 15px;
	align-items: center;
  }
  .userPlaceholder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: var(--greyBg);
	border-radius: 50%;
	border: 1px dashed var(--greyText);
	overflow: hidden;
	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }
  .selected.dropdown-item {
	background-color: var(--greyBg);
  }
`;
*/
