import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { I18nextProvider } from "react-i18next";
import i18n from "./app/Languages/INEXT";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./app/Assets/styles/style.scss";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <App />
          </Router>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
