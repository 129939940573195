import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { Box, SpanStyled } from "../../../Styled/Elements";
import { CustomCheckbox } from "../../../Styled/FormElements";

export const MembersList = (props) => {
	const {
		selectedItem,
		setSelectedItem,
		setSelectedItemImage,
		setSelectedItemName,
	} = props;

	//good solution
	// const handleMainChildClick = () => {
	//     setSelectedItem(props.value);
	// };

	// const handleMainChildClick = (itemValue) => {
	//   // If the clicked item is the currently selected item, deselect it.
	//   setSelectedItem(itemValue);
	// };

	const handleMainChildClick = () => {
		if (selectedItem === props.value) {
			setSelectedItem(null); // Deselect when clicked again
			setSelectedItemImage(null); // Remove the selected image
			setSelectedItemName(null); // Remove the selected Name
		} else {
			setSelectedItem(props.value);
			setSelectedItemImage(props.userImg); // Set the selected image
			setSelectedItemName(props.name); // Set the selected Name
		}
	};

	return (
		<>
			<Dropdown.Item
				href="#!"
				className={`p-0 ${selectedItem === props.value ? "selected" : ""}`}
				onClick={handleMainChildClick}
				>
				<DropListRow className="dropChild">
					<Box className="dropRadioContainer">
						{/* <RadioButton
                            color="var(--grey)"
                            name="test"
                            label={props.label}
                            value={props.value}
                            checked={selectedItem === props.value}
                        /> */}
						<CustomCheckbox
							name="members"
							value={props.value}
							checked={selectedItem === props.value}
						/>
						
					</Box>
					<Box className="selUserImg">
						<img src={props.userImg} alt="User" />
					</Box>
					<SpanStyled color="grey" className="ms-1">
						{props.name}
					</SpanStyled>
				</DropListRow>

				<Dropdown.Divider />
			</Dropdown.Item>
		</>
	);
};
const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 7px;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
