import { useState } from "react"
import { ChevronRight } from "react-bootstrap-icons"
import { InputFloating, RadioButton } from "../../../../Styled/FormElements"
import { Box, ButtonStyled, HeadingStyled, LazyImage, SpanStyled } from "../../../../Styled/Elements"
import { SearchIcon } from "../../../../Styled/AllImages"
import { UsersListStyled } from "./Styled"

const DirectMemberSelection = () => {
    const imgUrl = 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80'
    const [selectedUser, setSelectedUser] = useState()
    const toggleUserSelection = (e, currentVal) => {
        setSelectedUser(currentVal);
    }
    return (
        <Box>
            <Box className='px-3'>
                <InputFloating placeholder='Search' />
            </Box>
            <UsersListStyled className='mb-0 mt-2 p-0'>
                {[...Array(5)].map((list, key) => (
                    <li
                        className={` d-flex align-items-center justify-content-between ${selectedUser === key + 1 ? 'active' : ''}`}
                        key={`item-${key + 1}`}
                        onClick={e => toggleUserSelection(e, key + 1)}
                    >
                        <Box className='d-flex align-items-center'>
                            <Box className='active dot position-relative'>
                                <LazyImage src={imgUrl} width='40px' height='40px' className='rounded-circle' />
                            </Box>
                            <HeadingStyled size='14px' weight={500} className='ms-3' color='var(--drColor)'>
                                Cameron Williamson
                            </HeadingStyled>
                        </Box>
                        <RadioButton
                            value={key + 1}
                            checked={selectedUser === key + 1}
                            className='padding-l-25 radio-set'
                            name='user-selection'
                            labelClass='customize-position'
                        />
                    </li>
                ))}
            </UsersListStyled>
            <Box className="bTNS d-flex pt-4">
                <ButtonStyled className="py-2 mx-2" bg="var(--themeColor)" width="120px">
                    Select
                </ButtonStyled>
                <ButtonStyled className="py-2 mx-2" bg="var(--red)" width="120px">
                    Cancel
                </ButtonStyled>
            </Box>
        </Box>
    )
}


export default DirectMemberSelection;