import { ChevronRight } from "react-bootstrap-icons";
import { Box, ButtonStyled, LabelStyled, SpanStyled, TextStyled } from "../../../../Styled/Elements";
import { InputFloating } from "../../../../Styled/FormElements";

const CreateGroupForm = () => {
    return (
        <>
            <TextStyled size='14px' style={{ lineHeight: 1.8 }}>
                Start collaborating with your team today by creating a group. Give your team a
                name and invite members to join.
            </TextStyled>
            <Box>
                <LabelStyled className='my-1' size='15px' color='var(--darkBlue)'>Create Group</LabelStyled>
                <InputFloating placeholder='Group Name' />
            </Box>
            <Box className='my-3 pb-2 textarea-field-lg'>
                <LabelStyled className='my-1' size='15px' color='var(--darkBlue)'>Description</LabelStyled>
                <InputFloating textarea placeholder='Description (What’s this group about?)' />
            </Box>
            <Box className="bTNS d-flex">
                <ButtonStyled className="py-2 mx-2" bg="var(--themeColor)" width="120px">
                    Create
                </ButtonStyled>
                <ButtonStyled className="py-2 mx-2" bg="var(--red)" width="120px">
                    Cancel
                </ButtonStyled>
            </Box>

        </>
    )
}

export default CreateGroupForm;