import { Col, Row } from "react-bootstrap";
import { avatarImg } from "../../Styled/AllImages";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
  Box,
  HeadingStyled,
  SecondaryBarStyled,
  SpanStyled,
  TextWithIcon,
} from "../../Styled/Elements";
import { ProjectsTileArray } from "../../Components/Dashboard/Pages/TilesArray";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";

const rows = [
  {
    layoutName: "UI/UX Designer",
    creator: "Kevin",
    date: "May 23",
    howManyBeingChosen: "34",
    description: "-",
  },
  {
    layoutName: "Android Developer",
    creator: "Alex",
    date: "May 24",
    howManyBeingChosen: "Android Developer",
    description: "-",
  },
];

const InventoryColumns = [
  {
    name: "layoutName",
    label: "Layout Name",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "creator",
    label: "Creator",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "date",
    label: "Date",
  },
  {
    name: "howManyBeingChosen",
    label: "How Many Being Chosen",
  },
  {
    name: "description",
    label: "Description",
  },
];

export const LibraryOverview = () => {
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Library Overview
            </HeadingStyled>
          </Box>
        </SecondaryBarStyled>

        <Row className=" main-content px-row-10 mb-4">
          <Row className="px-row-10">
            <Col xs={12}>
              <Row className="px-row-10 row-tile row-tile-4">
                {ProjectsTileArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-tile col-tile-4"
                    sm={6}
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <ListTable
            options={[]}
            idIndex={1}
            value="inventory-Edit"
            rows={rows}
            columns={InventoryColumns}
          />
        </Row>
      </Box>
    </>
  );
};
