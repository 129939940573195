import { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import EmojiPicker from 'emoji-picker-react'
import ContentEditable from 'react-contenteditable'
import { Paperclip } from "react-bootstrap-icons"
import { ChatSendBtn, EmojiMood, Voice } from "../../../../Styled/AllImages"
import { Box } from "../../../../Styled/Elements"
// import { MoodIcon, PaperPlaneIcon } from "../ui/AllImages"
const EmojiBox = () => {
    const [messageVal, setMessage] = useState('')
    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };
    return (
        <>
            <Box className='chat-send-box'>
                <ul className='mb-0 list-unstyled w-100 align-items-center d-flex'>
                    {/* <li>
                        <Dropdown>
                            <Dropdown.Toggle variant='' className='p-0 border-0' bsPrefix>
                                <EmojiMood />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='bg-transparent p-0 border-0'>
                                <EmojiPicker
                                    lazyLoadEmojis
                                    height={400}
                                    previewConfig={{
                                        showPreview: false
                                    }}
                                    onEmojiClick={({ getImageUrl }) => setMessage(messageVal + `<img height='20px' src=${getImageUrl()} />`)}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    <li>
                        <label className='cursor-pointer'>
                            <Paperclip size={20} color='var(--greyText)' />
                            <input className='d-none' type='file' />
                        </label>
                    </li>
                    <li className='mx-2 cursor-pointer'>
                        <label>
                            <Voice size={20} color='var(--greyText)' />
                        </label>
                    </li>

                    <li className='mx-2 article-field'>
                        <ContentEditable placeholder='Ask a question or post an update' tagName='article' html={messageVal} onChange={handleInputChange} />
                    </li>
                    {/* <li className='ms-auto'>
                    <button type='button' className='border-0 d-flex align-items-center justify-content-center chat-send-btn'>
                        <ChatSendBtn height='22px' />
                    </button>
                </li> */}
                </ul>
                <ChatSendBtn />
            </Box>

        </>
    )
}

export default EmojiBox