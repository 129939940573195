import { Component } from "react";
import Chart from "react-apexcharts";

class ReportsPrDeProjectCostProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      series: [40, 40, 20],

      options: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        labels: [
          "Total Project Cost 40%",
          "Completed 40%",
          "Total Project Paid 20%",
        ], // Update the labels here
        colors: ["var(--outlineGreyBG)", "var(--orange)", "var(--green)"],
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%", // Adjust the thickness here (e.g., '65%')
            },
          },
        },

        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          fontSize: "12px",
          fontFamily: "var(--regular)",
          width: 450,
          offsetX: 0,
          offsetY: 15,
          padding: 0,
          labels: {
            colors: "var(--grey)",
            useSeriesColors: false,
          },
          markers: {
            width: 14,
            height: 14,
            radius: 4,
            offsetX: 0,
            offsetY: 3,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        responsive: [
          {
            //breakpoint: 480,
            options: {
              chart: {
                //width: 200
              },
            },
          },
        ],
      },
    };
  }
  render() {
    return (
      <div className="ProgressBarContainer ProgressBarProject ProgressBarProjectCost">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={405}
        />
      </div>
    );
  }
}

export default ReportsPrDeProjectCostProgressBar;
