import { Dropdown } from "react-bootstrap";
import { MoodIcon, PaperPlaneIcon, Attach, Voice, ChatSendIcon } from "../../Styled/AllImages";
import { Box, HeadingStyled, SecondaryBarStyled, ButtonStyledDiv, ButtonStyled } from "../../Styled/Elements";
import { TaskActivitiesCard } from "./TaskActivityCard";
import { Paperclip } from "react-bootstrap-icons";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";
import { InputStyledWrap } from "../../Styled/styled";
import { InputWithIcon } from "../../Styled/FormElements";

export const TaskActivities = () => {
	return (
		<TaskActivitiesCardWrapper className="TskActCard position-relative">
			<Box className="TaskActivitiesCardInner">
				<TaskActivitiesCard />
				<TaskActivitiesCard />
				<TaskActivitiesCard />
				<TaskActivitiesCard />
				<TaskActivitiesCard />
				<TaskActivitiesCard />
			</Box>
			<ChatFooter>
				<Box className="user-chat-input-wrapper">
					<Box className="user-chat-input-container">
						<Box className="user-chat-input-inner">

							<InputStyledWrap>
								<InputWithIcon
									type="text"
									className="form-control user-chat-input"
									placeholder="Type Here"
								/>
							</InputStyledWrap>
							<ButtonStyledDiv className="btn-add-voice btn-attach">
								<input type="file" accept="*" multiple />
								<Attach />
							</ButtonStyledDiv>
							<ButtonStyledDiv className="btn-add-voice btn-voice">
								<Voice />
							</ButtonStyledDiv>
						</Box>
						<ButtonStyled className="btn-send-files">
							<ChatSendIcon />
						</ButtonStyled>
					</Box>
				</Box>
			</ChatFooter>
		</TaskActivitiesCardWrapper>
	);
};

const TaskActivitiesCardWrapper = styled.div`
	display: flex; 
	height: 100%;
	padding-bottom: 73px;
	
	.TaskActivitiesCardInner{
		display: flex; 
		height: 100%;
		overflow-y: auto;
		flex-wrap: wrap;
		border-bottom: 1px solid var(--outlineC);
	}
`;
const ChatFooter = styled.div`
		position: absolute;
		padding: 15px 15px;
		bottom: 0;
		width: 100%;
		.user-chat-input-wrapper {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			// padding: 15px 20px 15px 20px;
			background-color: var(--white);
			position: relative;
			.user-chat-input-container{
				display: flex;
				width: 100%;
				gap: 15px;
				.user-chat-input-inner{
					position: relative;
					width: 100%;
					.btn-add-voice{
						position: absolute;
						top: 0;
						bottom: 0;
						margin: auto;
						padding: 0;
						border: 0;
						background-color: transparent;
						width: 21px;
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.btn-attach{
						right: 15px;
					}
					.btn-voice{
						right: 45px;
					}
				}
				.btn-send-files{
					display: flex;
					align-items: center;
					width: fit-content;
					padding: 8px 12px;
				}
			}
		}
			
	}
			
`;
