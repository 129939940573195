import styled from "styled-components";
import { AvatarGroup, Box, SpanStyled, TextStyled } from "../../../Styled/Elements";
import { showMembersModal } from "../../../Store/slices/HelperSlice";
import { useDispatch } from "react-redux";
import { Container, Stack } from "react-bootstrap";

export const TasksTile = (props) => {
    const dispatch = useDispatch()
    return (
        <>
            <TiledWrapperStyled className="TiledWrapper d-flex align-items-center">
                <Container fluid>
                    <Stack direction="horizontal" className="justify-content-between align-items-center">
                        <TextStyled
                            className="tile-title"
                            dangerouslySetInnerHTML={{ __html: props.title }}
                        ></TextStyled>
                        <AvatarGroup data={props.executer} dialog={() => dispatch(showMembersModal(true))} />
                    </Stack>
                    <Box>
                        <SpanStyled className="tile-content" color="#A0A0A0">
                            Today, {props.startDate} -
                            <SpanStyled color="#E8374F"> {props.dueDate}</SpanStyled>
                        </SpanStyled>
                    </Box>
                    <Box>
                        <SpanStyled className="tile-content" color="#A0A0A0">
                            ID: {props.id}
                        </SpanStyled>
                    </Box>
                    <Box>
                        <SpanStyled className="task-type p-1 rounded-2" color={`${props.type === 'Standalone' ? '#74d66e' : '#bfa5dd'}`}>
                            {props.type}
                        </SpanStyled>
                    </Box>
                </Container>
            </TiledWrapperStyled>
        </>
    );
};

const TiledWrapperStyled = styled.div`
  background: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 20px 10px;
  height: 100%;
  width: 100%;
  .tile-title {
    font-size: 16px;
    color: var(--darkBlue) !important;
    font-family: var(--semiBold);
    line-height: 16px !important;
    margin-bottom: 4px;
  }
  .tile-content {
    font-size: 13px;
    line-height: 18px;
  }
  .task-type {
    background-color: var(--greyBg);
  }
`;
