import { Component } from "react";
import Chart from "react-apexcharts";

class ProjectPerformanceGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      series: [80, 12, 8],

      options: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        labels: [
          "Total Project Cost 40%",
          "Completed 40%",
          "Total Project Paid 20%",
        ], // Update the labels here
        colors: ["var(--green2)", "var(--orange)", "var(--red)"],
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%", // Adjust the thickness here (e.g., '65%')
            },
          },
        },

        legend: {
          show: false,
        },
        responsive: [
          {
            //breakpoint: 480,
            options: {
              chart: {
                //width: 200
              },
            },
          },
        ],
      },
    };
  }
  render() {
    return (
      <div className="ProgressBarContainer per-graph">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={230}
        />
      </div>
    );
  }
}

export default ProjectPerformanceGraph;
