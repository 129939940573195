import { useState } from "react";
import styled from "styled-components";
import {
	AvatarGroup,
	Box,
	FormEleWrapper,
	HeadingStyled,
	LabelStyled,
	MainContainer,
	PillStyled,
	SecondaryBarStyled,
	SpanStyled,
	HeadingStyledDB,
	BoxCheck,
	TextStyled,
	StateBtn,
	Dropdownicon,
	RotatedDropdownIcon,
	Pill,
	RadioPositionStyled,
	SecondaryHeading,
} from "../../../Styled/Elements";
import { Col, Row } from "react-bootstrap";
// import { EditTaskToTeam } from "./addTask/EditTaskToTeam";

// import { ViewTaskModal } from "../../Components/Modals/ViewTask/ViewTask";
import { showMembersModal } from "../../../Store/slices/HelperSlice";
import { useDispatch } from "react-redux";
import { ImageUploader, RadioButton } from "../../../Styled/FormElements";
import { profile, profile2, Dropdown } from "../../../Styled/AllImages";
import { ListTable } from "../../../Components/Dashboard/Pages/Tables/ListTable";
import { ProjectsTile } from "../../../Components/Dashboard/Pages/ProjectsTile";
import { ProgressBarTile } from "../../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import {
	ReportsPrDeProjectCostArray,
	ReportsPrDeProjectCostProgBarTileArray,
	ReportsTasksDeProjectCostArray,

} from "../../../Components/Dashboard/Pages/TilesArray";
import { ViewTaskModal } from "../../../Components/Modals/ViewTask/ViewTask";


export const ProjectDetials = ({ project }) => {
	const [showModal, setModal] = useState(false);
	const [showAssignTskToTeamModal, setShowAssignTskToTeamModal] = useState(false);
	const [showEditTaskToTeamModal, setShowEditTaskToTeamModal] = useState(false);
	const [tableVisibility, setTableVisibility] = useState({
		toDo: true,
		inProgress: true,
		overdue: true,
		complete: true,
		extended: true,
	});
	const dispatch = useDispatch();
	const InventoryColumns = [
		{
			name: "id",
			label: "ID",
		},
		{
			name: "taskName",
			label: "Task Name",
			render: (text, item) => (
				<SpanStyled className="d-flex align-items-center">
					<BoxCheck BoxCheckClass={item.color.class} />
					<TextStyled className="mb-0 mx-1" color="var(--grey)">{text}</TextStyled>
				</SpanStyled>
			)
		},
		{
			name: "implementer",
			label: "Implementer",
		},
		{
			name: "executor",
			label: "Executor",
			render: (text) => <AvatarGroup data={text} dialog={() => dispatch(showMembersModal(true))} width='26px' height='24px' />,
		},
		{
			name: "start",
			label: "Start",
		},
		{
			name: "duration",
			label: "Duration",
		},
		{
			name: "End",
			label: "End",
			render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
		},
		{
			name: "Performance",
			label: "Performance",
		},
		{
			name: "type",
			label: "Type",
			// render: (text) => <StateBtn label="Standalone" />,
		},

	];
	const rows = [
		{
			id: '00123',
			taskName: "Android Development",
			implementer: "Task Manager",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			start: "May 24",
			duration: "6 Days",
			End: "May 30",
			Performance: "On Time",
			type: <StateBtn label="Standalone" color="var(--green2) " />,
			state: 'toDo',
			color: {
				class: "boxLightGrey",
			},
		},
		{
			id: '00123',
			taskName: "Android Development",
			implementer: "Task Manager",
			executor: [
				{ name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
			],
			start: "May 24",
			duration: "6 Days",
			End: "May 30",
			Performance: "On Time",
			type: <StateBtn label="Standalone" color="var(--green2) " />,
			state: 'toDo',
			color: {
				class: "boxLightGrey",
			},
		},

	];
	const onDelete = () => {
		setModal(true);
	};
	const openAddTskModal = () => {
		setShowAssignTskToTeamModal(true)
	}
	const openEdiTaskModal = () => {
		setShowEditTaskToTeamModal(true)
	}
	const toggleTableVisibility = (tableName) => {
		setTableVisibility((prevVisibility) => ({
			...prevVisibility,
			[tableName]: !prevVisibility[tableName],
		}));
	};
	const renderDropdownIcon = (tableName) => {
		const isTableVisible = tableVisibility[tableName];

		if (isTableVisible) {
			return (
				<Box className="" onClick={() => toggleTableVisibility(tableName)}>
					<Dropdownicon>
						<Dropdown width="18px" height="18px" />
					</Dropdownicon>
				</Box>
			);
		} else {
			return (
				<Box className="pb-2" onClick={() => toggleTableVisibility(tableName)}>
					<RotatedDropdownIcon>
						<Dropdown width="18px" height="18px" />
					</RotatedDropdownIcon>
				</Box>
			);
		}
	};
	return (
		<Box>
			<SecondaryBarStyled>
				<Box className="secondaryBar">
					<SecondaryHeading label="Project Detials" />
				</Box>
			</SecondaryBarStyled>

			<Box className="main-content">
				<MainContainer>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Project Name
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									Android App UX/UI Design
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Project Type
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									External
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Project Purpose
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									The purpose of the mobile app project is to provide a user-friendly and convenient solution that enhances communication and streamlines tasks for users on their mobile devices.
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer">
							<LabelStyled className="labelForm">Project Manager</LabelStyled>
							<ImageUploader label="Savannah Nguyen" userImg={profile} />
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Implementor Name
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									Ronald Richards
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Contact Person
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									Ronald Richards
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>
					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">
								Email
							</LabelStyled>
							<Box className="w-100">
								<SpanStyled color="var(--grey)" size="15px" lheight="18px">
									ronald.richards@gmail.com
								</SpanStyled>
							</Box>
						</Box>
					</FormEleWrapper>

					<FormEleWrapper>
						<Box className="formEleContainer align-items-start">
							<LabelStyled className="labelForm pt-1">Phase</LabelStyled>
							<Box className="w-100">
								<Box className="d-flex justify-content-between gap-2">
									<Box>
										<PillStyled color="#80dc78">Execution</PillStyled>
									</Box>
									<Box>
										<LabelStyled className="labelForm">Start Date</LabelStyled>
										<Box>
											<SpanStyled color="var(--grey)">
												9 August
											</SpanStyled>
										</Box>
									</Box>
									<Box>
										<LabelStyled className="labelForm">Duration</LabelStyled>
										<Box>
											<SpanStyled color="var(--grey)">
												10 Days
											</SpanStyled>
										</Box>
									</Box>
									<Box>
										<LabelStyled className="labelForm">End Date</LabelStyled>
										<Box>
											<SpanStyled color="var(--grey)">19 August</SpanStyled>
										</Box>
									</Box>
									<Box>
										<LabelStyled className="labelForm">Left</LabelStyled>
										<Box>
											<SpanStyled color="var(--grey)">
												8 Days
											</SpanStyled>
										</Box>
									</Box>
									<Box>
										<LabelStyled className="labelForm">Timeline %</LabelStyled>
										<Box>
											<SpanStyled color="var(--grey)">
												80 %
											</SpanStyled>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</FormEleWrapper>
				</MainContainer>
				<Row className="px-row-10 mt-4 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Project Cost
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-3">
							{ReportsPrDeProjectCostArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-3"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
							{ReportsPrDeProjectCostProgBarTileArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-prog-tile col-prog-tile-3"
								>
									<ProgressBarTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<Row className="px-row-10 mt-4 mb-4">
					<Col xs={12}>
						<HeadingStyledDB family="var(--semiBold)" size="">
							Projects Tasks
						</HeadingStyledDB>
						<Row className="px-row-10 mb-4 row-tile row-tile-3">
							{ReportsTasksDeProjectCostArray.map((item, key) => (
								<Col
									key={item.title + key}
									className="mt-2 col-tile col-tile-3"
								>
									<ProjectsTile {...item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
				<FormEleWrapper className="p-0 pe-0 border-0">
					<Box className="formEleContainer align-items-baseline mb-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('toDo')}
							<Pill>
								<SpanStyled weight={500} size="14px">
									To Do
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">3 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['toDo'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline mb-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('inProgress')}
							<Pill color="#018de4" textColor="white">
								<SpanStyled weight={500} size="14px">
									In Progress
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['inProgress'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline m-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('overdue')}
							<Pill color="var(--red)" textColor="white">
								<SpanStyled weight={500} size="14px">
									Overdue
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['overdue'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}
				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline m-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('extended')}
							<Pill color="var(--purple)" textColor="white">
								<SpanStyled weight={500} size="14px">
									Extended
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['extended'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}

				<FormEleWrapper className="p-0 pe-0 border-0 mt-3">
					<Box className="formEleContainer align-items-baseline m-0 p-0">
						<Box className="d-flex w-25 align-items-baseline">
							{renderDropdownIcon('complete')}
							<Pill color="#20c012" textColor="white">
								<SpanStyled weight={500} size="14px">
									Complete
								</SpanStyled>
							</Pill>
							<LabelStyled className="labelForm pt-1">2 Tasks</LabelStyled>
						</Box>
					</Box>
				</FormEleWrapper>
				{tableVisibility['complete'] && (
					<Box>
						<RadioPositionStyled>
							<ListTable
								onView={openAddTskModal}
								onEdit={openEdiTaskModal}
								onDelete={onDelete}
								options={["view"]}
								//idIndex={1}

								rows={rows.filter(row => row.state === 'toDo')}
								columns={InventoryColumns}
							/>
						</RadioPositionStyled>
					</Box>
				)}
			</Box>
			<ViewTaskModal
				showAssignTskToTeamModal={showAssignTskToTeamModal}
				setShowAssignTskToTeamModal={() => setShowAssignTskToTeamModal(false)}
				showSubmitTask={false}  // Submit button Hide-Show
				showPrintTask={true} // Print button Hide-Show
			/>
			{/* <EditTaskToTeam
				showEditTaskToTeamModal={showEditTaskToTeamModal}
				setShowEditTaskToTeamModal={() => setShowEditTaskToTeamModal(false)}
			/> */}
		</Box>
	);
};
