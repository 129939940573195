import { useState } from "react";
import { CustomModal } from "../../Components/Modals/CustomModal";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
  Box,
  FormEleWrapper,
  HeadingStyled,
  LabelStyled,
  SecondaryBarStyled,
} from "../../Styled/Elements";

const rows = [
  {
    category: "Task",
    roll: "Member",
    tasks: "Standalone",
    projectName: "IOS Development",
    taskName: "UX/UI Design",
    implementor: "Task Manager",
    status: "On Time",
  },
  {
    category: "Project",
    roll: "Task Manager",
    tasks: "-",
    projectName: "UI/UX",
    taskName: "Android Development",
    implementor: "Team Leader",
    status: "Late",
  },
];

const InventoryColumns = [
  {
    name: "category",
    label: "Category",
  },
  {
    name: "roll",
    label: "Roll",
  },
  {
    name: "tasks",
    label: "Tasks",
  },
  {
    name: "projectName",
    label: "Project Name",
  },
  {
    name: "taskName",
    label: "Task Name",
  },
  {
    name: "implementor",
    label: "Implementor",
  },
  {
    name: "status",
    label: "Status",
  },
];

export const ViewPerformance = () => {
  const [selectedTask, setSelectedTask] = useState("");

  return (
    <Box>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            Tasks
          </HeadingStyled>
        </Box>
      </SecondaryBarStyled>

      <Box className="main-content">
        <ListTable
          onView={setSelectedTask}
          options={["view"]}
          idIndex={1}
          value="inventory-Edit"
          rows={rows}
          columns={InventoryColumns}
        />
      </Box>

      <CustomModal
        showModal={selectedTask}
        onHide={() => setSelectedTask("")}
        heading="UX/UI Design"
        closeButton={true}
      >
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">Project Name</LabelStyled>
            <LabelStyled className="labelForm">
              {selectedTask.projectName}
            </LabelStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">Roll</LabelStyled>
            <LabelStyled className="labelForm">{selectedTask.roll}</LabelStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">
              Number of team members
            </LabelStyled>
            <LabelStyled className="labelForm">102</LabelStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">Start Date</LabelStyled>
            <LabelStyled className="labelForm">12 May 2200</LabelStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">Duration</LabelStyled>
            <LabelStyled className="labelForm">12 Days</LabelStyled>
          </Box>
        </FormEleWrapper>
        <FormEleWrapper>
          <Box className="formEleContainer">
            <LabelStyled className="labelForm">End Date</LabelStyled>
            <LabelStyled className="labelForm">12 May 2200</LabelStyled>
          </Box>
        </FormEleWrapper>
      </CustomModal>
    </Box>
  );
};
