import React from 'react'
import { showMembersModal } from '../../../Store/slices/HelperSlice'
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Box, HeadingStyled } from '../../../Styled/Elements';
import { XLg } from 'react-bootstrap-icons';

const MembersModal = () => {
    const helpers = useSelector((state) => state.helpers);
    const dispatch = useDispatch()

    return (
        <Modal
            show={helpers?.membersModal}
            className="members-popup"
            onHide={() => dispatch(showMembersModal(false))}
            centered
        >
            <Modal.Header className='justify-content-between'>
                <HeadingStyled size="18px" weight="600" color="var(--white)">
                    Members
                </HeadingStyled>
                <Box onClick={() => dispatch(showMembersModal(false))} style={{cursor: 'pointer'}}>
                    <XLg />
                </Box>
            </Modal.Header>
            <Modal.Body>
                <Box className="d-flex align-items-center mb-3">
                    <Box
                        className="meeting-dropdown mx-2"
                        style={{ width: "30px", height: "30px" }}
                    >
                        <img
                            alt="img"
                            className="img-fluid"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                        />
                    </Box>
                    <HeadingStyled
                        size="14px"
                        className=" mx-1 "
                        color="var(--drColor)"
                        weight={500}
                    >
                        Dell Alienware
                    </HeadingStyled>
                </Box>
                <Box className="d-flex align-items-center mb-3">
                    <Box
                        className="meeting-dropdown mx-2"
                        style={{ width: "30px", height: "30px" }}
                    >
                        <img
                            alt="img"
                            className="img-fluid"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                        />
                    </Box>
                    <HeadingStyled
                        size="14px"
                        className=" mx-1 "
                        color="var(--drColor)"
                        weight={500}
                    >
                        Dell Alienware
                    </HeadingStyled>
                </Box>
                <Box className="d-flex align-items-center">
                    <Box
                        className="meeting-dropdown mx-2"
                        style={{ width: "30px", height: "30px" }}
                    >
                        <img
                            alt="img"
                            className="img-fluid"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                        />
                    </Box>
                    <HeadingStyled
                        size="14px"
                        className=" mx-1 "
                        color="var(--drColor)"
                        weight={500}
                    >
                        Dell Alienware
                    </HeadingStyled>
                </Box>
            </Modal.Body>
        </Modal>
    )
}

export default MembersModal