import React from "react";
import styled from "styled-components";

import { Box, SpanStyled } from "../../../../../Styled/Elements";

const CloneWrapper = ({ selectedMainParents }) => {
	const addParents = selectedMainParents.map((mainParent) => {
		return (
			
			<UserUpload key={mainParent.id}>
			    <Box className="userPlaceholder">
			        <img src={mainParent.profile} alt="user" />
			    </Box>
				<Box className='abtUser'>
					<SpanStyled color="var(--grey)" >{mainParent.userName}</SpanStyled>
					{/* <SpanStyled color="var(--greyText)" size="10px" >{mainParent.userRole}</SpanStyled> */}
				</Box>
			</UserUpload>
		);
	});

	return (
		// <div className="cloneWrapper">
		<>
			{addParents}
			
		</>
	);
};

export default CloneWrapper;

const UserUpload = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  background-color: var(--greyBg);
  border-radius: 8px;
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--greyBg);
    border-radius: 50%;
    // border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .abtUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      // width: 100%;
    }
  }
`;
/*
const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  .UserUploadBtn {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--greyBg);
    border-radius: 50%;
    border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;
*/
