import React, {useState} from "react";
import { InputStyledWrap } from "../../../../Styled/styled";
import {
	Box,
	FormEleWrapper,
	LabelStyled,
	TextStyled,
} from "../../../../Styled/Elements";
import {
	InputFloating,
	RadioButton,
	CustomCheckbox,
	InputWithIcon,
} from "../../../../Styled/FormElements";

const Decision = () => {

	const [checkedValues, setCheckedValues] = useState([]);

	const toolsOption = [
		{
			label: "Technical Study",
			value: "technical study",
		},
		{
			label: "Financial",
			value: "financial",
		},
		{
			label: "Legal Study",
			value: "legal study",
		},
		{
			label: "Must Send",
			value: "must send",
		},
	];

	const decisionOption = [
		{
			type: "checkbox",
			label: "Consensus",
			value: "consensus",
		},
		{
			type: "checkbox",
			label: "Majority",
			value: "majority",
		},
		{
			type: "radio",
			label: "Other",
			value: "other",
		},
	];

	const mustSend = [
		{
			label: "Send agenda day before the meeting schedule. ",
			value: "Send agenda day before the meeting schedule. ",
		},
		{
			label: "Agenda must be sent before two hours from ending hours day, a day before the meeting schedule",
			value: "Agenda must be sent before two hours from ending hours day, a day before the meeting schedule",
		},
	];

	const handleCheckboxChange = (value) => {
		const isChecked = checkedValues.includes(value);
	
		if (isChecked) {
		  setCheckedValues(checkedValues.filter((val) => val !== value));
		} else {
		  setCheckedValues([...checkedValues, value]);
		}
	};
	const isMustSendChecked = checkedValues.includes('must send');


	return (
		<>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Overview</LabelStyled>
					<Box className="w-100">
						<Box className=" pb-2 textarea-field-lg">
							<InputFloating
								row={5}
								textarea
								type="textarea"
								name={"technical_aspect"}
								placeholder="Write Overview"
							/>
						</Box>
					</Box>
				</Box>
			</FormEleWrapper>
			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Tools</LabelStyled>
					<Box>
						<ul className="d-flex list-unstyled d-flex align-items-center flex-wrap gap-4">
							{toolsOption?.map((item) => (
								<li className="me-4 custom-radio d-flex align-items-center">
									<CustomCheckbox
										name={item?.name}
										label={item?.label}
										value={item?.value}
										labelClass=""
										onChange={() => handleCheckboxChange(item.value)}
									/>
									{/* <TextStyled
										md={2}
										xs={12}
										size="14px"
										className="mb-0"
										color="var(--greyText)"
										style={{ width: "max-content" }}
									>
										{item?.label}
									</TextStyled> */}
								</li>
							))}
						</ul>
						{isMustSendChecked && (
							<Box className="w-100 mt-3">
								<Box className="d-flex w-100 radioListWrapper flex-wrap gap-2">
									{mustSend.map((item, i) => (
										<Box key={i} className="radioList">
											<RadioButton
												color="var(--grey)"
												name="intExt"
												label={item.label}
												value={item.value}
											/>
										</Box>
									))}
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</FormEleWrapper>

			{/* <FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1"></LabelStyled>
					<Box className="d-flex w-100 radioListWrapper">
						<Box key="new" className="radioList">
							<Box>
								<RadioButton
									color="var(--grey)"
									name="intExt"
									label="Day Before Meeting Schedule"
								// value={item.value}
								// checked={selectedInternalExternal === item.value}
								// onChange={handleInternalExternalRadioButtonChange}
								/>
							</Box>
							<Box className="mt-3">
								<RadioButton
									color="var(--grey)"
									name="intExt"
									label="Must be sent before two hours ending hours day, a day before meeting schedule"
								// value={item.value}
								// checked={selectedInternalExternal === item.value}
								// onChange={handleInternalExternalRadioButtonChange}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</FormEleWrapper> */}

			<FormEleWrapper>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1">Decision</LabelStyled>
					<ul className="d-flex list-unstyled d-flex align-items-center flex-wrap gap-4">
						{decisionOption?.map((item) => (
							<li className="me-4 custom-radio d-flex align-items-center">
								{item.type === "checkbox" ? (
									<>
										<CustomCheckbox
											name={item?.name}
											label={item?.label}
											value={item?.value}
											labelClass=""
										/>
										
									</>
								) : (
									<RadioButton
										color="var(--grey)"
										name="intExt"
										label={item.label}
									// value={item.value}
									// checked={selectedInternalExternal === item.value}
									// onChange={handleInternalExternalRadioButtonChange}
									/>
								)}
							</li>
						))}
					</ul>
				</Box>
				<Box className="formEleContainer align-items-start">
					<LabelStyled className="labelForm pt-1"></LabelStyled>
					<Box className="w-100">
						<InputStyledWrap>
							<InputWithIcon type="text" placeholder="Other" />
						</InputStyledWrap>
					</Box>
				</Box>
			</FormEleWrapper>
		</>
	);
};

export default Decision;
