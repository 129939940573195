import styled from "styled-components";
import { Box } from "../Styled/Elements";
import { Route, Routes } from "react-router-dom";
import { Topbar } from "../Components/Dashboard/Topbar";
import { routesAdminConfig } from "../Router/AdminRoutes";
import { Sidebar } from "../Components/Dashboard/Sidebar";

const AdminLayout = () => {
  return (
    <LayoutWrapper className="d-flex flex-wrap">
      <Box>
        <Sidebar />
      </Box>
      <Topbar />
      {/* <Box className='flex-1 d-flex flex-column overflow-x-auto main-content-container'> */}

      <Box className="main-content-container">
        {
          <Routes>
            {routesAdminConfig.map((list, key) => (
              <Route key={key} {...list} />
            ))}
          </Routes>
        }
      </Box>
    </LayoutWrapper>
  );
};
const LayoutWrapper = styled.div`
  background-color: var(--greyBg);
  overflow: hidden;
  //height: 100vh;
  padding: 0px 0px 0px 0px;
  .custom-scroll {
    overflow-x: auto;
    overflow-x: hidden;
    padding-top: 16px;
  }
  .main-content-container {
    width: 100%;
    padding: 80px 0 0 248px;
    background-color: var(--greyBg);
    transition: 0.3s;
  }
`;
export default AdminLayout;
