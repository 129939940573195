import { useState } from "react";
import {
  Box,
  SecondaryBarStyled,
  SpanStyled,
  TabStyled,
} from "../../Styled/Elements";
import { ProjectListArray } from './ProjectTaskList'



export const ViewProjects = () => {
  const [activeTab, setActiveTab] = useState(ProjectListArray[0]);

  return (
    <>
      <Box>
        <SecondaryBarStyled className="secondaryBar">
          <Box>
            <Box className="tabsList">
              <TabStyled className="mb-0 list-unstyled d-flex justify-content-between">
                {ProjectListArray.map((item, key) => (
                  <li key={key} className={`${item.title === activeTab.title ? "active" : ""}`} onClick={() => setActiveTab(ProjectListArray[key])} >
                    {item.icon}
                    <SpanStyled className="px-2">{item.title}</SpanStyled>
                  </li>
                ))}
              </TabStyled>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <activeTab.component />
      </Box>
    </>
  );
};
