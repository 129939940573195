import { Col, Row } from "react-bootstrap";
import { PrTotal } from "../../Styled/AllImages";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
} from "../../Styled/Elements";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { InputStyledWrap } from "../../Styled/styled";
import { InputWithIcon } from "../../Styled/FormElements";
import { Search } from "react-bootstrap-icons";
import { TasksList } from "./TasksList";

const ProjectsArray = [
  {
    icon: PrTotal,
    count: "200",
    bg: "var(--lightBlue)",
    title: "Archived Projects",
  },
];

export const LibraryTasks = () => {
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Tasks Library
            </HeadingStyled>
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search Tasks by name"
                icon={Search}
              />
            </InputStyledWrap>
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search Tasks by executor"
                icon={Search}
              />
            </InputStyledWrap>
            <Box className="boxRBt">
              <ButtonStyledMedium
              //   onClick={() => navigate("/project/add-project")}
              >
                Search
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>

        <Row className=" main-content px-row-10 mb-4">
          <Row className="px-row-10">
            <Col xs={12}>
              <Row className="px-row-10 row-tile row-tile-4">
                {ProjectsArray.map((item, key) => (
                  <Col
                    key={item.title + key}
                    className="mt-2 col-tile col-tile-4"
                    sm={3}
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <TasksList />
        </Row>
      </Box>
    </>
  );
};
