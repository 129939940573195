import { Search } from "react-bootstrap-icons";
import { Badge, Col, Row } from "react-bootstrap";
import { InputStyledWrap } from "../../Styled/styled";
import { PrTotal, avatarImg } from "../../Styled/AllImages";
import { InputWithIcon, SelectList } from "../../Styled/FormElements";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
  SpanStyled,
  TextWithIcon,
} from "../../Styled/Elements";

const rows = [
  {
    id: "00127",
    ProjectManager: "Project Manager",
    ProjectName: "Web Development",
    Start: "May 22",
    Duration: "6 Days",
    End: "May 28",
    completionDate: "Jan 23",
    status: "On Time",
  },
  {
    id: "00128",
    ProjectManager: "Project Manager",
    ProjectName: "Media Channel",
    Start: "May 22",
    Duration: "6 Days",
    End: "May 28",
    completionDate: "Jul 11",
    status: "Cancelled",
  },
];
const InventoryColumns = [
  {
    name: "id",
    label: "#",
  },
  {
    name: "ProjectManager",
    label: "Project Manager",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "ProjectName",
    label: "Project Name",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "Start",
    label: "Start",
  },
  {
    name: "Duration",
    label: "Duration",
  },
  {
    name: "End",
    label: "End",
    render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
  },
  {
    name: "completionDate",
    label: "Completion Date",
  },
  {
    name: "status",
    label: "Status",
    render: (text) => {
      const badgeClass =
        text === "Start"
          ? "bg-orange"
          : text === "Governance"
          ? "bg-purple"
          : text === "Execution"
          ? "bg-green"
          : text === "Planning"
          ? "bg-blue"
          : "";
      return (
        <Badge
          className={`py-2 px-3 d-inline-flex bg-orange align-items-center cursor-pointer ${badgeClass}`}
        >
          <SpanStyled className="text-capitalize">{text}</SpanStyled>
        </Badge>
      );
    },
  },
];
const ProjectsArray = [
  {
    icon: PrTotal,
    count: "200",
    bg: "var(--lightBlue)",
    title: "Total Projects",
  },
];

export const LibraryProjects = () => {
  return (
    <Box>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <HeadingStyled
            className="mb-0"
            size="18px"
            weight="normal"
            color="var(--darkBlue)"
            family="var(--semiBold)"
          >
            Projects Library
          </HeadingStyled>
          <SelectList
            options={["Completed on Time", "Today"]}
            className="custom-select-design med-sel"
          />
          <InputStyledWrap>
            <InputWithIcon
              type="text"
              placeholder="Search by Project mananger"
              icon={Search}
            />
          </InputStyledWrap>
          <InputStyledWrap>
            <InputWithIcon
              type="text"
              placeholder="Search ny Project name"
              icon={Search}
            />
          </InputStyledWrap>
          <Box className="boxRBt">
            <ButtonStyledMedium
            //   onClick={() => navigate("/project/add-project")}
            >
              Search
            </ButtonStyledMedium>
          </Box>
        </Box>
      </SecondaryBarStyled>

      <Row className=" main-content px-row-10 mb-4">
        <Row className="px-row-10">
          <Col xs={12}>
            <Row className="px-row-10 row-tile row-tile-4">
              {ProjectsArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-4"
                  sm={12}
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <ListTable
          send="/library-projects-view"
          options={["send"]}
          idIndex={1}
          value="inventory-Edit"
          rows={rows}
          columns={InventoryColumns}
        />
      </Row>
    </Box>
  );
};
