import styled from "styled-components";
import { TextStyled } from "../../Styled/Elements";

export const Footer = () => {
  const date_year = new Date();
  return (
    <FooterStyled>
      <TextStyled size="16px" color="var(--white)" weight={400}>
        &copy; {date_year.getFullYear()} <span>PTM.</span> All rights reserved.
      </TextStyled>
    </FooterStyled>
  );
};
const FooterStyled = styled.footer`
  background-color: var(--themeColor);
  // padding: 16px;
  padding-bottom: 5px;
  padding-top: 16px;
  text-align: center;
  margin: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
`;
