import styled from "styled-components";
import { Box, SpanStyled } from "../../../../Styled/Elements";

export const ProgressBarTile = (props) => {
  return (
    <TileProgressBarStyled className="TileProgressBar">
      <SpanStyled
        className="pBarTitle"
        dangerouslySetInnerHTML={{ __html: props.title }}
      ></SpanStyled>
      <Box className="pBarCompleted">
        <SpanStyled className="pBarComTitle">{props.count}</SpanStyled>
        <SpanStyled className="pBarComText">{props.status}</SpanStyled>
      </Box>
      <props.progBar />
    </TileProgressBarStyled>
  );
};

const TileProgressBarStyled = styled.div`
  background: var(--white);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 0px 0px;
  position: relative;
  height: 100%;
`;
