import {
  dashboard,
  projects,
  tasks,
  reports,
  meeting,
  users,
  performance,
  contact,
  library,
  payment,
  settings,
} from "../../Styled/AllImages";
import { UsersPermissions } from "../../Pages/Users/userAdded/UserPermission";
import { UsersPersonalInformation } from "../../Pages/Users/userAdded/UserPersonalInformation";

export const sidebarList = [
  {
    title: "Dashboard",
    icon: dashboard,
    url: "/dashboard",
  },
  {
    title: "Projects",
    icon: projects,
    url: "/projects",
  },
  {
    title: "Tasks",
    icon: tasks,
    url: "/tasks",
  },
  {
    title: "Reports",
    icon: reports,
    submenu: [
      {
        title: "Overview",
        url: "/reports-overview",
      },
      {
        title: "Projects",
        url: "/reports-projects",
      },
      {
        title: "Tasks",
        url: "/reports-tasks",
      },
      {
        title: "Teams",
        url: "/reports-teams",
      },
    ],
  },
  {
    title: "Users",
    icon: users,
    url: "/users",
  },
  {
    title: "Meetings",
    icon: meeting,
    submenu: [
      {
        title: "My Meeting",
        url: "/meeting",
      },
      {
        title: "Received Meeting",
        url: "/received-meeting",
      },
    ],
  },
  {
    title: "Performance",
    icon: performance,
    url: "/performance",
  },
  {
    title: "Contact",
    icon: contact,
    url: "/contact",
  },
  {
    title: "Library",
    icon: library,
    submenu: [
      {
        title: "Overview",
        url: "/library-overview",
      },
      {
        title: "Projects",
        url: "/library-projects",
      },
      {
        title: "Tasks",
        url: "/library-tasks",
      },
      {
        title: "Others",
        url: "/library-others",
      },
    ],
  },
  {
    title: "Payment",
    icon: payment,
    url: "/payments",
  },
  {
    title: "Settings",
    icon: settings,
    url: "/settings",
  },

];

export const usersList = [
  {
    title: "Personal Information",
    component: UsersPersonalInformation,
  },
  {
    title: "Rights & Permissions",
    component: UsersPermissions,
  },
];

// export const profileList = [
//     {
//         title: 'Account Settings',
//         component: AccountSettings,
//         icon: Dashboard,
//     },
//     {
//         title: 'Billing',
//         component: ProfileBilling,
//         icon: BillingIcon,
//     },
//     {
//         title: 'Change Password',
//         component: ChangePassword,
//         icon: Lock,
//     }
// ]

// export const purchaseRequestList = [
//     {
//         title: 'Items',
//         component: PurchaseRequestItems
//     },
//     {
//         title: 'conditions',
//         component: PurchaseRequestCondition
//     },
//     {
//         title: 'AUTHORIZERS',
//         component: PurchaseAutorizersRequest
//     },
//     {
//         title: 'Cost Center',
//         component: PurchaseCostCenter
//     },
//     {
//         title: 'Estimation',
//         component: PurchaseRequestEstimation
//     }
// ]

// export const itemRequestsList = [
//     {
//         title: 'Category',
//         component: ItemCategory
//     },
//     {
//         title: 'Item',
//         component: ItemReqestItems
//     },
//     {
//         title: 'Reason / Justification',
//         component: ItemRequestReason
//     },
//     {
//         title: 'Authorizer',
//         component: ItemRequestAuthorizers
//     }
// ]

// export const settingsList = [
//     {
//         title: 'Administrator',
//         component: AdministratorSettings
//     },
//     {
//         title: 'Document',
//         component: DocumentSettings
//     }
// ]

// export const meetingList = [
//     {
//         title: 'My Meetings',
//         component: MyMeetings
//     },
//     {
//         title: 'Received Meetings',
//         component: ReceivedMeetings
//     }
// ]
