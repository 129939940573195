import { React, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Box,
  ButtonStyled,
  ContainerWapper,
  HeadingStyled,
  LeftBox,
  SpanStyled,
} from "../../../Styled/Elements";
import {
  SiteLogo,
  license,
  Download,
  BackArrow,
} from "../../../Styled/AllImages";
import {
  InputWithIcon,
  LandingWrapper,
  RadioButton,
} from "../../../Styled/FormElements";
import { Footer } from "../../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { PlanDataArray, PaymentMethodArray } from "./PlanData";
import { Badge, BadgeDownload } from "./style";
import { InputStyledWrap } from "../../../Styled/styled";

export const SignupPlan = () => {
  const [selectedPayment, setSelectedPayment] = useState("bank");
  const navigate = useNavigate();

  const handlePaymentSelection = (value) => {
    setSelectedPayment(value);
  };

  return (
    <>
      <ContainerWapper>
        <Col className="heading_wapper">
          <Box className="site_Logo mb-4">
            <BackArrow
              className="back_arrow"
              onClick={() => navigate("/signup")}
            />
            <SiteLogo width="200px" />
          </Box>
          <LeftBox>
            <HeadingStyled size="36px" weight="bold" className="mb-3">
              Signup With
              <SpanStyled color="var(--themeColor)" size="36px" weight="bold">
                PTM
              </SpanStyled>
            </HeadingStyled>
            <Box>
              <InputStyledWrap>
                <SpanStyled className="formLabel">
                  Number of Licenses
                </SpanStyled>
                <InputWithIcon
                  type="number"
                  placeholder="Number of Licenses"
                  icon={license}
                />
              </InputStyledWrap>
            </Box>
            <Box>
              <HeadingStyled size="24px" weight="bold" className="my-3">
                Choose Subscription Plan
              </HeadingStyled>
              <Row className="justify-content-between">
                {PlanDataArray.map((item, key) => (
                  <Col lg={6} md={4} xs={12} key={key} className="my-2">
                    <RadioButton name="plan" label={item.label} />
                  </Col>
                ))}
                <Col lg={12} md={6} xs={12} className="my-2 badge">
                  <Badge>
                    Total: 630 SAR<SpanStyled size="14px">Incl. TAX</SpanStyled>
                  </Badge>
                  <BadgeDownload>
                    Download Invoice
                    <SpanStyled className='px-1'>
                      <Download />
                    </SpanStyled>
                  </BadgeDownload>
                </Col>
              </Row>
            </Box>
            <Box>
              <HeadingStyled size="24px" weight="bold">
                Choose Payment Method
              </HeadingStyled>
              <Row className="justify-content-between">
                {PaymentMethodArray.map((item, i) => (
                  <Col lg={6} md={4} xs={12} key={i} className="my-3">
                    <RadioButton
                      name="planB"
                      label={item.label}
                      value={item.value}
                      checked={selectedPayment === item.value}
                      onChange={() => handlePaymentSelection(item.value)}
                    />
                  </Col>
                ))}
              </Row>
            </Box>
            <ButtonStyled
              className="button_text my-3"
              onClick={() =>
                navigate(
                  selectedPayment === "card" ? "/Card-Plan" : "/Bank-Plan"
                )
              }
            >
              <SpanStyled>Next</SpanStyled>
            </ButtonStyled>
          </LeftBox>
        </Col>
        <Col>
          <LandingWrapper
            label1="The Best PlateForm for"
            label2="Cross-team Work"
            text="Efficiently organize tasks, collaborate seamlessly, and boost productivity with our intuitive and powerful task management tool. Simplify your workflow today!"
          />
        </Col>
      </ContainerWapper>
      <Footer />
    </>
  );
};
