import { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Plus, Filter, Clander } from "../../Styled/AllImages";
import { DeleteEntry } from "../../Components/Modals/DeleteEntry";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { ProgressBarTile } from "../../Components/Dashboard/Pages/Charts/ProgressBarTile";
import { PaymentColumns } from "../../Components/Dashboard/Pages/Tables/tableConstants";
import { ProgressGraph1 } from "../../Components/Dashboard/Pages/Projects/ProgressGraph1";
import {
	Box,
	ButtonStyledMedium,
	HeadingStyled,
	HeadingStyledDB,
	SecondaryBarStyled,
	SpanStyled,
} from "../../Styled/Elements";
import {
	PaymentCostArray,
	ProjectCostProgBarTileArray,
	InternalExternalProjectsArray,
	PaymentProgBarTileArray,
} from "../../Components/Dashboard/Pages/TilesArray";
import DateRange from "../../Components/Dashboard/Pages/Payments/DateRange";
import { PaymentDateRangeContainer } from './styled'


export const Payments = () => {
	//const seriesData = [75, 25];

	const [showModal, setModal] = useState(false);
	const navigate = useNavigate();
	// const [ModalShow, setModalShow] = useState(false)
	// const [ModalSettingShow, setModalSettingShow] = useState(false)
	const rows = [
		{
			id: "1",
			ProjectName: "Android Development",
			Table: "Yes",
			CostCenter: "Riyadh",
			Amount: "SAR 2000",
			Proceed: "SAR 1000",
			Rest: "SAR 1000",
		},
		{
			id: "2",
			ProjectName: "iOS Development",
			Table: "Yes",
			CostCenter: "Riyadh",
			Amount: "SAR 2000",
			Proceed: "SAR 1000",
			Rest: "SAR 1000",
		},
		{
			id: "3",
			ProjectName: "Web Development",
			Table: "Yes",
			CostCenter: "Riyadh",
			Amount: "SAR 2000",
			Proceed: "SAR 1000",
			Rest: "SAR 1000",
		},
		{
			id: "4",
			ProjectName: "Media Channel",
			Table: "Yes",
			CostCenter: "Riyadh",
			Amount: "SAR 2000",
			Proceed: "SAR 1000",
			Rest: "SAR 1000",
		},
	];
	const onDelete = () => {
		setModal(true);
	};

	//const seriesValue = [50, 50];
	//const [seriesValue, setSeriesValue] = useState([75, 25]);

	//   const changeSeriesValue = () => {
	//     // Change the series value as needed
	//     setSeriesValue([50, 50]);
	//   };
	return (
		<>
			<Box>
				<SecondaryBarStyled>
					<Box className="secondaryBar">
						<HeadingStyled
							className="mb-0"
							size="18px"
							weight="normal"
							color="var(--darkBlue)"
							family="var(--semiBold)"
						>
							Payments
						</HeadingStyled>
						<PaymentDateRangeContainer>
							<SpanStyled color="var(--grey)"	className="px-2">Select Date Range</SpanStyled>
							<DateRange className="iconTitleDate" icon={Clander} title="From" />
							<DateRange className="iconTitleDate" icon={Clander} title="To" />
						</PaymentDateRangeContainer>
						
						{/* <Box className="boxRBt">
							<Box className="filterWrap">
								<Filter />
								<SpanStyled>Filter</SpanStyled>
							</Box>
							<ButtonStyledMedium
								onClick={() => navigate("/project/add-project")}
							>
								<Plus /> Add Project
							</ButtonStyledMedium>
						</Box> */}
					</Box>
				</SecondaryBarStyled>
				<ProjectWapper className="main-content">
					<Row className="px-row-10 mb-4">
						<Col xs={12}>
							{/* <HeadingStyledDB family="var(--semiBold)" size="">
								Project Cost
							</HeadingStyledDB> */}
							<Row className="px-row-10 mb-4 row-tile row-tile-3">
								{PaymentCostArray.map((item, key) => (
									<Col
										key={item.title + key}
										className="mt-2 col-tile col-tile-3"
									>
										<ProjectsTile {...item} />
									</Col>
								))}
								{PaymentProgBarTileArray.map((item, key) => (
									<Col
										key={item.title + key}
										className="mt-2 col-prog-tile col-prog-tile-3"
									>
										<ProgressBarTile {...item} />
									</Col>
								))}
							</Row>
						</Col>
					</Row>

					<Box>
						<ListTable
							onDelete={onDelete}
							options={["view"]}
							idIndex={1}
							value="inventory-Edit"
							rows={rows}
							columns={PaymentColumns}
						/>
					</Box>
					<DeleteEntry
						title="Inventory"
						setModal={setModal}
						showModal={showModal}
					/>
				</ProjectWapper>
			</Box>
		</>
	);
};
const ProjectWapper = styled.div``;
