import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { InputStyledWrap } from "../../Styled/styled";
import { Plus, SearchIcon } from "../../Styled/AllImages";
import { DeleteEntry } from "../../Components/Modals/DeleteEntry";
import { InputWithIcon, SelectList } from "../../Styled/FormElements";
import { UserArray } from "../../Components/Dashboard/Pages/TilesArray";
import { ProjectsTile } from "../../Components/Dashboard/Pages/ProjectsTile";
import { ListTable } from "../../Components/Dashboard/Pages/Tables/ListTable";
import { UsersColumns } from "../../Components/Dashboard/Pages/Tables/tableConstants";
import {
  Box,
  ButtonStyledMedium,
  HeadingStyled,
  SecondaryBarStyled,
} from "../../Styled/Elements";

export const Users = () => {
  const navigate = useNavigate();
  const [showModal, setModal] = useState(false);
  const onDelete = () => {
    setModal(true);
  };

  const rows = [
    {
      name: "Arham",
      costCenter: "Jeddha",
      jobTitle: "ProjectManager",
      email: "admin@gmail.com",
      phone: "(907) 555-0101",
      department: "Management",
    },
    {
      name: "Naeem",
      costCenter: "Jeddha",
      jobTitle: "ProjectManager",
      email: "admin@gmail.com",
      phone: "(907) 555-0101",
      department: "Management",
    },
    {
      name: "Naeem",
      costCenter: "Jeddha",
      jobTitle: "ProjectManager",
      email: "admin@gmail.com",
      phone: "(907) 555-0101",
      department: "Management",
    },
  ];
  return (
    <>
      <Box>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search by Name"
                icon={SearchIcon}
              />
            </InputStyledWrap>
            <SelectList
              options={["Select By Department", "Today"]}
              className="custom-select-design med-sel"
            />

            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search jobs Title"
                icon={SearchIcon}
              />
            </InputStyledWrap>
            <InputStyledWrap>
              <InputWithIcon
                type="text"
                placeholder="Search by cost center"
                icon={SearchIcon}
              />
            </InputStyledWrap>
            <Box className="boxRBt">
              <ButtonStyledMedium>Search</ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <SecondaryBarStyled>
          <Box className="secondaryBar">
            <HeadingStyled
              className="mb-0"
              size="18px"
              weight="normal"
              color="var(--darkBlue)"
              family="var(--semiBold)"
            >
              Users
            </HeadingStyled>
            <Box className="boxRBt">
              <ButtonStyledMedium onClick={() => navigate("/add/users")}>
                <Plus /> Add Users
              </ButtonStyledMedium>
            </Box>
          </Box>
        </SecondaryBarStyled>
        <Box className="main-content">
          <Box>
            <Col xs={12}>
              <Row>
                {UserArray.map((item, key) => (
                  <Col
                    lg={2}
                    md={6}
                    xs={12}
                    key={item.title + key}
                    className="m-2"
                  >
                    <ProjectsTile {...item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Box>
          <Box>
            <ListTable
              type="users"
              onDelete={onDelete}
              idIndex={1}
              options={["view", "delete", "edit"]}
              rows={rows}
              columns={UsersColumns}
            />
          </Box>
          <DeleteEntry title="User" setModal={setModal} showModal={showModal} />
        </Box>
      </Box>
    </>
  );
};
