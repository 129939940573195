import { Badge } from "react-bootstrap";
import { avatarImg } from "../../../../Styled/AllImages";
import { Check2, ClockHistory, X } from "react-bootstrap-icons";
import {
  AvatarGroup,
  BadgeBtn,
  Box,
  SpanStyled,
  TextWithIcon,
  TblGraphStyled,
} from "../../../../Styled/Elements";
import {
  showMeetingsReschduleModal,
  showMembersModal,
  showReScheduleModal,
  showViewDownloadModal,
} from "../../../../Store/slices/HelperSlice";

const actionsStatus = (item, dispatch) => {
  switch (item) {
    case "accepted":
    case "rejected":
    case "re-schdule":
      const colorSetting =
        item === "rejected"
          ? "var(--red)"
          : item === "re-schdule"
            ? "var(--orange)"
            : "var(--green2)";
      const bgSetting =
        item === "rejected"
          ? "lightRed"
          : item === "re-schdule"
            ? "lightYellow"
            : "lightGreen";
      return (
        <Badge
          bg=""
          className={`py-1 px-3 d-inline-flex  align-items-center bg-${bgSetting}`}
        >
          {item === "accepted" ? (
            <Check2 size={16} className="me-2" color={colorSetting} />
          ) : item === "re-schdule" ? (
            <ClockHistory size={16} className="me-2" color={colorSetting} />
          ) : item === "rejected" ? (
            <X size={18} className="me-2" color={colorSetting} />
          ) : null}
          <SpanStyled
            size="13px"
            className="text-capitalize"
            color={colorSetting}
          >
            {item}
          </SpanStyled>
        </Badge>
      );
    case "pending":
      return (
        <Box className="d-flex align-items-center">
          <BadgeBtn
            className=" mx-1"
            bg="var(--green2)"
            color="var(--white)"
            label="Accept"
          />
          <BadgeBtn
            className=" mx-1"
            bg="var(--red)"
            color="var(--white)"
            label="Reject"
          />
          <BadgeBtn
            className=""
            bg="var(--orange)"
            color="var(--white)"
            onClick={() => dispatch(showReScheduleModal(true))}
            label="Re-schedule"
          />
        </Box>
      );
      // eslint-disable-next-line
      break;
    default:
  }
};

export const InventoryColumns = [
  {
    name: "id",
    label: "#",
  },
  {
    name: "ProjectName",
    label: "Project Name",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "Type",
    label: "Type",
  },
  {
    name: "ProjectManager",
    label: "Project Manager",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "Phase",
    label: "Phase",
    render: (text) => {
      const badgeClass =
        text === "Start"
          ? "bg-orange"
          : text === "Governance"
            ? "bg-purple"
            : text === "Execution"
              ? "bg-green"
              : text === "Planning"
                ? "bg-blue"
                : "";
      return (
        <Badge
          className={`py-2 px-3 d-inline-flex bg-orange align-items-center cursor-pointer ${badgeClass}`}
        >
          <SpanStyled className="text-capitalize">{text}</SpanStyled>
        </Badge>
      );
    },
  },
  {
    name: "Start",
    label: "Start",
  },
  {
    name: "Duration",
    label: "Duration",
  },
  {
    name: "End",
    label: "End",
    render: (text) => <SpanStyled className="text-red">{text}</SpanStyled>,
  },
  {
    name: "Progress",
    label: "Progress",
    render: (text, { percent, ProgGraph }) => (
      <TblGraphStyled title={percent} graph={ProgGraph} />
    ),
  },
];

export const UsersColumns = [
  {
    name: "name",
    label: "Name",
    render: (text) => <TextWithIcon icon={avatarImg} title={text} />,
  },
  {
    name: "costCenter",
    label: "Cost Center",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "jobTitle",
    label: "Job Center",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "email",
    label: "Email",
    render: (email) => <SpanStyled>{email}</SpanStyled>,
  },
  {
    name: "phone",
    label: "Phone",
    render: (number) => <SpanStyled>{number}</SpanStyled>,
  },
  {
    name: "department",
    label: "Department",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
];

export const MeetingsColumns = [
  {
    name: "name",
    label: "Meeting",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "type",
    label: "Type",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "date",
    label: "Date",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "start_time",
    label: "Start time",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "Duration",
    label: "Duration",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "end_time",
    label: "End time",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "members",
    label: "Members",
    render: (members, row, dispatch) => (
      <AvatarGroup
        data={members}
        dialog={() => dispatch(showMembersModal(true))}
      />
    ),
  },
  {
    name: "re_shedule",
    label: "Reshedule",
    render: (re_shedule, row, dispatch) => (
      <AvatarGroup
        data={re_shedule}
        dialog={() => dispatch(showMeetingsReschduleModal(true))}
      />
    ),
  },
];

const viewDlownload = (text, dispatch) => {
  return (
    <BadgeBtn
      className="py-1 px-3 d-inline-flex align-items-center downloadbtn"
      label={text}
      onClick={() => dispatch(showViewDownloadModal(true))}
    />
  );
};

export const ReceivedMeetingsColumns = [
  {
    name: "name",
    label: "Meeting",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "type",
    label: "Type",
    render: (text) => text,
  },
  {
    name: "date",
    label: "Date",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "start_time",
    label: "Start time",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "Duration",
    label: "Duration",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "end_time",
    label: "End time",
    render: (text) => <SpanStyled>{text}</SpanStyled>,
  },
  {
    name: "agenda",
    label: "Agenda",
    // render: (text) => <SpanStyled >{text}</SpanStyled>
    render: (text, row, dispatch) => viewDlownload(text, dispatch),
  },
  {
    name: "action_status",
    label: "Action",
    render: (text, row, dispatch) => actionsStatus(text, dispatch),
  },
];


export const PaymentColumns = [
  {
    name: "id",
    label: "#",
  },
  {
    name: "ProjectName",
    label: "Project Name",
    render: (text) => <SpanStyled className="tblDbMdText">{text}</SpanStyled>,
  },
  {
    name: "Table",
    label: "Table",
  },
  {
    name: "CostCenter",
    label: "Cost Center",
  },
  {
    name: "Amount",
    label: "Amount",
  },
  {
    name: "Proceed",
    label: "Proceed",
  },
  {
    name: "Rest",
    label: "Rest",
  },

];