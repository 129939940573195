import { Row, Col, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { ProjectsTile } from "../Components/Dashboard/Pages/ProjectsTile";
import { ProgressBarTile } from "../Components/Dashboard/Pages/Charts/ProgressBarTile";
// import ProjectTasksTile from "../Components/admin/dashboard/ProjectTasksTile"
// import IndependentTasksTile from "../Components/admin/dashboard/IndependentTasksTile"
// import ProjectCostTile from "../Components/admin/dashboard/ProjectCostTile"
// import InternalExternalProjectsTile from "../Components/admin/dashboard/InternalExternalProjectsTile"
// import TileProgressBarStyled from "../Components/admin/dashboard/TileProgressBar"
// import { } from "../Styled/AllImages"

import {
  Box,
  ButtonStyledMedium,
  ChartBoxStyled,
  HeadingStyled,
  HeadingStyledDB,
  PieBoxStyled,
  SecondaryBarStyled,
  SpanStyled,
} from "../Styled/Elements";
import { SelectList } from "../Styled/FormElements";
import {
  ProjectsTileArray,
  ProjectTasksArray,
  IndependentTasksArray,
  ProjectCostArray,
  InternalExternalProjectsArray,
  ProjectsProgBarTileArray,
  ProjectTaskProgBarTileArray,
  IndependentTasksProgBarTileArray,
  ProjectCostProgBarTileArray,
  IntExtProgBarTileArray,
} from "../Components/Dashboard/Pages/TilesArray";
import {
  ProjectsPerformanceArray,
  IndPerformanceArray,
} from "../Components/Dashboard/Pages/Charts/PerformanceIndicationsArray";
import PerformanceIndications from "../Components/Dashboard/Pages/Charts/PerformanceIndications";
import ProjectPerformanceGraph from "../Components/Dashboard/Pages/Charts/ProjectPerformanceGraph";
import IndPerformanceGraph from "../Components/Dashboard/Pages/Charts/ProjectPerformanceGraph";

import ColumnStackedChart from "../Components/Dashboard/Pages/Charts/ColumnStackedChart";
import IndependentTasksChart from "../Components/Dashboard/Pages/Charts/IndependentTasksChart";
import { NotificationsList } from "../Components/Dashboard/NotificationsList";
import { NotificationsListArray } from "../Components/Dashboard/MessageNotifyListsArray";
import { Notification } from "../Styled/AllImages";
// import LineChart from "../../Components/charts/LineChart"

export const Dashboard = () => {
  return (
    <Box>
      <SecondaryBarStyled>
        <Box className="secondaryBar">
          <Box className="boxTVB">
            <SpanStyled size="14px" color="var(--grey)">
              A new project has been started which needs your approval.
            </SpanStyled>
            <ButtonStyledMedium>View Details</ButtonStyledMedium>
          </Box>
          <Box className="boxRBt">
            
            <Dropdown>
              <Dropdown.Toggle
                bsPrefix
                className="bg-transparent position-relative border-0 p-0"
              >
                <ButtonStyledMedium
              border="1px solid var(--outlineC)"
              bg="var(--greyBg)"
              color="var(--grey)"
              family="var(--regular)"
            >
                View All
            </ButtonStyledMedium>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropMNListContainer">
                <HeadingStyled
                  size="18px"
                  family="var(--semiBold)"
                  className="MNHeading"
                >
                  Messages
                </HeadingStyled>
                {NotificationsListArray.map((item, key) => (
                  <NotificationsList
                    {...item}
                    key={item.key}
                    className="BankListing"
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Box>
        </Box>
      </SecondaryBarStyled>
      <MainContent>
        <Row className="px-row-10 mb-4">
          <Col xs={12}>
            <HeadingStyledDB family="var(--semiBold)" size="">
              Projects
            </HeadingStyledDB>
            <Row className="px-row-10 mb-4 row-tile row-tile-4">
              {ProjectsTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-4"
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}

              {ProjectsProgBarTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-prog-tile col-prog-tile-4"
                >
                  <ProgressBarTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col xs={12}>
            <HeadingStyledDB family="var(--semiBold)" size="">
              Project Tasks
            </HeadingStyledDB>
            <Row className="px-row-10 mb-4 row-tile row-tile-4">
              {ProjectTasksArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-4"
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}
              {ProjectTaskProgBarTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-prog-tile col-prog-tile-4"
                >
                  <ProgressBarTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col xs={12}>
            <HeadingStyledDB family="var(--semiBold)" size="">
              Independent Tasks
            </HeadingStyledDB>
            <Row className="px-row-10 mb-4 row-tile row-tile-4">
              {IndependentTasksArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-4"
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}
              {IndependentTasksProgBarTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-prog-tile col-prog-tile-4"
                >
                  <ProgressBarTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col xs={12}>
            <HeadingStyledDB family="var(--semiBold)" size="">
              Project Cost
            </HeadingStyledDB>
            <Row className="px-row-10 mb-4 row-tile row-tile-3">
              {ProjectCostArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-3"
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}
              {ProjectCostProgBarTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-prog-tile col-prog-tile-3"
                >
                  <ProgressBarTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col xs={12}>
            <HeadingStyledDB family="var(--semiBold)" size="">
              Internal/External Projects
            </HeadingStyledDB>
            <Row className="px-row-10 mb-4 row-tile row-tile-2">
              {InternalExternalProjectsArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-tile col-tile-2"
                >
                  <ProjectsTile {...item} />
                </Col>
              ))}
              {IntExtProgBarTileArray.map((item, key) => (
                <Col
                  key={item.title + key}
                  className="mt-2 col-prog-tile col-prog-tile-3"
                >
                  <ProgressBarTile {...item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col className="" xs={12} lg={6}>
            <ChartBoxStyled>
              <Box className="graph-sel-row">
                <Box className="graph-sel-h-col">
                  <HeadingStyledDB className="mb-0">
                    Project Tasks
                  </HeadingStyledDB>
                  <Box className="mo-duration">
                    <SpanStyled size="14" color="var(--greyText)">
                      February Total
                    </SpanStyled>
                    <SpanStyled
                      size="14"
                      color="var(--darkBlue)"
                      family="var(--semiBold)"
                    >
                      102
                    </SpanStyled>
                  </Box>
                </Box>
                <SelectList
                  options={["Week", "Today"]}
                  className="custom-select-design med-sel"
                />
              </Box>
              <ColumnStackedChart />
            </ChartBoxStyled>
          </Col>
          <Col className="" xs={12} lg={6}>
            <ChartBoxStyled>
              <Box className="graph-sel-row">
                <Box className="graph-sel-h-col">
                  <HeadingStyledDB className="mb-0">
                    Independent Tasks
                  </HeadingStyledDB>
                  <Box className="mo-duration">
                    <SpanStyled size="14" color="var(--greyText)">
                      February Total
                    </SpanStyled>
                    <SpanStyled
                      size="14"
                      color="var(--darkBlue)"
                      family="var(--semiBold)"
                    >
                      248
                    </SpanStyled>
                  </Box>
                </Box>
                <SelectList
                  options={["Week", "Today"]}
                  className="custom-select-design med-sel"
                />
              </Box>
              <IndependentTasksChart />
            </ChartBoxStyled>
          </Col>
        </Row>
        <Row className="px-row-10 mb-4">
          <Col className="" xs={12} lg={6}>
            <PieBoxStyled>
              <HeadingStyledDB className="mb-0">
                Projects performance %
              </HeadingStyledDB>
              <Row className="px-row-10 mb-4">
                <Col lg={7}>
                  <Row>
                    {ProjectsPerformanceArray.map((item, key) => (
                      <Col xs={12} key={item.title + key} className="mt-3">
                        <PerformanceIndications {...item} />
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col lg={5} className="position-relative">
                  <Box className="perBarCompleted">
                    <SpanStyled className="perBarComTitle">80%</SpanStyled>
                    <SpanStyled className="perBarComText">Completed</SpanStyled>
                  </Box>
                  <ProjectPerformanceGraph />
                </Col>
              </Row>
            </PieBoxStyled>
          </Col>
          <Col className="" xs={12} lg={6}>
            <PieBoxStyled>
              <HeadingStyledDB className="mb-0">
                Independent Tasks Performance %
              </HeadingStyledDB>
              <Row className="px-row-10 mb-4">
                <Col lg={7}>
                  <Row>
                    {IndPerformanceArray.map((item, key) => (
                      <Col xs={12} key={item.title + key} className="mt-3">
                        <PerformanceIndications {...item} />
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col lg={5} className="position-relative">
                  <Box className="perBarCompleted">
                    <SpanStyled className="perBarComTitle">80%</SpanStyled>
                    <SpanStyled className="perBarComText">Completed</SpanStyled>
                  </Box>
                  <IndPerformanceGraph />
                </Col>
              </Row>
            </PieBoxStyled>
          </Col>
        </Row>
      </MainContent>
    </Box>
  );
};
const MainContent = styled.div`
  padding: 18px;
`;
