import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { Box, TextStyled, SpanStyled } from "../../Styled/Elements";

export const MessagesList = (props) => {
  return (
    <>
      {/* <Row className='align-item-center'>
                <Col md={12} lg={6} className='colBkList ps-0'>
                    <Box className='iconBkList'>
                        <props.icon width='24px' />
                    </Box>
                    <TextStyled className='mb-0' size='15px' family='var(--regular)'
                            color='var(--grey)' dangerouslySetInnerHTML={{ __html: props.title }}>
                    </TextStyled>
                </Col>
                <Col md={12} lg={6} className='colBkList'>
                    <Box>
                        <TextStyled color='var(--grey)' size='15px' family='var(--semiBold)'>
                            {props.text}
                        </TextStyled>
                    </Box>
                </Col>
            </Row> */}

      <Dropdown.Item href="#!" className="p-0">
        <MNListWrapper>
          <Box className="MNListImg">
            <props.userImg />
          </Box>
          <Box className="userMNDetail">
            <Box className="titleName bluDot">
              <TextStyled
                size="12px"
                dangerouslySetInnerHTML={{ __html: props.title }}
                className="MNtitle mb-1"
              ></TextStyled>
              <TextStyled
                size="12px"
                family="var(--semiBold)"
                className="MNuserName mb-1"
              >
                {props.userName}
              </TextStyled>
            </Box>

            <SpanStyled
              size="12px"
              family="var(--medium)"
              color="var(--themeColor)"
            >
              {props.messageTime}
            </SpanStyled>
          </Box>
        </MNListWrapper>
        <Dropdown.Divider />
      </Dropdown.Item>
    </>
  );
};
const MNListWrapper = styled.div`
  display: flex;
  padding: 11px 25px 10px 15px;
  gap: 12px;
  align-items: flex-start;
  .MNListImg {
    max-width: 32px;
    min-width: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
  .userMNDetail {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    & > * {
      width: 100%;
    }
  }
  .MNtitle {
  }
  .titleName > * {
    display: flex;
    width: 100%;
    white-space: normal;
    line-height: 16px;
  }
  .bluDot {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: var(--themeColor);
      width: 9px;
      height: 9px;
      border-radius: 50%;
      right: -15px;
      top: 10px;
    }
  }
`;
