import styled from "styled-components";
import { useState, useEffect } from "react";
import { arabic } from "../Styled/AllImages";
import { useTranslation } from "react-i18next";
import { ButtonChange, LazyImage } from "../Styled/Elements";

export const ChangeButton = () => {
  const { i18n } = useTranslation();
  const [isEnglishSelected, setIsEnglishSelected] = useState(
    localStorage.getItem("lang") === "en" ? false : true
  );

  const changeLanguageAndDirection = (lng, dir) => {
    i18n.changeLanguage(lng);
    document.documentElement.dir = dir; // Change document direction
    document.body.classList.toggle("rtl", dir === "rtl");
    localStorage.setItem("lang", lng); // Store the language preference in local storage
    localStorage.setItem("dir", dir);
  };
  const handleLanguageChange = (lng, dir) => {
    changeLanguageAndDirection(lng, dir);
    setIsEnglishSelected(!isEnglishSelected); // Toggle between English and Arabic
  };
  useEffect(() => {
    const savedLang = localStorage.getItem("lang");
    const savedDir = localStorage.getItem("dir");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
    if (savedDir) {
      document.documentElement.dir = savedDir;
      document.body.classList.toggle("rtl", savedDir === "rtl"); // Set the direction of the body element
    }
  }, []);

  return (
    <>
      <ButtonBox>
        {isEnglishSelected ? (
          <ButtonChange onClick={() => handleLanguageChange("en", "ltr")}>
            <img
              className="english_icon"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png"
              alt="USA"
            />
            Engliah
          </ButtonChange>
        ) : (
          <ButtonChange onClick={() => handleLanguageChange("ar", "rtl")}>
            <LazyImage src={arabic} className="english_icon" />
            العربية
          </ButtonChange>
        )}
      </ButtonBox>
    </>
  );
};
const ButtonBox = styled.div`
  .english_icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 6px;
  }
`;
