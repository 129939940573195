import React, { useState } from "react";
import DataWrapper from "./DataWrapper";
import CloneWrapper from "./CloneWrapper";
import {
	avatarImg,
	profile,
	profile2,
	profile3,
	profile4,
} from "../../../../../Styled/AllImages";
import styled from "styled-components";
import { MultiUserRow } from './styledTaskList';

const AddMember = () => {
	const [selectedMainParents, setSelectedMainParents] = useState([]);

	const mainParents = [
		{
			id: 1,
			taskDate: "May 3-8",
			taskName: "Savannah Nguyen",
			taskID: "ID: 001236547",
		},
		{
			id: 2,
			taskDate: "May 5-10",
			taskName: "Kristin Watson",
			taskID: "ID: 00234324234",
		},
		{
			id: 3,
			taskDate: "May 18-25",
			taskName: "Brooklyn Simmons",
			taskID: "ID: 6557567",
		},
		{
			id: 4,
			taskDate: "May 26-28",
			taskName: "Brooklyn Simmons",
			taskID: "ID: 756757",
		},
	];

	const handleMainParentClick = (mainParent) => {
		
		
		if (selectedMainParents.some((selected) => selected.id === mainParent.id)) {
			
			// Remove the mainParent from the selected list
			setSelectedMainParents(
				selectedMainParents.filter((selected) => selected.id !== mainParent.id)
			);
			
		} else {
			

			// Add the mainParent to the selected list
			setSelectedMainParents([...selectedMainParents, mainParent]);
		}
	};

	const handleRemoveClick = (parentId) => {
		// Remove the selected data based on its ID
		setSelectedMainParents(selectedMainParents.filter((selected) => selected.id !== parentId));
	};

	return (
		<MultiUserRow>
			<CloneWrapper selectedMainParents={selectedMainParents} onRemoveClick={handleRemoveClick} />
			<DataWrapper
				mainParents={mainParents}
				onMainParentClick={handleMainParentClick}
				selectedMainParents={selectedMainParents}
			/>


		</MultiUserRow>
	);
};

export default AddMember;



