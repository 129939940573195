import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { SingleProfile } from "../../../../../Styled/AllImages";
import { Box, SpanStyled } from "../../../../../Styled/Elements";
import { CustomCheckbox, SelectList, SelectListWithIcon } from "../../../../../Styled/FormElements";

const DataWrapper = ({
	mainParents,
	onMainParentClick,
	selectedMainParents,
	handleMemberRoles,
	props,
}) => {
	return (
		<UserUpload>
			<Dropdown className="dropup-center">
				<Dropdown.Toggle
					bsPrefix
					className="bg-transparent position-relative border-0 p-0 UserUploadBtn"
					id="dropdown-button-drop-up-centered"
				>
					<Box className="userPlaceholder">
						<SingleProfile />
					</Box>
					<SpanStyled className="Replac" color="var(--grey)">
						Add Members
					</SpanStyled>

					{/* <SpanStyled className="Replac" color="var(--grey)">
						bbbb
					</SpanStyled> */}
				</Dropdown.Toggle>

				{/* dropdown starts from here */}
				<Dropdown.Menu className="dropMNListContainer dropLeft">
					{mainParents.map((mainParent) => (
						<Dropdown.Item
							href="#!"
							className={`p-0 ${selectedMainParents.some(
								(selected) => selected.id === mainParent.id
							) && "selected"
								}`}
							key={mainParent.id}
							onClick={(e) => e.stopPropagation()}
						>
							<DropListRow className="dropChild">
								<Box
									className="d-flex"
									onClick={() => onMainParentClick(mainParent)}
									>
									<Box className="dropRadioContainer">
										<CustomCheckbox
											name="members"
											checked={selectedMainParents.some(
												(selected) => selected.id === mainParent.id
											)}
											as="div"
										/>
									</Box>
									<Box className="selUserImg">
										<img src={mainParent.profile} alt="User" />
									</Box>
									<SpanStyled color="grey" className="ms-1">
										{mainParent.userName}
									</SpanStyled>
								</Box>
								<SelectListWithIcon
									onChange={(e) => handleMemberRoles(e.target.value, mainParent.id)}
									options={["Manager", "Team Leader", "iOS Developer"]}
									selClass="w-auto"
									placeholder="Select Role"
								/>
							</DropListRow>

							<Dropdown.Divider />
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
				{/* dropdown ends here */}
			</Dropdown>
		</UserUpload>
	);
};

export default DataWrapper;

const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  display: flex;
  align-items: center;

  .UserUploadBtn {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--greyBg);
    border-radius: 50%;
    border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;

const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 7px;
  justify-content: space-between;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
