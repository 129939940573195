import styled from "styled-components";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SingleProfile } from "../../../../Styled/AllImages";
import { Box, SpanStyled } from "../../../../Styled/Elements";
import { MembersList } from "../../../../Pages/project/addProject/MembersList";
import { UsersListArray } from "../../../../Pages/project/addProject/UsersListsArray";

export const MembersDropDown = (props) => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedItemImage, setSelectedItemImage] = useState(null);
	const [selectedItemName, setSelectedItemName] = useState(null);
	return (
		<UserUpload>
			<Dropdown>
				<Dropdown.Toggle
					bsPrefix
					className="bg-transparent position-relative border-0 p-0 UserUploadBtn"
				>
					<Box className="userPlaceholder">
						{selectedItemImage ? (
							<img src={selectedItemImage} alt="Preview" />
						) : (
							<SingleProfile />
						)}
					</Box>
					{selectedItemName ? (
						<SpanStyled className="Replac" color="var(--grey)">
							{selectedItemName}
						</SpanStyled>
					) : (
						<SpanStyled className="Replac" color="var(--grey)">
							{props.userLabel}
						</SpanStyled>
					)}
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropMNListContainer">
					{UsersListArray.map((item, key) => (
						<MembersList
							{...item}
							key={item.key}
							className="BankListing"
							selectedItem={selectedItem}
							setSelectedItem={setSelectedItem}
							setSelectedItemImage={setSelectedItemImage}
							setSelectedItemName={setSelectedItemName}
						/>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</UserUpload>
	);
};

const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  .UserUploadBtn {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--greyBg);
    border-radius: 50%;
    border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;
