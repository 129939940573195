import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Box, SpanStyled, TextStyled } from "../../Styled/Elements";
import { TasksTile } from "../../Components/Dashboard/Pages/TasksTile";
import { PlusLg } from "react-bootstrap-icons";
import styled from "styled-components";

export const Board = (props) => {

  const buttonStyle = {
    border: '2px dashed #A0A0A0',
    height: '55px',
    color: '#A0A0A0'
  };
  const boardHeaders = [
    {
      name: 'toDo',
      label: 'To Do',
      borderColor: '#B1B1BC'
    },
    {
      name: 'inProgress',
      label: 'In Progress',
      borderColor: '#018DE4'
    },
    {
      name: 'overdue',
      label: 'Overdue',
      borderColor: '#E8374F'
    },
    {
      name: 'complete',
      label: 'Complete',
      borderColor: '#0FBC00'
    },
  ]

  const tasks = [
    {
      id: '00124',
      title: 'Android Developer',
      status: 'toDo',
      type: 'Standalone',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00125',
      title: 'Android Developer',
      status: 'toDo',
      type: 'Sequence',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00125',
      title: 'Android Developer',
      status: 'toDo',
      type: 'Standalone',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00125',
      title: 'Android Developer',
      status: 'complete',
      type: 'Standalone',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00125',
      title: 'Android Developer',
      status: 'complete',
      type: 'Sequence',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00125',
      title: 'Android Developer',
      status: 'overdue',
      type: 'Sequence',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
    {
      id: '00126',
      title: 'Android Developer',
      status: 'inProgress',
      type: 'Standalone',
      startDate: 'May 25',
      dueDate: 'May 30',
      executer: [
        { name: 'lenovo' }, { name: 'lenovo' }, { name: 'lenovo' }
      ]
    },
  ];

  const TaskColumn = ({ item }) => {
    const columnTasks = tasks.filter(task => task.status === item.name);
    return (
      <>
        <Card style={{ borderRadius: '15px', borderTop: `3px solid ${item.borderColor}` }} className="mb-3">
          <TiledWrapperStyled>
            <Card.Body className="flex">
              <Stack direction="horizontal" className="justify-content-between align-items-center">
                <Box className="d-flex align-items-center">
                  <TextStyled
                    className="tile-title"
                    dangerouslySetInnerHTML={{ __html: item.label }}
                  ></TextStyled>
                  <SpanStyled className="mx-2 px-2 py-0 rounded-4 fw-semibold" border={'1px solid #A0A0A0'} color="#6c757d">
                    {columnTasks.length}
                  </SpanStyled>
                </Box>
                <TextStyled onClick={props.openAddTaskModal} style={{cursor:'pointer'}}>
                  <PlusLg color="#343a40" width={20} height={20} />
                </TextStyled>
              </Stack>
            </Card.Body>
          </TiledWrapperStyled>
        </Card>
        {columnTasks.map((task, key) => (
          <div key={key} className="mt-2">
            <TasksTile {...task} />
          </div>
        ))}
        <Button style={buttonStyle} className="w-100 bg-white mt-3" onClick={props.openAddTaskModal}>
          Add Task <PlusLg color="#343a40" />
        </Button>
      </>
    )
  }

  const renderedTaskList = boardHeaders.map((head, key) => {
    return (
      <Col
        key={head.name + key}
        className="mt-2 col-tile col-tile-3"
      >
        <TaskColumn item={head} />
      </Col>
    )
  })
  return (
    <>
      <Box className="main-content">
        <Row className="px-row-10 mb-4 row-tile row-tile-3">
          {renderedTaskList}
        </Row>
      </Box >
    </>
  );
};
const TiledWrapperStyled = styled.div`
  .tile-title {
    font-size: 16px;
    color: var(--darkBlue) !important;
    font-family: var(--semiBold);
    line-height: 16px !important;
    margin-bottom: 4px;
  }
`;
