import { Badge } from "react-bootstrap"
import { Plus } from "react-bootstrap-icons"
import { MessageSidebarWrapper } from "./styled"
import { EditOutlineIcon } from "../../Styled/AllImages"
import { Box, HeadingStyled, LazyImage, SpanStyled } from "../../Styled/Elements"

const MessagesUsersSidebar = ({ onModalShow, setModalDirectShow }) => {
    const generateColor = () => {
        const colors = new Set(['var(--themeColor)', 'var(--orange)', 'var(--green)', 'var(--purple)']);
        const colorArray = Array.from(colors);
        return colorArray[Math.floor(Math.random() * colorArray.length)];
    }
    const imgUrl = 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80'
    const GroupListItem = ({ name, direct, classes }) => (
        <li className=' group-list d-flex mt-1'>
            {!direct ?
                <Badge
                    bg=''
                    className='bage-hash d-flex align-items-center justify-content-center rounded-circle'
                    style={{ backgroundColor: generateColor(), lineHeight: 'normal' }}
                >
                    #
                </Badge>
                :
                <Box className={classes}>
                    <LazyImage src={imgUrl} width='35px' height='35px' className='rounded-circle' />
                </Box>
            }
            <SpanStyled size='14px'>{name}</SpanStyled>
        </li>
    )
    return (
        <MessageSidebarWrapper>
            <Box className='profile-view border-bottom-custom'>
                <Box className='d-flex align-items-center'>
                    <LazyImage className='rounded-circle' width='55px' height='55px' src={imgUrl} />
                    <Box className='mx-2'>
                        <HeadingStyled size='14px' weight={500}>
                            M.Abdullah
                        </HeadingStyled>
                        <SpanStyled size='12px' color='var(--greyText)'>
                            project Manager
                        </SpanStyled>
                    </Box>
                </Box>
                <Box>
                    <button type='button' onClick={setModalDirectShow} className='edit-btn rounded-circle border-0'>
                        <EditOutlineIcon />
                    </button>
                </Box>
            </Box>
            <Box className='border-bottom-custom pb-2'>
                <Box className='border-bottom-custom'>
                    <ul className='list-unstyled group-view'>
                        <li>
                            <HeadingStyled size='14px'>
                                #Groups
                            </HeadingStyled>
                        </li>
                        <li>
                            <button onClick={onModalShow} className='border-0 bg-transparent add-btn p-0'>
                                <Plus className='px-1' size='30' /> Create a Group
                            </button>
                        </li>
                    </ul>
                </Box>
                <ul className='list-unstyled mb-0 pb-1 list-group'>
                    <GroupListItem name='UX/UI Design' />
                    <GroupListItem name='Android Development' />
                    <GroupListItem name='iOS Development' />
                    <GroupListItem name='Finance' />
                </ul>
            </Box>
            <Box>
                <HeadingStyled size='15px' className='direct-message-list border-bottom-custom'>
                    Direct Messages
                </HeadingStyled>
                <ul className='list-unstyled mb-0 pb-1 list-group'>
                    <GroupListItem direct classes='deactive' name='Yasir Ali' />
                    <GroupListItem direct classes='active' name='Arham Mughal' />
                    <GroupListItem direct classes='active' name='Rana Faiq' />
                </ul>
            </Box>
        </MessageSidebarWrapper>
    )
}



export default MessagesUsersSidebar;